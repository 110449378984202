import { theme } from "@chakra-ui/core";

export default {
  ...theme,
  colors: {
    ...theme.colors,
    purple: {
      ...theme.colors.purple,
      400: "#7F21FF",
      500: "#7F00FF",
      600: "#7000E0",
      15: "#ecd9ff",
      75: "#9f41ff",
      35: "#d2a7ff",
    },
    surface: "#F8F8FA",
    text: {
      500: "#384364",
      400: "#566372",
      300: "#a4abb9",
      200: "#707070",
      15: "#e1e3e8",
    },
    gray: {
      // 50: "#F7FAFC",
      100: "#E8E9EC",
      // 200: "#E2E8F0",
      300: "#CBD5E0",
      // 400: "#A0AEC0",
      // 500: "#718096",
      // 600: "#4A5568",
      // 700: "#2D3748",
      // 800: "#1A202C",
      // 900: "#171923",
      400: "#BEBEBE1F",
      500: "#E8E9EC",
      600: "#E8E9EC",
      700: "#b3b3b3"
    },
    urgent: "#1ADDFD",
    hot: "#FC2F0D",
    pink: "#7F00FF0B",
    accepted: "#23CE6B",
    declined: "#FC2F0D",
    success: "#23CE6B",
    error: "#FC2F0D",
    pending: "#9ba1b1",
  },
  fonts: {
    body: `"Roboto"`,
    heading: `"Roboto"`,
    mono: `"Roboto"`,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
};
