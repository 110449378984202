import React, { useEffect, useRef, useState } from 'react';

import { Box, Image, Grid, SimpleGrid, IconButton, FormControl, Input, FormErrorMessage, Select } from "@chakra-ui/core"

import Text from "../../common/Text"
import FeaturedContainer from '../../common/FeaturedContainer';

import { Link } from "react-router-dom"
import { imageUpload } from '../../api';
import { FaPlus } from 'react-icons/fa';

import countries from "../../common/meta/countries.json";
import Button from "../../common/Button";

import { useDispatch, useSelector } from 'react-redux';
import { editUser } from "../../actions/authActions";
import { auth } from '../../api';

const countriesArray = Object.keys(countries);

const EditProfile = ({ history, match }) => {
    const inputFile = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({
        first_name: "",
        last_name: "",
        company: "",
        job_title: "",
        country: "",
        city: "",
        phone1: "",
        phone2: "",
        website: "",
    });
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (user.image) setUploadedImage(user.image)
        setFields(user);
        if (user.first_name) localStorage.setItem("user", JSON.stringify(user))
    }, [])

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const onUpload = (e) => {
        setIsUploading(true);
        const file = e.target.files[0];
        imageUpload(file)
            .then((res) => {
                setUploadedImage(res.object);
                setFields({ ...fields, image_id: res.object.id })
                setIsUploading(false);
            })
            .catch((err) => {
                setIsUploading(false);
            });
    };

    const handleChange = (e) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }

    const goBack = () => {
        history.push("/profile/offers");
    }

    const onSave = () => {
        dispatch(editUser(fields, goBack));
    }

    return (
        <Box w="100%" h="100%">
            <Box
                w="100%"
                h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
                className="gradient-header"
                px={["1rem", "2.5rem", "3rem"]}
                py="1.5rem"
            >
                <Text type="header" color="white">
                    Edit Profile
                </Text>
                <Text type="secondaryBodyRegular" color="white" as={Link} to={"/profile/offers"} w="fit-content">
                    {
                        "‹ Back to My Profile"
                    }
                </Text>
            </Box>
            <FeaturedContainer d="flex" mt={"-3.5rem"} width="fit-content" p={["3rem", "3rem 5rem"]} ml="auto" mr="auto" alignItems="center" d="flex" flexDirection="column">
                <SimpleGrid columns={[1, 1, 2]} spacing={5} mt={5}>
                    <FormControl d="flex" flexDirection="column" gridRow="1/3">
                        {uploadedImage ? (
                            <Image
                                cursor="pointer"
                                onClick={onButtonClick}
                                alt="profile"
                                src={uploadedImage.url}
                                objectFit="cover"
                                rounded="full"
                                size={[80, 105]}
                                m={["auto", 0]}
                            />
                        ) : (
                                <IconButton
                                    onClick={onButtonClick}
                                    id="uploadPhoto"
                                    variant="outline"
                                    aria-label="Uplaod Photo"
                                    fontSize="20px"
                                    borderRadius="100%"
                                    width={[80, 105]}
                                    height={[80, 105]}
                                    color="#1E2A38"
                                    borderWidth={1}
                                    icon={FaPlus}
                                    m={["auto", 0]}
                                    isLoading={isUploading}
                                    opacity="0.3"
                                />
                            )}
                        <input
                            type="file"
                            id="file"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={onUpload}
                        />
                    </FormControl>
                    <FormControl
                        isInvalid={errors.first_name && errors.first_name.length > 0}
                    >
                        <Input
                            variant="flushed"
                            id="first_name"
                            placeholder="First Name"
                            name="first_name"
                            value={fields.first_name}
                            onChange={handleChange}
                            data-test-id="first_name"
                        />
                        <FormErrorMessage data-test-id="first_name-error">
                            {errors.first_name && errors.first_name[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl
                        isInvalid={errors.last_name && errors.last_name.length > 0}
                    >
                        <Input
                            variant="flushed"
                            id="last_name"
                            placeholder="Last Name"
                            name="last_name"
                            value={fields.last_name}
                            onChange={handleChange}
                            data-test-id="last_name"
                        />
                        <FormErrorMessage data-test-id="last_name-error">
                            {errors.last_name && errors.last_name[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.company && errors.company.length > 0}>
                        <Input
                            variant="flushed"
                            id="company"
                            placeholder="Company Name"
                            name="company"
                            value={fields.company}
                            onChange={handleChange}
                            data-test-id="company"
                        />
                        <FormErrorMessage data-test-id="company-error">
                            {errors.company && errors.company[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl
                        isInvalid={errors.job_title && errors.job_title.length > 0}
                    >
                        <Input
                            variant="flushed"
                            id="job_title"
                            placeholder="Job Title"
                            name="job_title"
                            value={fields.job_title}
                            onChange={handleChange}
                            data-test-id="job_title"
                        />
                        <FormErrorMessage data-test-id="job_title-error">
                            {errors.job_title && errors.job_title[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.country && errors.country.length > 0}>
                        <Select
                            id="country"
                            variant="flushed"
                            placeholder="Country"
                            name="country"
                            value={fields.country}
                            onChange={handleChange}
                            data-test-id="country"
                        >
                            {countriesArray.map((country) => (
                                <option key={country}>{country}</option>
                            ))}
                        </Select>
                        <FormErrorMessage>
                            {errors.country && errors.country[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.city && errors.city.length > 0}>
                        <Select
                            variant="flushed"
                            id="city"
                            placeholder="City"
                            name="city"
                            value={fields.city}
                            onChange={handleChange}
                            data-test-id="city"
                        >
                            {fields.country && fields.country.length > 0 ? (
                                countries[fields.country]?.map((city) => (
                                    <option key={city}>{city}</option>
                                ))
                            ) : (
                                    <option></option>
                                )}
                        </Select>
                        <FormErrorMessage>{errors.city && errors.city[0]}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.phone1 && errors.phone1.length > 0}>
                        <Input
                            variant="flushed"
                            id="phone1"
                            placeholder="Telephone 1"
                            name="phone1"
                            value={fields.phone1}
                            onChange={handleChange}
                            data-test-id="phone1"
                        />
                        <FormErrorMessage>
                            {errors.phone1 && errors.phone1[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.phone2 && errors.phone2.length > 0}>
                        <Input
                            variant="flushed"
                            id="tel2"
                            placeholder="Telephone 2"
                            name="phone2"
                            value={fields.phone2}
                            onChange={handleChange}
                            data-test-id="phone2"
                        />
                        <FormErrorMessage>
                            {errors.phone2 && errors.phone2[0]}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl
                        gridColumn={[1, "1/3"]}
                        isInvalid={errors.website && errors.website.length > 0}
                    >
                        <Input
                            variant="flushed"
                            id="website"
                            placeholder="Website"
                            name="website"
                            value={fields.website}
                            onChange={handleChange}
                            data-test-id="website"
                        />
                        <FormErrorMessage>
                            {errors.website && errors.website[0]}
                        </FormErrorMessage>
                    </FormControl>
                </SimpleGrid>
                <Box mt="2rem" w="min-content" d={["block", "flex"]}>
                    <Button onClick={onSave}>Save Changes</Button>
                    <Button ml={[0, "1rem"]} mt={["1rem", 0]} variantColor="gray" backgroundColor="gray.100" color="text.500" onClick={goBack}>Cancel</Button>
                </Box>
            </FeaturedContainer>
        </Box >
    );
};

export default EditProfile;