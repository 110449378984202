import React from "react";
import { Box, Image } from "@chakra-ui/core";
import Text from "./Text";

import Male from "../assets/images/Male.png"

const UserTag = ({ name = "", company = "", image, ...props }) => {
  return (
    <Box d="flex" alignItems="center" {...props}>
      <Image
        h={["3rem", "2rem"]}
        w={["3rem", "2rem"]}
        objectFit="cover"
        rounded="full"
        src={image || Male}
      />
      <Box ml="0.8rem">
        <Text type="secondaryBodyBold">{name}</Text>
        <Text type="secondaryBodyLight">{company}</Text>
      </Box>
    </Box>
  );
};

export default UserTag;
