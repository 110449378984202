import React from "react";

import { Box, FormControl, FormErrorMessage } from "@chakra-ui/core";
import Input from "../../common/Input";

const IconInput = ({ Icon, isLoading, errors, ...inputProps }) => {
  return (
    <Box d="flex" alignItems="center" mt={"2.4rem"} w="100%">
      <Box
        alignSelf="flex-start"
        as={Icon}
        color="gray.500"
        size={["1.8rem", "1.4rem"]}
        mr={["1rem", "0.8rem"]}
      ></Box>

      <FormControl
        isDisabled={isLoading}
        isInvalid={errors && errors[inputProps.name]}
        width={"90%"}
      >
        <Input {...inputProps} />
        {errors &&
          errors[inputProps.name] &&
          errors[inputProps.name].map((message) => (
            <FormErrorMessage
              fontSize={"0.6rem"}
              className={`${inputProps.name}-error-message`}
              key={message}
            >
              {message}
            </FormErrorMessage>
          ))}
      </FormControl>
    </Box>
  );
};

export default IconInput;
