import React, { useState } from "react";
import { Box, Collapse } from "@chakra-ui/core";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Text from "./Text";
import UserTag from "./UserTag";
import FeaturedContainer from "./FeaturedContainer";

const Details = ({
  representativeType,
  representative,
  onEditNetworkClicked,
  isMobile = false,
  toggleCollapse,
  isCollapseOpen = false,
}) => {
  return (
    <Box py="1rem" px="0.5rem" pl="1rem" w="100%">
      <Box d="flex" justifyContent="space-between" alignItems="center">
        <Text type="largeBodyBold">
          {representativeType === "network" ? "Network" : "Sales"}{" "}
          Representative
        </Text>
        {isMobile && (
          <Box
            onClick={toggleCollapse}
            as={isCollapseOpen ? FaChevronUp : FaChevronDown}
            mr="1rem"
          />
        )}
      </Box>
      <UserTag
        mt="0.7rem"
        name={representative.fullname}
        company={representative.company}
        image={representative.image}
      />
      {representativeType === "network" ? (
        <Box mt="0.7rem">
          <Text type="secondaryBodyBold">{representative.company}</Text>
          <Box d="flex">
            <Text type="bodyLight">92 dealers</Text>
            <Text
              type="bodyBold"
              color="purple.500"
              ml="0.5rem"
              cursor="pointer"
              onClick={onEditNetworkClicked}
            >
              Edit Network Name
            </Text>
          </Box>
        </Box>
      ) : (
        <Box
          mt="0.7rem"
          d="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          fontSize="0.5rem"
          color="text.500"
          fontWeight="light"
        >
          <Box w="50%" fontSize={["0.7rem", undefined]}>
            <Text fontWeight="bold">Contact info</Text>
            <Text>{representative.phone1}</Text>
            <Text>{representative.email}</Text>
          </Box>
          <Box w="50%" fontSize={["0.7rem", undefined]}>
            <Text fontWeight="bold">Location of vehicles:</Text>
            <Text>{representative.location}</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const SalesRepresentative = ({
  representativeType,
  representative = {},
  onEditNetworkClicked,
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);
  return (
    <>
      <FeaturedContainer d={["none", "flex"]}>
        <Details
          representative={representative}
          representativeType={representativeType}
          onEditNetworkClicked={onEditNetworkClicked}
        />
      </FeaturedContainer>
      <FeaturedContainer d={["block", "none"]}>
        <Collapse isOpen={isCollapseOpen} startingHeight={40}>
          <Details
            isMobile
            toggleCollapse={toggleCollapse}
            isCollapseOpen={isCollapseOpen}
            representative={representative}
            representativeType={representativeType}
            onEditNetworkClicked={onEditNetworkClicked}
          />
        </Collapse>
      </FeaturedContainer>
    </>
  );
};

export default SalesRepresentative;
