import typeToReducer from "type-to-reducer";
import {
  GET_BATCHES,
  GET_BATCH,
  ADD_VEHICLE,
  SET_BATCH,
  GET_BATCH_VEHICLES,
  GET_BATCH_VEHICLE,
  SET_BATCH_VEHICLE,
  GET_BATCH_OFFERS,
  GET_BATCH_DEALS,
  GET_DASHBOARD_OFFERS,
  GET_DASHBOARD_DEALS,
  GET_NETWORK_USERS,
  GET_ACTIVITIES,
  SET_SEARCH_KEYWORD,
  SET_SEARCH_RESULTS
} from "../constants";

const initalState = {
  batches: [],
  loading: false,
  isRejected: false,
  error: {},
  currentBatch: {
    object: null,
    vehicles: [],
    offers: [],
    deals: [],
  },
  offers: {
    objects: [],
    loading: false,
    error: {},
    isRejected: false,
  },
  deals: {
    objects: [],
    loading: false,
    error: {},
    isRejected: false,
  },
  currentVehicle: null,
  networkUsers: [],
  activities: [],
  searchKeyword: "",
  searchResultsCount: {
    total: 0,
    batches: 0,
    offers: 0,
    deals: 0,
    users: 0
  }
};

const reducer = typeToReducer(
  {
    [GET_BATCHES]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        batches: action.payload ? action.payload.objects : [],
      }),
    },
    [GET_BATCH]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentBatch: {
          ...state.currentBatch,
          object: action.payload.object,
        },
      }),
    },
    [SET_BATCH]: (state, action) => ({
      ...state,
      loading: false,
      isRejected: false,
      currentBatch: {
        ...state.currentBatch,
        ...action.payload,
      },
    }),
    EDIT_BATCH: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentBatch: {
          ...state.currentBatch,
          object: action.payload.object,
        },
      }),
    },
    [ADD_VEHICLE]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentBatch: {
          ...state.currentBatch,
          object: {
            ...state.currentBatch.object,
            num_vehicles: state.currentBatch.object.vehicles + 1,
          },
          vehicles: [...state.currentBatch.vehicles, action.payload.object],
        },
      }),
    },
    [GET_BATCH_VEHICLES]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentBatch: {
          ...state.currentBatch,
          vehicles: action.payload.objects,
        },
      }),
    },
    [GET_BATCH_OFFERS]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentBatch: {
          ...state.currentBatch,
          offers: action.payload.objects,
        },
      }),
    },
    [GET_BATCH_DEALS]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentBatch: {
          ...state.currentBatch,
          deals: action.payload.objects,
        },
      }),
    },
    [GET_DASHBOARD_OFFERS]: {
      PENDING: (state) => ({
        ...state,
        offers: { ...state.offers, loading: true },
      }),
      REJECTED: (state, action) => ({
        ...state,
        offers: {
          ...state.offers,
          loading: false,
          isRejected: true,
          error: action.payload,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        offers: {
          ...state.offers,
          ...action.payload,
          loading: false,
          isRejected: false,
        },
      }),
    },
    [GET_DASHBOARD_DEALS]: {
      PENDING: (state) => ({
        ...state,
        deals: { ...state.deals, loading: true },
      }),
      REJECTED: (state, action) => ({
        ...state,
        deals: {
          ...state.deals,
          loading: false,
          isRejected: true,
          error: action.payload,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deals: {
          ...state.deals,
          ...action.payload,
          loading: false,
          isRejected: false,
        },
      }),
    },
    [GET_BATCH_VEHICLE]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        currentVehicle: action.payload.object,
      }),
    },
    [SET_BATCH_VEHICLE]: (state, action) => ({
      ...state,
      loading: false,
      isRejected: false,
      currentVehicle: action.payload,
    }),
    [GET_NETWORK_USERS]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        networkUsers: action.payload.objects,
      }),
    },
    [GET_ACTIVITIES]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        activities: action.payload.objects,
      }),
    },
    [SET_SEARCH_KEYWORD]: (state, action) => ({
      ...state,
      searchKeyword: action.payload,
    }),
    [SET_SEARCH_RESULTS]: (state, action) => ({
      ...state,
      searchResultsCount: action.payload,
    }),
  },
  initalState
);

export default reducer;
