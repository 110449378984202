import React, { useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getOffers as getOffersAction } from "../../actions/marketplaceActions";

import { Box, Skeleton } from "@chakra-ui/core";

import Filters from "../../common/Filters";
import Pagination from "../../common/Pagination";
import Offer from "../../common/OfferDealStatus";

import {
  offersVariantsMapping as variantsMapping,
  offersTitlesMapping as titlesMapping,
  offersDescriptionsMapping as descriptionsMapping,
  offersCounterTitlesMapping as counterTitlesMapping,
  offersCounterDescriptionsMapping as counterDescriptionsMapping,
} from "../../common/mappings";

const generalFilters = [
  "All Offers",
  "Pending",
  "Accepted",
  "Declined",
  "Withdrawn",
];

const filtersMap = {
  pending: "Pending",
  accepted: "Accepted",
  rejected: "Declined",
  withdrawn: "Withdrawn",
};

const OffersList = ({ history }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    userId: state.auth.user.id,
    offers: state.marketplace.offers.objects,
    pages: state.marketplace.offers.pages,
    page: state.marketplace.offers.page,
    loading: state.marketplace.offers.loading,
  }));

  useEffect(() => {
    getOffers();
  }, []);

  const getOffers = (page = 1) => {
    const params = {
      state: history.location.pathname.split("/")[2],
      page,
    };
    dispatch(getOffersAction(params));
  };

  const navigateToFilter = (selectedTab) => {
    switch (selectedTab) {
      case "All Offers":
        history.push(`/offers`);
        break;
      case "Pending":
        history.push(`/offers/pending`);
        break;
      case "Accepted":
        history.push(`/offers/accepted`);
        break;
      case "Declined":
        history.push(`/offers/rejected`);
        break;
      case "Withdrawn":
        history.push(`/offers/withdrawn`);
        break;

      default:
        break;
    }
    getOffers();
  };

  const selectedFilter = filtersMap[history.location.pathname.split("/")[2]];

  return (
    <Fragment>
      <Box
        mx="2.5rem"
        mt={"2rem"}
        d="flex"
        justifyContent="space-between"
      >
        <Filters
          generalFilters={generalFilters}
          selectedGeneral="All Offers"
          onSelectedGeneral={navigateToFilter}
          selectedGeneral={selectedFilter || "All Offers"}
        />
      </Box>
      <Box mx="2.5rem">
        {store.loading ? (
          <>
            <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
            <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
            <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
            <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
          </>
        ) : (
            store.offers.map((offer) => (
              <Offer
                key={offer.id}
                id={offer.id}
                variant={variantsMapping[offer.state]}
                title={
                  offer.is_counter
                    ? counterTitlesMapping[offer.state]
                    : titlesMapping[offer.state]
                }
                description={
                  offer.is_counter
                    ? counterDescriptionsMapping[offer.state]
                    : descriptionsMapping[offer.state]
                }
                offer={offer}
                linkTo={`/offers/details/${offer.id}`}
              />
            ))
          )}
      </Box>
      <Pagination
        pages={store.pages}
        currentPage={store.page}
        setPage={getOffers}
      />
    </Fragment>
  );
};

export default OffersList;
