import React, { useState, useRef } from "react";

import { Box } from "@chakra-ui/core";
import LandingHeader from "./LandingHeader";

import Text from "../../common/Text";

const Sections = ({ sections, title }) => {
  const sectionRef = useRef([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const listenToScroll = (e) => {
    setScrollPosition(e.target.scrollTop);
  };

  return (
    <Box backgroundColor="surface" minH="100vh" overflow="hidden">
      <LandingHeader backgroundColor="purple.500" px="5.4rem" />
      <Box d="flex">
        <Box
          w="20%"
          bg="white"
          h="70vh"
          boxShadow=" 0px 3px 15px #0000000D"
          d={["none", "flex"]}
          alignItems="center"
          flexDir="column"
        >
          {sections.map((section, index) => (
            <Text
              p="1rem"
              borderLeftWidth="1px"
              borderColor="gray.100"
              fontSize="0.8rem"
              w="70%"
              cursor="pointer"
              color={
                sectionRef.current[index] &&
                scrollPosition >=
                  (index === 0 ? 0 : sectionRef.current[index].scrollHeight) &&
                scrollPosition <=
                  (index < sections.length - 1
                    ? sectionRef.current[index + 1].scrollHeight
                    : window.innerHeight)
                  ? "purple.500"
                  : "text.500"
              }
              fontWeight={index === 0 ? "bold" : "medium"}
            >
              {section.title}
            </Text>
          ))}
        </Box>
        <Box
          p="2.5rem"
          w={["100%", "80%"]}
          overflowY="scroll"
          maxHeight="calc(100vh - 7rem)"
          onScroll={listenToScroll}
        >
          <Text fontSize="0.8rem" letterSpacing="3px">
            SEEZONE
          </Text>
          <Text fontSize="2rem" fontWeight="medium">
            {title}
          </Text>
          {sections.map((section, sectionIndex) => (
            <Box
              key={section.title}
              id={`section_${sectionIndex}`}
              ref={(el) => (sectionRef.current[sectionIndex] = el)}
            >
              <Text type="header" color="purple.500" mt="1rem">
                {section.title}
              </Text>
              {section.subSections.map((subSection, subSectionIndex) => (
                <Box key={subSection.title} mt="1rem">
                  <Text fontSize={["1rem", "0.7rem"]} fontWeight="bold">
                    {subSection.title}
                  </Text>
                  <Box d="flex" fontSize="0.8rem">
                    <Text w="3rem">
                      {sectionIndex + 1}.{subSectionIndex + 1}.1
                    </Text>
                    <Text w="90%" removeStyling>
                      {subSection.content}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Sections;
