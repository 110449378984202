import React from "react";

import { Box } from "@chakra-ui/core";

import Text from "../../common/Text";
import OffersList from "./OffersList";

const Offers = ({ history }) => {
    return (
        <Box w="100%" h="100%">
            <Box
                w="100%"
                h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
                className="gradient-header"
                px={["2rem", "2.5rem", "3rem"]}
                py="1.5rem"
            >
                <Text type="header" color="white">
                    My Offers
                </Text>
                <Text fontSize={["1.1rem", "0.6rem"]} color="white">
                    Offers you have made on your custom selection from batches
               </Text>
            </Box>
            <OffersList history={history} />
        </Box>
    );
};

export default Offers;
