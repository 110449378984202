import React, { useState } from "react";

import { Grid, Box, Button, Collapse } from "@chakra-ui/core";

import { Link, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { auth as authApi } from "../../api";

import {
  FaUserCircle,
  FaCar,
  FaNetworkWired,
  FaHandshake,
  FaDesktop,
  FaUser,
  FaLock,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

import Header from "./Header";
import Checkbox from "../../common/CheckBox";
import Text from "../../common/Text";
import IconInput from "./IconInput";
import IconText from "./IconText";

import constraints from "../../common/generalConstraints";
import validate from "validate.js";

import firebase from "../../common/firebase";
import CustomButton from "../../common/Button";

const LoginScreen = ({ onForgotPasswordOpen }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const [fields, setFields] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(true);
  const handleToggle = () => setShow(!show);

  const history = useHistory();

  const handleFieldChange = (e) =>
    setFields({ ...fields, [e.target.name]: e.target.value });

  const loginUser = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((firebaseResponse) => {
        authApi
          .getLoggedInUser()
          .then(function (res) {
            localStorage.setItem(
              "firebaseUser",
              JSON.stringify(firebaseResponse.user)
            );
            setIsLoading(false);
            if (res && res.object) {
              localStorage.setItem("user", JSON.stringify(res.object));
            }
            history.push("/marketplace");
          })
          .catch((err) => {
            if (err.detail && err.detail.includes("is not Registered")) {
              history.push("complete-profile");
            }
          });
      })
      .catch(function (error) {
        console.log(errors);
        setIsLoading(false);

        var errorCode = error.code;

        if (
          errorCode === "auth/user-not-found" ||
          errorCode === "auth/wrong-password"
        )
          setErrors({
            password: ["User with this email/password combination not found"],
          });
      });
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();

    const validationErrors = validate(
      { email: fields.email, password: fields.password },
      constraints
    );

    if (validationErrors) {
      setErrors({ ...validationErrors });
    } else {
      setIsLoading(true);
      loginUser(fields.email, fields.password);
    }
  };

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <Grid
      h="100%"
      templateColumns={["1fr", "1fr", "5fr 4fr", "3fr 2fr", "2fr 1fr"]}
      display={["block", "grid"]}
      overflow="hidden"
    >
      <Collapse
        className="gradient-background"
        backgroundPosition="right"
        pl={["2.5rem", "5.4rem"]}
        pr={"2.5rem"}
        color="white"
        fontSize={["0.7rem"]}
        pb={[10, 10, 10, 0]}
        h={["22rem", "100vh"]}
        startingHeight={80}
        isOpen={show}
      >
        <Header />
        <Box
          mt={["2rem", "5.7rem"]}
          maxW={"20.5rem"}
          letterSpacing={0}
          lineHeight={1.2}
        >
          <Text color="white" removeStyling fontSize={["0.9rem", "auto"]}>
            SeezOne is a two-way online platform for international B2B car
            trading. Our wholesale vehicle marketplace for buyers and sellers
            connects trusted car dealers and distributors with a fully
            transparent structure, gives users access to unique car stock with a
            few clicks, and guarantees secure payment between users.
          </Text>
        </Box>
        <Box mt={["3.2rem"]} display={["none", "block"]}>
          <IconText Icon={FaCar}>
            <b>Buy</b> custom groups of vehicles
          </IconText>
          <IconText Icon={FaHandshake}>
            <b>Sell</b> car stock in batches with digital auctions
          </IconText>
          <IconText Icon={FaDesktop}>
            <b>Digitize</b> your trading business
          </IconText>
          <IconText Icon={FaNetworkWired}>
            <b>Establish</b> your network
          </IconText>
        </Box>
        <Box mt={["2rem", "3.2rem"]}>
          <CustomButton
            color="purple.400"
            bg="white"
            height={["35px", "1.92rem"]}
            width={["117px", "auto"]}
            fontSize={["1rem", "0.7rem"]}
          >
            How It Works
          </CustomButton>
        </Box>
      </Collapse>
      <Box
        padding="0.8rem"
        backgroundColor="white"
        display={["block", "none"]}
        position="absolute"
        borderRadius="100%"
        right={10}
        bottom={show ? "calc(100vh - 23.5rem)" : "calc(100vh - 7rem)"}
        zIndex={2}
        borderWidth="1px"
        borderColor="purple.500"
        onClick={handleToggle}
      >
        <Box
          as={!show ? FaChevronDown : FaChevronUp}
          fontSize="1rem"
          color="purple.500"
        ></Box>
      </Box>
      <Box bg="surface" px={["5rem", "5.4rem"]} h="100%" pt={["2rem", "6rem"]}>
        <Box
          as="form"
          d="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          onSubmit={handleSubmit}
        >
          <Box
            as={FaUserCircle}
            size={["4rem", "2.8rem"]}
            color="purple.500"
          ></Box>
          <Text
            fontWeight="bold"
            fontSize={["1.8rem", "1.35rem"]}
            mt={"0.5rem"}
          >
            Login
          </Text>
          <IconInput
            isLoading={isLoading}
            errors={errors}
            Icon={FaUser}
            placeholder="Username"
            onChange={handleFieldChange}
            autoFocus={true}
            name="email"
            value={fields.email}
          />
          <IconInput
            isLoading={isLoading}
            errors={errors}
            Icon={FaLock}
            placeholder="Password"
            onChange={handleFieldChange}
            value={fields.password}
            name="password"
            type="password"
          />
          <CustomButton
            data-test-id="login_submit"
            variantColor="purple"
            mt={"2.7rem"}
            type="submit"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Login
          </CustomButton>
          <Box d="flex" justifyContent="space-between" mt={"2rem"} w="100%">
            <Checkbox
              size="lg"
              variantColor="purple"
              isChecked={rememberMe}
              onChange={toggleRememberMe}
            >
              <Text type="bodyLight">Remember me</Text>
            </Checkbox>
            <Button
              className="forgot-password-button"
              variant="link"
              variantColor="text"
              as={Link}
              to={"/forgot-password"}
            >
              <Text type="bodyLight">Forgot Password?</Text>
            </Button>
          </Box>
          <Button
            as={Link}
            to={"/create-account"}
            variant="link"
            variantColor="text"
            mt={"2rem"}
            className="create-account-button"
          >
            <Text type="secondaryBodyLight">
              Interested in joining? <b>Create an account ›</b>
            </Text>{" "}
          </Button>
          <Button
            as={Link}
            to={"/read-more"}
            variant="link"
            variantColor="text"
            mt={"1rem"}
          >
            <Text type="secondaryBodyLight">
              Want to know more? <b>Read this</b>
            </Text>{" "}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default LoginScreen;
