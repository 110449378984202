import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, useToast } from "@chakra-ui/core";
import { Switch, Route, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getBatch, editBatch } from "../../actions/dashboardActions";
import { myDashboard } from "../../api";

import Text from "../../common/Text";
import TagCountDown from "../../common/TagCountDown";
import Filters from "../../common/Filters";
import Modal from "../../common/Modal";

import Vehicles from "./MyBatchVehicles";
import Offers from "./MyBatchOffers";
import Deals from "./MyBatchDeals";

import batchTypes from "../../common/meta/batchTypes";

const batchStatesMap = {
  unposted: "Not Posted Yet",
  active: "Posted",
  inactive: "Unposted",
  expired: "Expired",
};

const generalFilters = ["Vehicles", "Offers", "Deals"];

const filtersMap = {
  vehicles: "Vehicles",
  offers: "Offers",
  deals: "Deals",
};

const MyBatchDetails = ({ history, match }) => {
  const batch = useSelector((state) => state.dashboard.currentBatch);
  let { batchId } = match.params;
  useEffect(() => {
    if (!history.location.pathname.split("/")[4])
      history.push(`/dashboard/batches/${batchId}/vehicles`);
    if (!batch || !batch.object || !batch.object.id)
      dispatch(getBatch(batchId));
  }, [batch]);
  const dispatch = useDispatch();
  const [deleteOpened, setDeleteOpened] = useState(false);

  const selectedFilter = filtersMap[history.location.pathname.split("/")[4]];
  const toast = useToast();

  const navigateToFilter = (selectedTab) => {
    switch (selectedTab) {
      case "Vehicles":
        history.push(`/dashboard/batches/${batchId}/vehicles`);
        break;
      case "Offers":
        history.push(`/dashboard/batches/${batchId}/offers`);
        break;
      case "Deals":
        history.push(`/dashboard/batches/${batchId}/deals`);
        break;
      default:
        break;
    }
  };

  const addVehicle = () => history.push("add-vehicle");

  const handleEdit = () => {
    history.push("edit-batch");
  };

  const openDeleteModal = () => {
    setDeleteOpened(true);
  };

  const unPostBatch = () => {
    dispatch(editBatch(batch.object.id, { state: "inactive" }));
  };

  const deleteBatch = () => {
    myDashboard
      .deleteBatch(batch.object.id)
      .then((res) => {
        setDeleteOpened(false);
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
        toast({
          position: "top-right",
          title: "An error occured",
          description: error.detail,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setDeleteOpened(false);
      });
  };

  const postBatch = () => {
    dispatch(editBatch(batch.object.id, { state: "active" }));
  };

  const isPosted = batch?.object?.state === "active";

  return (
    <Box>
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text type="header" color="white">
          Batch Details
        </Text>
        <Text
          as={Link}
          to="/dashboard"
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
        >
          ‹ Back to Solution404 Used Cars & Vehicles
        </Text>
      </Box>
      {batch && batch.object && batch.object.id ? (
        <Box
          minH="8.5rem"
          mx="2.5rem"
          mt="-2rem"
          boxShadow="0px 3px 15px #0000000D"
          borderRadius="10px"
          bg="white"
          p="1rem"
          px="1.5rem"
          d="flex"
          flexDirection={["column", "row"]}
        >
          <Box
            w={["100%", "55%"]}
            h={["auto", "6.5rem"]}
            d="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box d={["flex", "none"]} mb="1rem">
              <Button
                variantColor="purple"
                variant="link"
                fontSize="1.1rem"
                fontWeight="bold"
                onClick={addVehicle}
              >
                {"+ Add Vehicle"}
              </Button>
              <Button
                variantColor="purple"
                variant="link"
                fontSize="1.1rem"
                fontWeight="bold"
                ml="1rem"
                onClick={handleEdit}
              >
                Edit Batch
              </Button>
            </Box>
            <Box>
              <Text type="secondaryBodyLight" fontSize={["1rem", "0.6rem"]}>
                Batch #{batch.object.id} / {batchTypes[batch.object.type]} /{" "}
                {batch.object.num_vehicles} vehicles /{" "}
                {batchStatesMap[batch.object.state]}
              </Text>
              <Text type="header">{batch.object.title}</Text>
              <Text type="bodyLight">{batch.object.description}</Text>
            </Box>
            <Text type="bodyMedium" display={["none", "block"]}>
              <span style={{ fontWeight: "bold" }}>Prices</span>{" "}
              {batch.object.show_prices ? "Visible" : "Hidden"}
              <span style={{ fontWeight: "bold" }}> Location</span>{" "}
              {batch.object.location}
              <span style={{ fontWeight: "bold" }}>
                Distribution
              </span> Network {batch.object.global_access && "& Global"}
            </Text>
            <Text
              mt="1rem"
              fontSize="1rem"
              type="bodyMedium"
              display={["block", "none"]}
            >
              <span style={{ fontWeight: "bold" }}>Prices</span>{" "}
              {batch.object.show_prices ? "Visible" : "Hidden"}
            </Text>
            <Text fontSize="1rem" type="bodyMedium" display={["block", "none"]}>
              <span style={{ fontWeight: "bold" }}>Location</span>{" "}
              {batch.object.location}
            </Text>
            <Text
              mb="1rem"
              fontSize="1rem"
              type="bodyMedium"
              display={["block", "none"]}
            >
              <span style={{ fontWeight: "bold" }}>Distribution</span> Network{" "}
              {batch.object.global_access && "& Global"}
            </Text>
          </Box>
          <Box
            w={["100%", "45%"]}
            h={["auto", "6.5rem"]}
            d="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems={["center", "flex-end"]}
            mt={["1rem", 0]}
          >
            <Box d={["none", "flex"]} justifyContent="flex-end" w="13rem">
              <Button
                variantColor="purple"
                variant="link"
                fontSize="0.7rem"
                onClick={addVehicle}
              >
                {"+ Add Vehicle"}
              </Button>
              <Button
                ml={4}
                variantColor="purple"
                variant="link"
                fontSize="0.7rem"
                onClick={handleEdit}
              >
                Edit Batch
              </Button>
              {batch?.object?.state !== "inactive" && (
                <Button
                  ml={4}
                  color="declined"
                  variant="link"
                  fontSize="0.7rem"
                  onClick={isPosted ? unPostBatch : openDeleteModal}
                >
                  {isPosted ? "Unpost Batch" : "Delete Batch"}
                </Button>
              )}
            </Box>
            <Box
              d="flex"
              justifyContent={["center", "flex-end"]}
              alignItems="center"
              flexDir={["column", "row"]}
              w={["100%", "90%"]}
            >
              <TagCountDown
                fromTime={new Date(batch.object.expiry_date)}
                isNotPosted={!isPosted}
              />
              {!isPosted && (
                <Button
                  ml={4}
                  onClick={postBatch}
                  mt={["1rem", 0]}
                  backgroundColor="success"
                  color="white"
                  borderRadius={["3rem", "1rem"]}
                  h={["3rem", "2rem"]}
                  w={["12rem", "9rem"]}
                >
                  Post Batch
                </Button>
              )}
              {batch?.object?.state !== "inactive" && (
                <Button
                  d={["block", "none"]}
                  color="declined"
                  variant="link"
                  fontSize="1rem"
                  mt={4}
                  onClick={isPosted ? unPostBatch : openDeleteModal}
                >
                  {isPosted ? "Unpost Batch" : "Delete Batch"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Skeleton
          minH="8.5rem"
          mx="2.5rem"
          mt="-2rem"
          boxShadow="0px 3px 15px #0000000D"
          borderRadius="10px"
          bg="white"
          p="1rem"
          px="1.5rem"
          d="flex"
          flexDirection={["column", "row"]}
          isLoaded={batch && batch.object && batch.object.id}
        />
      )}
      <Box mx="2.5rem" mt="1rem">
        <Filters
          generalFilters={generalFilters}
          selectedGeneral={selectedFilter || "Vehicles"}
          onSelectedGeneral={navigateToFilter}
          selectedFilter={selectedFilter}
        />
        <Box mb="1rem">
          <Switch>
            <Route
              path="/dashboard/batches/:batchId/vehicles"
              component={Vehicles}
            />
            <Route
              path="/dashboard/batches/:batchId/offers"
              component={Offers}
            />
            <Route path="/dashboard/batches/:batchId/deals" component={Deals} />
          </Switch>
        </Box>
      </Box>
      <Modal
        isOpen={deleteOpened}
        onClose={() => setDeleteOpened(false)}
        showFooter={true}
        footerText="Yes, Delete Batch"
        onFooterClick={deleteBatch}
      >
        <Text px="0.7rem" py="2rem" fontSize="1rem">
          Are you sure you want to delete this batch?
        </Text>
      </Modal>
    </Box>
  );
};

export default MyBatchDetails;
