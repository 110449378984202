import React from "react";
import { Box, Text, Image } from "@chakra-ui/core";

import desktopLogo from "../../assets/images/logoBlack.png";

const Logo = ({ logo = desktopLogo, imgH = "4rem", imgMl = "1.6rem" }) => {
  return (
    <Box h="100%" d="flex" alignItems="center">
      <Image h={imgH} src={logo} ml={imgMl} />
    </Box>
  );
};

export default Logo;
