import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getDeals as getDealsAction } from "../../actions/dashboardActions";

import { Box, Skeleton } from "@chakra-ui/core";

import Text from "../../common/Text";
import Filters from "../../common/Filters";
import Deal from "../../common/OfferDealStatus";

import {
  dealsVariantsMapping as variantsMapping,
  dealsTitlesMapping as titlesMapping,
  dealsDescriptionsMapping as descriptionsMapping,
  dealsTitlesToParams as titlesToParams,
} from "../../common/mappings";

const Deals = ({ history }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    userId: state.auth.user.id,
    deals: state.dashboard.deals.objects,
    pages: state.dashboard.deals.pages,
    page: state.dashboard.deals.page,
    loading: state.dashboard.deals.loading,
  }));
  const currentState = history.location.pathname.split("/")[3];

  const [detailedFilters, setDetailedFilters] = useState([
    {
      name: "Status",
      options: ["In Progress", "Completed", "Canceled"],
      selected: titlesMapping[currentState] || "",
    },
  ]);
  const searchKeyword = useSelector((state) => state.dashboard.searchKeyword);
  const dealsCount = useSelector((state) => state.dashboard.searchResultsCount.deals);
  useEffect(() => {
    if (searchKeyword) getDeals();
  }, [searchKeyword])

  useEffect(() => {
    getDeals();
  }, []);

  const getDeals = (page = 1) => {
    const params = {
      state: history.location.pathname.split("/")[3],
      q: searchKeyword,
      page,
    };
    dispatch(getDealsAction(params));
  };

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    setDetailedFilters(newDetailedFilter);
    dispatch(getDealsAction({ state: titlesToParams[value] || "", q: searchKeyword }));
    history.push(`/dashboard/deals/${titlesToParams[value] || ""}`);
  };

  return (
    <Box mt="1.5rem">
      <Box
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="1rem"
      >
        <Box>
          <Text type="header">Deals</Text>
          <Text type="bodyLight">
            {
              searchKeyword ? `${dealsCount} result${dealsCount > 1 ? "s" : ""} that match your search in Deals` : "Accepted offers by both parties are your deals"
            }
          </Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={selectDetailedFilter}
        />
      </Box>
      <Box>
        {store.loading ? (
          <>
            <Skeleton isLoaded={!store.loading}>
              <Box minHeight="7rem"></Box>
            </Skeleton>
            <Skeleton mt="1rem" isLoaded={!store.loading}>
              <Box minHeight="7rem"></Box>
            </Skeleton>
            <Skeleton mt="1rem" isLoaded={!store.loading}>
              <Box minHeight="7rem"></Box>
            </Skeleton>
          </>
        ) : (
            store.deals.map((deal) => (
              <Deal
                showBuyer={true}
                buyer={deal.buyer}
                key={deal.id}
                id={deal.id}
                variant={variantsMapping[deal.state || "in_progress"]}
                title={titlesMapping[deal.state || "in_progress"]}
                description={descriptionsMapping[deal.state || "in_progress"]}
                linkTo={`deals/details/${deal.id}`}
                deal={deal}
                type="deal"
              />
            ))
          )}
      </Box>
    </Box>
  );
};

export default Deals;
