import React from "react";
import { Link } from "react-router-dom";

import { Grid, Box, Image } from "@chakra-ui/core";
import { FaCar } from "react-icons/fa";

import FeaturedContainer from "../../common/FeaturedContainer";
import TagCountDown from "../../common/TagCountDown";
import TagOutline from "../../common/TagOutline";
import Text from "../../common/Text";

import { currencyFormat, mileageFormat } from "../../common/utils";
import { useSelector } from "react-redux";

const CarRow = ({ noBorder, car, showPrice }) => {
  const currencies = useSelector(state => state.statics.currencies);
  const symbol = currencies.find(c => c.name === car.currency)?.symbol;

  return (
    <>
      <Grid
        columnGap="1.5rem"
        templateColumns="2rem 6.5fr 1.3fr 10fr 2fr 2fr"
        fontSize={"0.6rem"}
        fontWeight="light"
        color={"text.400"}
        alignItems="center"
        borderBottomColor="surface"
        borderBottomWidth={noBorder ? 0 : "2px"}
        display={["none", "grid"]}
      >
        <Image
          w={8}
          src={
            car.image ||
            "https://www.allcarleasing.co.uk/images/img-placeholder.jpg"
          }
        ></Image>
        <Text type="secondaryBodyRegular" textTransform="uppercase">
          {car.title}
        </Text>
        <Text type="secondaryBodyLight">{car.year}</Text>
        <Text textTransform="capitalize" type="secondaryBodyLight">
          {mileageFormat(car.mileage)} / {car.color || "N/A"} /{" "}
          {car.transmission} / {car.regional_specs}
        </Text>
        <Text type="secondaryBodyLight">
          {showPrice ? currencyFormat(car.price, symbol) : ""}
        </Text>
        <Text type="secondaryBodyLight">#{car.id}</Text>
      </Grid>
      <Box
        display={["flex", "none"]}
        flexDirection="column"
        justifyContent="space-between"
        minHeight="10rem"
        height="10rem"
        p="1rem"
        borderBottom="3px solid"
        borderBottomColor="surface"
      >
        <Box d="flex">
          <Text color="text.500" fontWeight="light">
            #{car.id}
          </Text>
          <Text ml="1rem" color="text.400" fontWeight="light">
            {car.year}
          </Text>
          <Text ml="1rem" color="text.400" fontWeight="light">
            {showPrice ? currencyFormat(car.price, symbol) : ""}
          </Text>
        </Box>
        <Box d="flex" alignItems="center">
          <Image
            w="45px"
            h="35px"
            src={
              car.image ||
              "https://www.allcarleasing.co.uk/images/img-placeholder.jpg"
            }
          ></Image>
          <Text
            ml="1rem"
            fontSize="1.1rem"
            textTransform="uppercase"
            color="text.500"
          >
            {car.title}
          </Text>
        </Box>
        <Text
          fontSize="1.1rem"
          color="text.400"
          overflow="hidden"
          lineHeight="1.5rem"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          fontWeight="light"
          w="calc(100vw - 9rem)"
        >
          {mileageFormat(car.mileage)}km / {car.color || "N/A"} /{" "}
          {car.transmission} / {car.regional_specs}
        </Text>
      </Box>
    </>
  );
};

const Batch = ({ variant, batch }) => {
  variant = batch.urgent ? "urgent" : batch.hot ? "hot" : "";
  return (
    <Link to={`/marketplace/batches/${batch.id}`}>
      <FeaturedContainer variant={variant}>
        <Grid
          py={[0, "1rem"]}
          px="0.5rem"
          columnGap="1.5rem"
          rowGap="1rem"
          templateColumns={[null, "14rem auto"]}
          gridAutoFlow="row"
          overflowX={["hidden", "auto"]}
          w="100%"
        >
          <Box
            borderBottom={
              batch.vehicles_preview && batch.vehicles_preview.length > 0
                ? ["3px solid", "0px"]
                : "0px"
            }
            borderBottomColor="surface"
            p={["1rem", 0]}
          >
            <Box
              d="flex"
              alignItems="center"
              mt={"5px"}
              fontSize={["1.1rem", "0.65rem"]}
            >
              <Text fontWeight="light" color="text.400">
                Batch #{batch.id}
              </Text>
              {variant && variant.length > 0 && (
                <Text color={variant} fontWeight={"bold"} ml={"1rem"}>
                  {batch.urgent ? "Urgent" : "Hot"}
                </Text>
              )}
            </Box>
            <Text
              fontWeight="bold"
              fontSize={["1.5rem", "0.8rem"]}
              color="text.500"
              maxW={[null, "12rem"]}
              lineHeight={["1.7rem", "1rem"]}
              mt={1}
            >
              {batch.title}
            </Text>
            <Text
              type="bodyLight"
              color="text.400"
              maxW="10rem"
              mt={1}
              fontSize={["0.9rem", "0.5rem"]}
            >
              {batch.description}
            </Text>
            <Box mt={2} color="text.500" d="flex" alignItems="center">
              <TagOutline text={batch.type} />
              <Box ml="13px" as={FaCar} size={["1.2rem", "1rem"]}></Box>
              <Text fontSize={["1.1rem", "0.6rem"]} ml="6px">
                {batch.num_vehicles}
              </Text>
            </Box>
            <TagCountDown mt={[4, 2]} fromTime={batch.expiry_date} />
          </Box>
          <Grid templateRows={["1fr, 1fr, 1fr", "1fr 1fr 1fr 1fr"]}>
            {batch.vehicles_preview &&
              batch.vehicles_preview
                .slice(0, 3)
                .map((car, index) => (
                  <CarRow
                    key={car.id}
                    car={car}
                    showPrice={batch.show_prices}
                    noBorder={batch.vehicles_preview.length == index}
                  />
                ))}
          </Grid>
        </Grid>
      </FeaturedContainer>
    </Link>
  );
};

export default Batch;
