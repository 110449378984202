import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@chakra-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { getBatchDeals } from "../../actions/dashboardActions";

import Deal from "../../common/OfferDealStatus";
import Text from "../../common/Text";
import Filters from "../../common/Filters";

import {
  dealsVariantsMapping as variantsMapping,
  dealsTitlesMapping as titlesMapping,
  dealsDescriptionsMapping as descriptionsMapping,
  dealsTitlesToParams as titlesToParams,
} from "../../common/mappings";

const MyBatchDetails = ({ history, match }) => {
  const loading = useSelector((state) => state.dashboard.loading);
  const currentBatch = useSelector((state) => state.dashboard.currentBatch);
  const currentState = history.location.pathname.split("/")[5];
  const { batchId } = match.params;
  const dispatch = useDispatch();

  const [detailedFilters, setDetailedFilters] = useState([
    {
      name: "Status",
      options: ["In Progress", "Completed", "Canceled"],
      selected: titlesMapping[currentState] || "",
    },
  ]);

  useEffect(() => {
    dispatch(
      getBatchDeals(batchId, {
        state: currentState,
      })
    );
  }, []);

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    setDetailedFilters(newDetailedFilter);
    dispatch(getBatchDeals(batchId, { state: titlesToParams[value] || "" }));
    history.push(
      `/dashboard/batches/${batchId}/deals/${titlesToParams[value] || ""}`
    );
  };
  return (
    <Box mt="1rem">
      <Box
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        py="1rem"
      >
        <Box w="8rem">
          <Text type="header">Deals</Text>
          <Text type="bodyLight" maxW={["100%", "60%"]}>
            Showing {currentBatch.deals.length} Deals
          </Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={selectDetailedFilter}
        />
      </Box>
      {loading ? (
        <>
          <Skeleton isLoaded={!loading}>
            <Box minHeight="7rem"></Box>
          </Skeleton>
          <Skeleton mt="1rem" isLoaded={!loading}>
            <Box minHeight="7rem"></Box>
          </Skeleton>
          <Skeleton mt="1rem" isLoaded={!loading}>
            <Box minHeight="7rem"></Box>
          </Skeleton>
        </>
      ) : (
        currentBatch.deals.map((deal) => (
          <Deal
            key={deal.id}
            id={deal.id}
            variant={variantsMapping[deal.state || "in_progress"]}
            title={titlesMapping[deal.state || "in_progress"]}
            description={descriptionsMapping[deal.state || "in_progress"]}
            linkTo={`/dashboard/deals/details/${deal.id}`}
            deal={deal}
            type="deal"
          />
        ))
      )}
    </Box>
  );
};

export default MyBatchDetails;
