import React from "react";
import { Switch, Route } from "react-router-dom";

import Marketplace from "./Marketplace";
import BatchDetails from "../batchDetails/BatchDetails";
import VehicleDetails from "../vehicleDetails/VehicleDetails";

const MarketplaceRouter = () => {
  return (
    <Switch>
      <Route
        path="/marketplace/batches/:batchId/vehicle/:vehicleId"
        component={VehicleDetails}
      />
      <Route path="/marketplace/batches/:batchId" component={BatchDetails} />
      <Route path="/marketplace" component={Marketplace} />
    </Switch>
  );
};

export default MarketplaceRouter;
