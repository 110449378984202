import React, { useState } from "react";
import { Box } from "@chakra-ui/core";

import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const MobileGeneralFilters = ({
  generalFilters = [],
  onSelectedGeneral,
  selectedGeneral,
}) => {
  const [selected, setSelected] = useState(selectedGeneral);
  const [shownFilters, setShownFilters] = useState({ start: 0, end: 4 });

  const toNext = () => {
    const newShownFilters = { ...shownFilters };
    newShownFilters.start = newShownFilters.start + 1;
    newShownFilters.end = newShownFilters.end + 1;

    setShownFilters(newShownFilters);
  };

  const toPrevious = () => {
    const newShownFilters = { ...shownFilters };
    newShownFilters.start = newShownFilters.start - 1;
    newShownFilters.end = newShownFilters.end - 1;

    setShownFilters(newShownFilters);
  };

  return (
    <Box
      bg="white"
      borderRadius="2rem"
      d={["flex", "none"]}
      overflowX="hidden"
      maxWidth={["calc(100vw - 5rem)", "100%"]}
      boxShadow={["0px 3px 15px #00000014", 0]}
    >
      {shownFilters.start > 0 && (
        <Box
          bg={"white"}
          cursor="pointer"
          mx={4}
          borderRadius="2rem"
          color="black"
          textAlign="center"
          marginY="auto"
          fontWeight={"light"}
          size="1rem"
          onClick={toPrevious}
          as={FaChevronLeft}
        />
      )}
      {generalFilters
        .slice(shownFilters.start, shownFilters.end)
        .map((filter) => (
          <Box
            key={filter}
            bg={selected === filter ? "purple.500" : "white"}
            cursor="pointer"
            px={2}
            py={[1, 2]}
            borderRadius="2rem"
            color={selected === filter ? "white" : "black"}
            textAlign="center"
            margin="auto"
            fontWeight={[selected === filter ? "500" : "light", "500"]}
            fontSize={["1.1rem", "0.7rem"]}
            whiteSpace="nowrap"
            onClick={() => {
              setSelected(filter);
              onSelectedGeneral && onSelectedGeneral(filter);
            }}
          >
            {filter}
          </Box>
        ))}
      {shownFilters.end < generalFilters.length && (
        <Box
          bg={"white"}
          cursor="pointer"
          mx={4}
          borderRadius="2rem"
          color="black"
          textAlign="center"
          marginY="auto"
          fontWeight={"light"}
          size="1rem"
          onClick={toNext}
          as={FaChevronRight}
        />
      )}
    </Box>
  );
};

export default MobileGeneralFilters;
