import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@chakra-ui/core";

import { currencyFormat } from "./utils";
import FeaturedContainer from "./FeaturedContainer";
import TagCountDown from "./TagCountDown";
import UserTag from "./UserTag";
import Text from "./Text";
import moment from "moment";

const initOffer = { batch: { dealer: {} }, vehicle_offers: [] };
const initDeal = { batch: { dealer: {} }, vehicle_deals: [] };

const Offer = ({
  id,
  variant,
  title,
  description,
  linkTo,
  offer = initOffer,
  deal = initDeal,
  type = "offer",
  showBuyer,
  buyer
}) => {
  const { batch } = type === "offer" ? offer : deal;
  return (
    <>
      <FeaturedContainer
        variant={variant}
        minHeight={["7rem", "5rem"]}
        as={Link}
        to={linkTo}
        d={["none", "-webkit-box"]}
      >
        <Grid
          columnGap="0.6rem"
          templateColumns="1fr 1fr"
          w="100%"
          minW={[600, 0]}
        >
          <Box
            pb="0.8rem"
            p="0.6rem"
            d="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Box
                w="100%"
                d="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Text
                  type="largeBodyBold"
                  color={variant}
                  mb="0.1rem"
                  w="37.5%"
                >
                  {title}
                </Text>

                <Box h="1px" bg="transparent" w="25%" mr="1rem"></Box>
                <Text type="largeBodyBold" mb="0.1rem" w="37.5%">
                  {type === "offer"
                    ? offer.is_counter
                      ? 0
                      : offer.vehicle_offers.length
                    : deal.vehicle_deals.length}{" "}
                  vehicles /{" "}
                  {currencyFormat(type === "offer" ? offer.price : deal.price)}
                </Text>
              </Box>

              <Box w="100%" d="flex" justifyContent="flex-start">
                <Text type="secondaryBodyLight" w="37.5%">
                  {description}
                </Text>
                <Box w="25%" mr="1rem"></Box>
                <Text type="secondaryBodyLight" w="37.5%">
                  {type === "offer" ? "Offer" : "Deal"} #{id}
                </Text>
              </Box>
            </Box>

            <Text type="secondaryBodyLight" w="37.5%" opacity="0.5">
              {moment(offer.create_date || new Date()).fromNow()}
            </Text>
          </Box>
          <Box pb="0.8rem" d="flex" p="0.6rem">
            <Box w="50%">
              <Text type="largeBodyBold" mb="0.1rem">
                {batch.description}
              </Text>
              <Text
                type="secondaryBodyLight"
                w="100%"
                textTransform="capitalize"
              >
                Batch #{batch.id} / {batch.type} /{" "}
                {batch ? batch.num_vehicles : 0} Vehicles
              </Text>
            </Box>
            <Box w="50%">
              <UserTag
                image={showBuyer ? buyer.image : batch.dealer.image}
                name={showBuyer ? buyer.fullname : batch.dealer.fullname}
                company={showBuyer ? buyer.company : batch.dealer.company}
              />
              <TagCountDown mt={2} fromTime={batch.expiry_date} />
            </Box>
          </Box>
        </Grid>
      </FeaturedContainer>
      <FeaturedContainer
        variant={variant}
        as={Link}
        to={linkTo}
        d={["-webkit-box", "none"]}
      >
        <Box p="1.5rem" mr="2rem">
          <Box d="flex" fontSize="1rem">
            <Text color="text.500" fontWeight="light">
              {type === "offer" ? "Offer" : "Deal"} #{id}
            </Text>
            <Text color="text.500" fontWeight="light" ml="1rem">
              {moment(offer.create_date || new Date()).fromNow()}
            </Text>
          </Box>
          <Text
            type="largeBodyBold"
            color={variant}
            fontSize="1.8rem"
            mt="0.5rem"
          >
            {title}
          </Text>
          <Text fontSize="1.1rem" fontWeight="bold" mt="0.5rem">
            {description}
          </Text>
          <Text fontSize="1.5rem" fontWeight="bold" my="1.5rem">
            {type === "offer"
              ? offer.is_counter
                ? 0
                : offer.vehicle_offers.length
              : deal.vehicle_deals.length}{" "}
            vehicles /{" "}
            {currencyFormat(type === "offer" ? offer.price : deal.price)}
          </Text>
          <UserTag
            name={batch.dealer.fullname}
            company={batch.dealer.company}
          />
          <TagCountDown mt="1rem" fromTime={batch.expiry_date} />
        </Box>
      </FeaturedContainer>
    </>
  );
};

export default Offer;
