import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, FormControl, FormErrorMessage } from "@chakra-ui/core";

import Input from "../../common/Input";
import Header from "../login/Header";
import { FaLock } from "react-icons/fa";

import validate from "validate.js";
import constraints from "../../common/generalConstraints";

import Button from "../../common/Button";
import Text from "../../common/Text";

const CreatePassword = ({
  handleChange,
  password,
  isLoading,
  createFirebaseAccount,
}) => {
  const [errors, setErrors] = useState({});

  const submitPassword = (e) => {
    e.preventDefault();
    const validationErrors = validate({ password }, constraints);

    if (validationErrors && validationErrors.password) {
      setErrors({ ...errors, password: validationErrors.password[0] });
    } else {
      createFirebaseAccount();
    }
  };

  return (
    <Box w="full" h="100vh">
      <Box className="gradient-background" h="30vh">
        <Header />
      </Box>
      <Box
        shadow="lg"
        bg="white"
        w={["100%", "100%", "50%", "25%"]}
        maxWidth={"17rem"}
        minWidth={["100%", "17rem"]}
        m="auto"
        rounded="lg"
        mt={[0, -16]}
      >
        <Box
          borderTopRightRadius="lg"
          borderTopLeftRadius="lg"
          h={130}
          bg="surface"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Text textAlign="center" type="largeBodyBold" color="purple.500">
            Solution 404 Used Cars & Vehicles
          </Text>
        </Box>
        <Box py={"2.5rem"} px={"1.9rem"}>
          <form onSubmit={submitPassword}>
            <Box
              w={75}
              h={75}
              borderRadius={75}
              bg="purple.500"
              d="flex"
              justifyContent="center"
              alignItems="center"
              m="auto"
              mt="-3.5rem"
            >
              <Box as={FaLock} size="32px" color="white" bg="purple.500"></Box>
            </Box>{" "}
            <Text type="header" textAlign="center" mt={6}>
              Create a Password
            </Text>
            <Text type="secondaryBodyLight" textAlign="center" pt={2} px={6}>
              Use this password to log into your SeezOne account with your email
            </Text>
            <FormControl
              isInvalid={errors.password && errors.password.length > 0}
              px={4}
              mt="2rem"
            >
              <Input
                onChange={handleChange}
                value={password}
                name="password"
                mt={6}
                variant="flushed"
                placeholder="Password"
                type="password"
                autoFocus={true}
              />
              <FormErrorMessage className="password-error-message">
                {errors.password}
              </FormErrorMessage>
            </FormControl>
            <Box w="full" textAlign="center" pt={8}>
              <Button isLoading={isLoading}>Continue</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(CreatePassword);
