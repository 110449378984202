import React, { useState } from "react";

import { Box, Collapse, Text } from "@chakra-ui/core";
import LandingHeader from "./LandingHeader";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Faq = () => {
  return (
    <Box backgroundColor="surface" minH="100vh">
      <LandingHeader backgroundColor="purple.500" px={["2rem", "5.4rem"]} />
      <Box px={["2rem", "5.4rem"]} py="3rem">
        <Text
          fontSize="0.75rem"
          color="purple.500"
          textTransform="uppercase"
          letterSpacing="3px"
          fontWeight="medium"
        >
          Seezone
        </Text>
        <Text fontSize="2rem" color="#333" letterSpacing="-1px">
          Frequently Asked Questions{" "}
        </Text>
        <Question
          title={"What is SeezOne"}
          text={
            "SeezOne is the global digital marketplace for wholesale vehicle trading. It is a new online solution for international B2B vehicle trading. Our unique platform connects trusted vehicle traders from around the world and makes it easy for buyers and sellers to do business digitally. Users can buy custom selections of vehicles, sell car stock in batches, digitize their trading businesses, and establish a global network. Buyers can browse batches of vehicles from international sellers (new, used, ex-rental, and more), filter vehicles and view individual cars in each batch (including specs, photos, and reports), make offers on custom selections of vehicles from any batch with one click, and agree on deals or receive counter-offers from sellers. After the buyer and seller make a deal they can process payments safely. SeezOne keeps track of offers, deals, budget and connections on one easy-to-use platform."
          }
        />
        <Question
          title={"What is SeezOne"}
          text={
            "SeezOne is the global digital marketplace for wholesale vehicle trading. It is a new online solution for international B2B vehicle trading. Our unique platform connects trusted vehicle traders from around the world and makes it easy for buyers and sellers to do business digitally. Users can buy custom selections of vehicles, sell car stock in batches, digitize their trading businesses, and establish a global network. Buyers can browse batches of vehicles from international sellers (new, used, ex-rental, and more), filter vehicles and view individual cars in each batch (including specs, photos, and reports), make offers on custom selections of vehicles from any batch with one click, and agree on deals or receive counter-offers from sellers. After the buyer and seller make a deal they can process payments safely. SeezOne keeps track of offers, deals, budget and connections on one easy-to-use platform."
          }
        />
        <Question
          title={"What is SeezOne"}
          text={
            "SeezOne is the global digital marketplace for wholesale vehicle trading. It is a new online solution for international B2B vehicle trading. Our unique platform connects trusted vehicle traders from around the world and makes it easy for buyers and sellers to do business digitally. Users can buy custom selections of vehicles, sell car stock in batches, digitize their trading businesses, and establish a global network. Buyers can browse batches of vehicles from international sellers (new, used, ex-rental, and more), filter vehicles and view individual cars in each batch (including specs, photos, and reports), make offers on custom selections of vehicles from any batch with one click, and agree on deals or receive counter-offers from sellers. After the buyer and seller make a deal they can process payments safely. SeezOne keeps track of offers, deals, budget and connections on one easy-to-use platform."
          }
        />
        <Question
          title={"What is SeezOne"}
          text={
            "SeezOne is the global digital marketplace for wholesale vehicle trading. It is a new online solution for international B2B vehicle trading. Our unique platform connects trusted vehicle traders from around the world and makes it easy for buyers and sellers to do business digitally. Users can buy custom selections of vehicles, sell car stock in batches, digitize their trading businesses, and establish a global network. Buyers can browse batches of vehicles from international sellers (new, used, ex-rental, and more), filter vehicles and view individual cars in each batch (including specs, photos, and reports), make offers on custom selections of vehicles from any batch with one click, and agree on deals or receive counter-offers from sellers. After the buyer and seller make a deal they can process payments safely. SeezOne keeps track of offers, deals, budget and connections on one easy-to-use platform."
          }
        />
        <Question
          title={"What is SeezOne"}
          text={
            "SeezOne is the global digital marketplace for wholesale vehicle trading. It is a new online solution for international B2B vehicle trading. Our unique platform connects trusted vehicle traders from around the world and makes it easy for buyers and sellers to do business digitally. Users can buy custom selections of vehicles, sell car stock in batches, digitize their trading businesses, and establish a global network. Buyers can browse batches of vehicles from international sellers (new, used, ex-rental, and more), filter vehicles and view individual cars in each batch (including specs, photos, and reports), make offers on custom selections of vehicles from any batch with one click, and agree on deals or receive counter-offers from sellers. After the buyer and seller make a deal they can process payments safely. SeezOne keeps track of offers, deals, budget and connections on one easy-to-use platform."
          }
        />
      </Box>
    </Box>
  );
};

const Question = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapse
      mt={["1rem", "0.5rem"]}
      maxW={["100%", "60%"]}
      background="white"
      startingHeight={"3.5rem"}
      boxShadow="0px 3px 15px #0000000A"
      borderRadius="10px"
      isOpen={isOpen}
      position="relative"
    >
      {isOpen && (
        <Box
          h={"100%"}
          w="10px"
          borderTopLeftRadius={"10px"}
          borderBottomLeftRadius={"10px"}
          backgroundColor="purple.500"
          position="absolute"
          left={0}
          top={0}
        ></Box>
      )}
      <Box
        w="100%"
        p="0.5rem"
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
        position="relative"
      >
        {!isOpen && (
          <Box
            h={"100%"}
            w="10px"
            borderTopLeftRadius={"10px"}
            borderBottomLeftRadius={"10px"}
            backgroundColor="text.500"
            position="absolute"
            left={0}
            top={0}
          ></Box>
        )}
        <Text
          ml="0.5rem"
          fontSize={["1.5rem", "1rem"]}
          color={isOpen ? "purple.500" : "text.500"}
          letterSpacing="0px"
          fontWeight="bold"
        >
          {title}
        </Text>
        <Box
          as={isOpen ? FaChevronUp : FaChevronDown}
          color={isOpen ? "purple.500" : "text.500"}
        />
      </Box>
      {isOpen && (
        <Text p="1rem" fontSize={["1rem", "0.75rem"]} color="text.500">
          {text}
        </Text>
      )}
    </Collapse>
  );
};

export default Faq;
