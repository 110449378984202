import {
  get,
  post,
  patch,
  del,
  toUrlParams,
  fileUpload,
  head,
} from "./helpers";
import { headers } from "./headers";

export const auth = {
  register: (data) => post("users/register/", { isAuth: true, data }),
  getLoggedInUser: () => get("users/current/", { isAuth: true }),
  editLoggedInUser: (data) => patch("users/current/", { isAuth: true, data }),
  getUser: (userId) => get(`users/${userId}/`, { isAuth: true }),
  getUserOffers: (userId, params) =>
    get(`users/${userId}/offers/${toUrlParams(params)}`, { isAuth: true }),
  getUserDeals: (userId, params) =>
    get(`users/${userId}/deals/${toUrlParams(params)}`, { isAuth: true }),
  editUser: (userId, data) => patch(`users/${userId}/`, { isAuth: true, data }),
};

export const marketplace = {
  batches: (params) =>
    get(`marketplace/batches/${toUrlParams(params)}`, {
      isAuth: true,
    }),
  featured: () => get("marketplace/featured/", { isAuth: true }),
  offers: (params) =>
    get(`marketplace/offers/${toUrlParams(params)}`, { isAuth: true }),
  deals: (params) =>
    get(`marketplace/deals/${toUrlParams(params)}`, { isAuth: true }),
  headOffers: () => head(`marketplace/offers/`, { isAuth: true }),
  headDeals: () => head(`marketplace/deals/`, { isAuth: true }),
};

export const myDashboard = {
  batches: (params) => get(`batches/${toUrlParams(params)}`, { isAuth: true }),
  createBatch: (data) => post("batches/", { isAuth: true, data }),
  editBatch: (batchId, data) =>
    patch(`batches/${batchId}/`, { isAuth: true, data }),
  getBatch: (batchId) => get(`batches/${batchId}/`, { isAuth: true }),
  deleteBatch: (batchId) => del(`batches/${batchId}/`, { isAuth: true }),
  addVehicle: (batchId, data) =>
    post(`batches/${batchId}/vehicles/`, { isAuth: true, data }),
  getVehicles: (batchId, params) =>
    get(`batches/${batchId}/vehicles/${toUrlParams(params)}`, { isAuth: true }),
  getVehicle: (batchId, vehicleId) =>
    get(`batches/${batchId}/vehicles/${vehicleId}/`, { isAuth: true }),
  editVehicle: (vehicleId, data) =>
    patch(`batches/vehicles/${vehicleId}/`, { isAuth: true, data }),
  getBatchOffers: (batchId, params) =>
    get(`batches/${batchId}/offers/${toUrlParams(params)}`, { isAuth: true }),
  getBatchDeals: (batchId, params) =>
    get(`batches/${batchId}/deals/${toUrlParams(params)}`, { isAuth: true }),
  getNetworkUsers: (params) =>
    get(`users/${toUrlParams(params)}`, { isAuth: true }),
  getActivities: () => get(`activities/daily/`, { isAuth: true }),
  sendInvitations: (data) => post(`invitations/`, { isAuth: true, data }),
  getInvitationsUrl: () => get(`invitations/url/`, { isAuth: true }),
  headBatchesRequireAction: () =>
    head(`batches/?state=expired`, { isAuth: true }),
  headOffersRequireResponse: () =>
    head(`offers/?state=pending`, { isAuth: true }),
  headDealsInProgress: () =>
    head(`deals/?state=in_progress`, { isAuth: true }),
  headDealersRequireOnboarding: () =>
    head(`users/?state=pending`, { isAuth: true }),
  headBatches: (params) => head(`batches/${toUrlParams(params)}`, { isAuth: true }),
  headOffers: (params) => head(`offers/${toUrlParams(params)}`, { isAuth: true }),
  headDeals: (params) => head(`deals/${toUrlParams(params)}`, { isAuth: true }),
  headUsers: (params) => head(`users/${toUrlParams(params)}`, { isAuth: true }),
};

export const batches = {
  getSingle: (id) => get(`batches/${id}/`, { isAuth: true }),
  getVehicles: (batchId, params) =>
    get(`batches/${batchId}/vehicles/${toUrlParams(params)}`, {
      isAuth: true,
    }),
  createOffer: (batch_id, vehicle_offers) =>
    post(`batches/${batch_id}/offers/`, {
      data: { vehicle_offers },
      isAuth: true,
    }),
};

export const offers = {
  getSingle: (id) => get(`offers/${id}/`, { isAuth: true }),
  getOffers: (params) => get(`offers/${toUrlParams(params)}`, { isAuth: true }),
  getVehicles: (id) => get(`offers/${id}/vehicles/`, { isAuth: true }),
  modify: (id, vehicle_offers) =>
    patch(`offers/${id}/`, { isAuth: true, data: { vehicle_offers } }),
  reject: (id) => post(`offers/${id}/reject/`, { isAuth: true }),
  accept: (id) => post(`offers/${id}/accept/`, { isAuth: true }),
  withdraw: (id) => post(`offers/${id}/withdraw/`, { isAuth: true }),
  counter: (id, data) => post(`offers/${id}/counter/`, { isAuth: true, data }),
};

export const deals = {
  getSingle: (id) => get(`deals/${id}/`, { isAuth: true }),
  getVehicles: (id, params) =>
    get(`deals/${id}/vehicles/${toUrlParams(params)}`, { isAuth: true }),
  getDeals: (params) => get(`deals/${toUrlParams(params)}`, { isAuth: true }),
  editDeal: (dealId, data) => patch(`deals/${dealId}/`, { isAuth: true, data }),
};

export const statics = {
  currencies: () => get("statics/currencies/", { isAuth: true })
}

export const imageUpload = (file) => fileUpload(file, "images/", { isAuth: false });
export const attachmentUpload = (file) => fileUpload(file, "attachments/", { isAuth: false });
export const csvUpload = (batchId, file) => fileUpload(file, `batches/${batchId}/vehicles/upload/`, { isAuth: true });

export const getNotifications = (params = {}) =>
  get(`notifications/${toUrlParams(params)}`, { isAuth: true });
