import React from "react";
import { Box } from "@chakra-ui/core";
import { Link } from "react-router-dom";

import { MdChevronRight } from "react-icons/md";
import Text from "../../common/Text";
import FeaturedContainer from "../../common/FeaturedContainer";
import Button from "../../common/Button";

const CounterOfferStates = (props) => {
  console.log({ props });
  const findType = () => {
    switch (props.type) {
      case "pending":
        return <Pending {...props} />;
      case "accepted":
        return <Accepted {...props} />;
      case "rejected":
        return <Rejected {...props} />;

      default:
        return null;
    }
  };

  return findType();
};

const Pending = ({ accept, reject, loading }) => {
  return (
    <FeaturedContainer
      h={["100%", "7rem"]}
      mt={["1rem", "0.6rem"]}
      variant=""
      p="1rem"
      pl="2rem"
      d="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Text type="header" mb="0.5rem">
          Counter-Offer Received
        </Text>
        <Text type="secondaryBodyLight">
          The seller has made a counter-offer. Please review and respond.
        </Text>
      </Box>
      <Box
        d={["flex", "block"]}
        alignItems="flex-end"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Button bg="success" onClick={accept} isLoading={loading}>
          Accept
        </Button>
        <Button
          bg="error"
          ml={[0, "1rem"]}
          mt={["1rem", 0]}
          onClick={reject}
          isLoading={loading}
        >
          Decline
        </Button>
      </Box>
    </FeaturedContainer>
  );
};

const Accepted = ({ deal }) => {
  return (
    <FeaturedContainer h="7rem" mt={["1rem", "0.6rem"]} variant="success">
      <Box w="100%" h="100%" d="flex" flexDirection="column">
        <Box pl="1rem" h="70%">
          <Text type="header" mb="0.5rem" mt="1rem">
            Counter-Offer Accepted
          </Text>
          <Text type="secondaryBodyLight" mb="1rem">
            This offer was accepted by both parties. You can view it in Deals.
          </Text>
        </Box>
        <Box
          bg="gray.400"
          w="100%"
          h={["3rem", "30%"]}
          d="flex"
          alignItems="center"
        >
          <Text
            as={Link}
            to={deal?.id ? `/deals/details/${deal.id}` : "/deals"}
            color="purple.500"
            d="flex"
            alignItems="center"
            type="secondaryBodyBold"
            ml="1rem"
          >
            Continue to Deal Details
            <Box as={MdChevronRight} fontWeight="light" />
          </Text>
        </Box>
      </Box>
    </FeaturedContainer>
  );
};

const Rejected = () => {
  return (
    <FeaturedContainer minH="7rem" mt="0.6rem" variant="error">
      <Box p="1rem">
        <Text type="header" mb="0.5rem" color="error">
          Counter-Offer Declined
        </Text>
        <Text type="secondaryBodyLight" mb="1rem">
          You declined this offer.
        </Text>
      </Box>
    </FeaturedContainer>
  );
};

export default CounterOfferStates;
