import React from 'react';

import { Box } from "@chakra-ui/core";
import { FaCheck } from "react-icons/fa"

const labelStyles = {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top",
    cursor: "pointer"
}

const disabledStyles = {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top",
    cursor: "not-allowed"
}

// margin-left: 0.5rem;
// font-size: 16px;
// -webkit-user-select: none;
// -moz-user-select: none;
// -ms-user-select: none;
// user-select: none;
// opacity: 1;

const CheckBox = ({ isChecked, onChange, isDisabled, children, wrapperStyle = {} }) => {
    let style = isDisabled ? disabledStyles : labelStyles;
    style = { ...style, ...wrapperStyle }
    return (
        <label style={style}>
            <input type="checkbox" style={{ width: 0, height: 0 }} onChange={onChange} disabled={isDisabled} checked={isChecked} />
            <Box w="1rem" h="1rem" border="1px solid #707070" borderRadius="3px" d="flex" justifyContent="center" alignItems="center">
                {isChecked && <Box as={FaCheck} color="purple.400" size="0.6rem" />}
            </Box>
            <Box ml={"0.5rem"}>
                {children}
            </Box>
        </label >
    );
};

export default CheckBox;