import React, { Fragment, useEffect, useState } from 'react';

import { auth } from "../../api"
import { dealsVariantsMapping, dealsTitlesMapping, dealsDescriptionsMapping } from '../../common/mappings';

import Deal from "../../common/OfferDealStatus"
import Text from "../../common/Text"
import Filters from "../../common/Filters"

import { Box, Skeleton } from '@chakra-ui/core';
import Pagination from '../../common/Pagination';

const detailedFilters = [{
    name: "Status",
    options: ["All Deals", "In Progress", "Completed", "Cancelled"]
}];

const NetworkUserDeals = ({ match }) => {
    const [deals, setDeals] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(true);

    const { userId } = match.params

    useEffect(() => {
        getDeals()
    }, [page, state])

    const getDeals = () => {
        setLoading(true);
        const params = {
            state,
            page
        }
        auth.getUserDeals(userId, params).then(res => {
            setLoading(false);
            setDeals(res.objects);
            setPages(res.pages);
        })
    }

    const setFilter = (selectedTab) => {
        switch (selectedTab) {
            case "All Deals":
                setState("")
                break;
            case "In Progress":
                setState("in_progress")
                break;
            case "Completed":
                setState("completed")
                break;
            case "Cancelled":
                setState("cancelled")
                break;

            default:
                break;
        }
    };

    return <Fragment>
        <Box
            mx="2.5rem"
            mt={"1rem"}
            d="flex"
            justifyContent="space-between"
        >
            <Box>
                <Text type="header">Deals</Text>
                <Text type="bodyLight">Showing {deals.length} Deals</Text>
            </Box>
            <Filters
                onSelectedDetailed={(index, tab) => setFilter(tab)}
                detailedFilters={detailedFilters}
            />
        </Box>

        <Box mx="2.5rem">
            {loading ? (
                <>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                </>
            ) : (
                    deals.map(deal => (
                        <Deal
                            key={deal.id}
                            id={deal.id}
                            variant={dealsVariantsMapping[deal.state || "in_progress"]}
                            title={dealsTitlesMapping[deal.state || "in_progress"]}
                            description={dealsDescriptionsMapping[deal.state || "in_progress"]}
                            linkTo={`deals/details/${deal.id}`}
                            deal={deal}
                            type="deal"
                        />
                    ))
                )
            }
        </Box>
        <Pagination
            pages={pages}
            currentPage={page}
            setPage={setPage}
        />
    </Fragment>
};

export default NetworkUserDeals;