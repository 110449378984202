import React from "react";
import { Box } from "@chakra-ui/core";
import { Link } from "react-router-dom";

import { MdChevronRight } from "react-icons/md";
import Text from "../../common/Text";
import FeaturedContainer from "../../common/FeaturedContainer";

const CounterOfferStates = (props) => {
  const findType = () => {
    switch (props.type) {
      case "accepted":
        return <Accepted {...props} />;
      case "rejected":
        return <Rejected {...props} />;

      default:
        return null;
    }
  };

  return findType();
};

const Accepted = ({ deal }) => {
  return (
    <FeaturedContainer h="7rem" mt={["1rem", "0.6rem"]} variant="success">
      <Box w="100%" h="100%" d="flex" flexDirection="column">
        <Box pl="1rem" h="70%">
          <Text type="header" mb="0.5rem" mt="1rem">
            Counter-Offer Accepted
          </Text>
          <Text type="secondaryBodyLight" mb="1rem">
            This offer was accepted by both parties. You can view it in Deals.
          </Text>
        </Box>
        <Box bg="gray.400" w="100%" h="30%" d="flex" alignItems="center">
          <Text
            as={Link}
            to={
              deal?.id
                ? `/dashboard/deals/details/${deal.id}`
                : "/dashboard/deals"
            }
            color="purple.500"
            d="flex"
            alignItems="center"
            type="secondaryBodyBold"
            ml="1rem"
          >
            Continue to Deal Details{" "}
            <Box as={MdChevronRight} fontWeight="light" />
          </Text>
        </Box>
      </Box>
    </FeaturedContainer>
  );
};

const Rejected = () => {
  return (
    <FeaturedContainer minH="7rem" mt="0.6rem" variant="error">
      <Box p="1rem">
        <Text type="header" mb="0.5rem" color="error">
          Counter-Offer Declined
        </Text>
        <Text type="secondaryBodyLight" mb="1rem">
          The buyer declined this offer.
        </Text>
      </Box>
    </FeaturedContainer>
  );
};

export default CounterOfferStates;
