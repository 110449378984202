export const offersVariantsMapping = {
  pending: "purple.500",
  rejected: "error",
  accepted: "success",
  withdrawn: "error",
};

export const offersTitlesMapping = {
  rejected: "Offer Declined",
  accepted: "Offer Accepted",
  pending: "Offer Made",
  withdrawn: "Offer Withdrawn",
};

export const offersCounterTitlesMapping = {
  pending: "Counter-Offer Received",
  accepted: "Counter-Offer Accepted",
  rejected: "Counter-Offer Rejected",
  withdrawn: "Offer Withdrawn",
};

export const myOffersLayoutMapping = {
  pending: ["1fr", "3fr 7fr"],
  accepted: ["1fr", "3fr 4fr 3fr"],
  rejected: ["1fr", "3fr 4fr 3fr"],
  withdrawn: ["1fr", "3fr 4fr 3fr"],
};

export const myOffersCounterLayoutMapping = ["1fr", "3fr 2.5fr 4.5fr"];

export const offersLayoutMapping = {
  pending: ["1fr", "3fr 4fr 3fr"],
  accepted: ["1fr", "3fr 4fr 3fr"],
  rejected: ["1fr", "3fr 4fr 3fr"],
  withdrawn: ["1fr", "3fr 4fr 3fr"],
};

export const offersCounterLayoutMapping = ["1fr", "3fr 3fr 4fr"];

export const myOffersTitlesMapping = {
  rejected: "Offer Declined",
  accepted: "Offer Accepted",
  pending: "Offer Received",
  withdrawn: "Offer Withdrawn",
};

export const myOffersCounterTitlesMapping = {
  pending: "Counter-Offer Made",
  accepted: "Counter-Offer Accepted",
  rejected: "Counter-Offer Declined",
  withdrawn: "Offer Withdrawn",
};

export const offersDescriptionsMapping = {
  rejected: "The seller declined this offer",
  accepted:
    "This offer was accepted by both parties. You can view it in Deals.",
  pending:
    "Your offer has been submitted and awaits a response from the seller.",
  withdrawn: "You withdrew this offer.",
};

export const offersCounterDescriptionsMapping = {
  rejected: "The buyer declined this offer",
  accepted:
    "This offer was accepted by both parties. You can view it in Deals.",
  pending: "The seller has made a counter-offer. Please review and respond.",
  withdrawn: "You withdrew this offer.",
};

export const myOffersDescriptionsMapping = {
  rejected: "You declined this offer",
  accepted: "The offer was accepted by both parties",
  pending: "You have received a new offer. Please review and respond.",
  withdrawn: "The buyer withdrew this offer.",
};

export const myOfferCounterDescriptionsMapping = {
  rejected: "The buyer declined this offer",
  accepted: "This offer was accepted by both parties.",
  pending:
    "Your counter-offer has been sent to the buyer and awaits a response.",
  withdrawn: "The buyer withdrew this offer.",
};

export const myOffersTitlesToParams = {
  "Offer Received": "pending",
  "Offer Accepted": "accepted",
  "Offer Declined": "rejected",
  "Offer Withdrawn": "withdrawn",
  "Counter Offer Made": "pending",
  "Counter Offer Accepted": "accepted",
  "Counter Offer Declined": "rejected",
};

export const dealsVariantsMapping = {
  in_progress: "purple.500",
  completed: "success",
  cancelled: "error",
  canceled: "error",
};

export const dealsTitlesMapping = {
  in_progress: "In Progress",
  completed: "Completed",
  cancelled: "Cancelled",
  canceled: "Cancelled",
};

export const dealsDescriptionsMapping = {
  in_progress: "This deal is currently in progress.",
  completed: "This deal was successfully completed.",
  cancelled: "This deal was cancelled.",
  canceled: "This deal was cancelled.",
};

export const dealsTitlesToParams = {
  "In Progress": "in_progress",
  Completed: "completed",
  Cancelled: "canceled",
};
