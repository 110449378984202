import React from "react";
import { Switch, Route } from "react-router-dom";

import Offers from "./Offers";
import OfferDetails from "./OfferDetails";
import VehicleDetails from "../vehicleDetails/VehicleDetails";

const OffersRouter = () => {
  return (
    <Switch>
      <Route
        path="/offers/details/:offerId/batch/:batchId/vehicle/:vehicleId"
        component={VehicleDetails}
      />
      <Route path="/offers/details/:offerId" component={OfferDetails} />
      <Route path="/offers" component={Offers} />
    </Switch>
  );
};

export default OffersRouter;
