import React from "react";
import ErrorPage from "./ErrorPage";

const NotFound = ({ history }) => {
  return (
    <ErrorPage
      title="Page 404 Not Found"
      description="The page you are looking for does not exist"
    />
  );
};

export default NotFound;
