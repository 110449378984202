import { useReducer } from "react";

const initialState = {
  loading: false,
  data: {},
  list: [],
};

function promiseReducer(state, action) {
  switch (action.type) {
    case "pending":
      return { ...state, loading: true };
    case "rejected":
      return { ...state, loading: false, rejected: true };
    case "fulfilled":
      return {
        ...state,
        data: action.payload,
        list: action.payload.objects || [],
        loading: false,
      };
    case "set":
      return { ...state, list: action.payload, loading: false };
    case "set_data":
      return { ...state, data: action.payload, loading: false };
    default:
      throw new Error();
  }
}

function useFetchHook(data) {
  return useReducer(promiseReducer, data || initialState);
}

export default useFetchHook;
