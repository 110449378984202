import React, { useRef, useState } from "react";
import {
  useToast,
  Box,
  IconButton,
  FormControl,
  Image,
  SimpleGrid,
  FormErrorMessage,
} from "@chakra-ui/core";

import Select from "../../common/Select";
import Input from "../../common/Input";
import Button from "../../common/Button";
import Text from "../../common/Text";
import { FaPlus } from "react-icons/fa";

import countries from "../../common/meta/countries.json";
import { imageUpload } from "../../api";

import validate from "validate.js";
const constraints = {
  first_name: {
    presence: { allowEmpty: false },
  },
  last_name: {
    presence: { allowEmpty: false },
  },
  company: {
    presence: { allowEmpty: false },
  },
  job_title: {
    presence: { allowEmpty: false },
  },
  city: {
    presence: { allowEmpty: false },
  },
  country: {
    presence: { allowEmpty: false },
  },
  phone1: {
    presence: { allowEmpty: false },
  },
  phone2: {
    presence: { allowEmpty: true },
  },
  website: {
    presence: { allowEmpty: true },
  },
};

const ProfileInformation = ({
  goToNextStep,
  fields,
  setFields,
  errors,
  setErrors,
  uploadedImageUrl,
  setUploadedImageUrl,
}) => {
  const inputFile = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onUpload = (e) => {
    setIsUploading(true);
    const file = e.target.files[0];
    imageUpload(file)
      .then((res) => {
        setFields({ target: { name: "image_id", value: res.object.id } });
        setUploadedImageUrl(URL.createObjectURL(file));
        setIsUploading(true);
      })
      .catch((err) => {
        setIsUploading(false);
        const description = err.detail
          ? `${err.detail[0].loc[0]} ${err.detail[0].msg}`
          : JSON.stringify(err);
        toast({
          position: "top-right",
          title: "An error occured",
          description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    const validationErrors = validate(fields, constraints);
    if (validationErrors) setErrors(validationErrors);
    else goToNextStep();
  };

  const countriesArray = Object.keys(countries);

  return (
    <form onSubmit={submit}>
      <Text type="header" textAlign="center">
        Complete your SeezOne Profile
      </Text>
      <Box mt={15}>
        <SimpleGrid columns={[1, 1, 2]} spacing={5} mt={5}>
          <FormControl d="flex" flexDirection="column" gridRow="1/3">
            {uploadedImageUrl ? (
              <Image
                cursor="pointer"
                onClick={onButtonClick}
                alt="profile"
                src={uploadedImageUrl}
                objectFit="cover"
                rounded="full"
                size={[80, 105]}
                m={["auto", 0]}
              />
            ) : (
                <IconButton
                  onClick={onButtonClick}
                  id="uploadPhoto"
                  variant="outline"
                  aria-label="Uplaod Photo"
                  fontSize="20px"
                  borderRadius="100%"
                  width={[80, 105]}
                  height={[80, 105]}
                  color="#1E2A38"
                  borderWidth={1}
                  icon={FaPlus}
                  m={["auto", 0]}
                  isLoading={isUploading}
                  opacity="0.3"
                />
              )}
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onUpload}
            />
          </FormControl>
          <FormControl
            isInvalid={errors.first_name && errors.first_name.length > 0}
          >
            <Input
              variant="flushed"
              id="first_name"
              placeholder="First Name"
              name="first_name"
              value={fields.first_name}
              onChange={setFields}
              data-test-id="first_name"
            />
            <FormErrorMessage data-test-id="first_name-error">
              {errors.first_name && errors.first_name[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={errors.last_name && errors.last_name.length > 0}
          >
            <Input
              variant="flushed"
              id="last_name"
              placeholder="Last Name"
              name="last_name"
              value={fields.last_name}
              onChange={setFields}
              data-test-id="last_name"
            />
            <FormErrorMessage data-test-id="last_name-error">
              {errors.last_name && errors.last_name[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.company && errors.company.length > 0}>
            <Input
              variant="flushed"
              id="company"
              placeholder="Company Name"
              name="company"
              value={fields.company}
              onChange={setFields}
              data-test-id="company"
            />
            <FormErrorMessage data-test-id="company-error">
              {errors.company && errors.company[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={errors.job_title && errors.job_title.length > 0}
          >
            <Input
              variant="flushed"
              id="job_title"
              placeholder="Job Title"
              name="job_title"
              value={fields.job_title}
              onChange={setFields}
              data-test-id="job_title"
            />
            <FormErrorMessage data-test-id="job_title-error">
              {errors.job_title && errors.job_title[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.country && errors.country.length > 0}>
            <Select
              id="country"
              variant="flushed"
              placeholder="Country"
              name="country"
              value={fields.country}
              onChange={setFields}
              data-test-id="country"
            >
              {countriesArray.map((country) => (
                <option key={country}>{country}</option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.country && errors.country[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.city && errors.city.length > 0}>
            <Select
              variant="flushed"
              id="city"
              placeholder="City"
              name="city"
              value={fields.city}
              onChange={setFields}
              data-test-id="city"
            >
              {fields.country.length > 0 ? (
                countries[fields.country].map((city) => (
                  <option key={city}>{city}</option>
                ))
              ) : (
                  <option></option>
                )}
            </Select>
            <FormErrorMessage>{errors.city && errors.city[0]}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.phone1 && errors.phone1.length > 0}>
            <Input
              variant="flushed"
              id="phone1"
              placeholder="Telephone 1"
              name="phone1"
              value={fields.phone1}
              onChange={setFields}
              data-test-id="phone1"
            />
            <FormErrorMessage>
              {errors.phone1 && errors.phone1[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.phone2 && errors.phone2.length > 0}>
            <Input
              variant="flushed"
              id="tel2"
              placeholder="Telephone 2"
              name="phone2"
              value={fields.phone2}
              onChange={setFields}
              data-test-id="phone2"
            />
            <FormErrorMessage>
              {errors.phone2 && errors.phone2[0]}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            gridColumn={[1, "1/3"]}
            isInvalid={errors.website && errors.website.length > 0}
          >
            <Input
              variant="flushed"
              id="website"
              placeholder="Website"
              name="website"
              value={fields.website}
              onChange={setFields}
              data-test-id="website"
            />
            <FormErrorMessage>
              {errors.website && errors.website[0]}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Box w="full" textAlign="center" pt="2rem">
          <Button w="8.5rem" type="submit">
            Continue
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ProfileInformation;
