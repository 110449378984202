import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Image, Input } from "@chakra-ui/core";

import Text from "./Text";
import Button from "./Button";
import AddToOfferModal from "./AddToOfferModal";
import TagOutline from "./TagOutline";
import Switch from "@material/react-switch";

import { currencyFormat, mileageFormat } from "./utils";
import { FaTimesCircle } from "react-icons/fa";
import { myDashboard } from "../api";
import { useSelector } from "react-redux";

const CarRow = ({
  noBorder,
  disabled,
  type = "batch",
  linkTo = "batch/vehicle",
  isOwner,
  index,
  addToOffer,
  removeFromOffer,
  offerMade,
  showPrice = true,
  car,
  setCar,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offer, setOffer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const currencies = useSelector(state => state.statics.currencies);
  const symbol = currencies.find(c => c.name === car.currency)?.symbol || "$";

  const onAddToOffer = () => {
    addToOffer(index, offer);
    toggleModal();
  };

  const onRemoveFromOffer = () => {
    setOffer("");
    removeFromOffer(index);
  };

  const handleOfferChange = (e) => setOffer(e.target.value);

  const isDisabled = car?.state !== "visible";

  const toggleCar = (isVisible) => {
    let state = isVisible ? "sold" : "visible";
    setIsLoading(true);
    myDashboard.editVehicle(car.id, { state }).then((res) => {
      setCar(res.object);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Grid
        columnGap="1.5rem"
        templateColumns="2rem 9rem 1.3fr 10fr 2fr 2fr 2fr"
        fontSize={"0.6rem"}
        fontWeight="light"
        color={isDisabled ? "text.300" : "text.400"}
        alignItems="center"
        minW={[500, 0]}
        borderBottomColor="surface"
        borderBottomWidth={noBorder ? 0 : "2px"}
        pb="0.25rem"
        display={["none", "grid"]}
      >
        <Box position="relative">
          <Image
            opacity={isDisabled ? 0.5 : 1}
            w={8}
            maxH="1.5rem"
            objectFit="cover"
            src={
              car?.images[0]?.url ||
              "https://www.allcarleasing.co.uk/images/img-placeholder.jpg"
            }
          ></Image>
          {isDisabled && (
            <Box
              bg="hot"
              color="white"
              borderTopRightRadius="10px"
              borderBottomRightRadius="10px"
              position="absolute"
              fontSize="0.5rem"
              top="0"
              left="0"
              px="5px"
              fontWeight="medium"
            >
              {car.state === "sold" && "SOLD"}
            </Box>
          )}
        </Box>
        <Text
          as={Link}
          to={linkTo}
          fontWeight="medium"
          color={isDisabled ? "text.300" : "text.500"}
        >
          {car.title}
        </Text>
        <Text>{car.year}</Text>
        <Text textAlign="center">
          {mileageFormat(car.mileage)} {car.mileage && "/"} {car.color} {car.color && "/"} {car.transmission} {car.transmission && "/"}{" "}
          {car.regional_specs}
        </Text>
        <Text>{showPrice ? currencyFormat(car.price, symbol) : ""}</Text>
        <Text>#{car.id}</Text>
        {isOwner ? (
          <Switch
            checked={!isDisabled}
            color="purple"
            size="md"
            onChange={(e) => toggleCar(!e.target.checked)}
          />
        ) : type === "offer" ? (
          <TagOutline text={currencyFormat(car.offerMade || offerMade, symbol)} />
        ) : offerMade && offerMade > 0 ? (
          <Box w="7.2rem" d="flex" justifyContent="center">
            <Box
              borderRadius={"1.92rem"}
              min-width={"5rem"}
              height={"1.5rem"}
              borderWidth="1px"
              borderColor="purple.500"
              d="flex"
              alignItems="center"
              justifyContent="center"
              px="0.6rem"
            >
              <Text type="largeBodyBold" color="purple.500" textAlign="center">
                {currencyFormat(offerMade, symbol)}
              </Text>
              <Box
                as={FaTimesCircle}
                color="purple.500"
                ml="0.4rem"
                fontSize="0.7rem"
                cursor="pointer"
                onClick={onRemoveFromOffer}
              />
            </Box>
          </Box>
        ) : (
                <Button
                  disabled={isDisabled}
                  variant="link"
                  fontWeight="regular"
                  onClick={toggleModal}
                >
                  Add to Offer
                </Button>
              )}
      </Grid>
      <Box
        display={["block", "none"]}
        p="1rem"
        borderBottom="3px solid"
        borderBottomColor="surface"
      >
        <Box
          display={["flex", "none"]}
          flexDirection="column"
          justifyContent="space-between"
          minHeight="6rem"
          height="8rem"
        >
          <Box d="flex" justifyContent="space-between" mr="1rem">
            <Box d="flex">
              <Text color="text.500" fontWeight="light">
                #{car.id}
              </Text>
              <Text ml="1rem" color="text.400" fontWeight="light">
                {car.year}
              </Text>
              <Text ml="1rem" color="text.400" fontWeight="light">
                {showPrice ? currencyFormat(car.price, symbol) : ""}
              </Text>
            </Box>
            {isOwner && (
              <Switch
                checked={!isDisabled}
                color="purple"
                size="md"
                onChange={(e) => toggleCar(!e.target.checked)}
              />
            )}
          </Box>
          <Box d="flex" alignItems="center">
            <Image
              w="45px"
              h="35px"
              src={
                car.image ||
                "https://www.allcarleasing.co.uk/images/img-placeholder.jpg"
              }
            ></Image>
            <Text
              ml="1rem"
              fontSize="1.1rem"
              textTransform="uppercase"
              color="text.500"
              as={Link}
              to={linkTo}
            >
              {car.title}
            </Text>
          </Box>
          <Text
            fontSize="1.1rem"
            color="text.400"
            fontWeight="light"
            overflow="hidden"
            lineHeight="1.5rem"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            w="calc(100vw - 9rem)"
          >
            {mileageFormat(car.mileage)}km / {car.color || "N/A"} /{" "}
            {car.transmission} / {car.regional_specs}
          </Text>
        </Box>
        {type === "offer" ? (
          <Box d="flex" justifyContent="center" float="right">
            <TagOutline text={currencyFormat(car.offerMade || offerMade, symbol)} />
          </Box>
        ) : offerMade && offerMade > 0 ? (
          <Box
            w="7.2rem"
            d="flex"
            justifyContent="center"
            float="right"
            mt="1rem"
          >
            <Box
              borderRadius={"1.92rem"}
              min-width={"5rem"}
              height={"1.5rem"}
              borderWidth="1px"
              borderColor="purple.500"
              d="flex"
              alignItems="center"
              justifyContent="center"
              px="0.6rem"
            >
              <Text type="largeBodyBold" color="purple.500" textAlign="center">
                {currencyFormat(offerMade, symbol)}
              </Text>
              <Box
                as={FaTimesCircle}
                color="purple.500"
                ml="0.4rem"
                fontSize="0.7rem"
                cursor="pointer"
                onClick={onRemoveFromOffer}
              />
            </Box>
          </Box>
        ) : (
              <Button
                w="100px"
                float="right"
                disabled={isDisabled}
                variant="link"
                fontWeight="regular"
                onClick={toggleModal}
                boxShadow="none"
              >
                Add to Offer
              </Button>
            )}
      </Box>
      <AddToOfferModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        car={car}
        showPrice={showPrice}
        handleOfferChange={handleOfferChange}
        offer={offer}
        onAddToOffer={onAddToOffer}
      />
    </>
  );
};

export default CarRow;
