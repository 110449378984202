import { combineReducers } from "redux";

import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import marketplaceReducer from "./marketplaceReducer";
import notificationsReducer from "./notificationsReducer";
import staticsReducer from "./staticsReducer";

const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  marketplace: marketplaceReducer,
  notifications: notificationsReducer,
  statics: staticsReducer
});

export default appReducer;
