import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, FormControl, FormErrorMessage } from "@chakra-ui/core";
import { useToast } from "@chakra-ui/core";

import Input from "../../common/Input";
import Header from "../login/Header";
import { FaLock } from "react-icons/fa";

import validate from "validate.js";
import constraints from "../../common/generalConstraints";

import Button from "../../common/Button";
import Checkbox from "../../common/CheckBox";
import Text from "../../common/Text";

import firebase from "../../common/firebase";

const CreateAccount = ({ history }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(true);
  const toast = useToast();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const createFirebaseAccount = () => {
    setIsLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(state.email, state.password)
      .then((response, error) => {
        setIsLoading(false);
        localStorage.setItem("firebaseUser", JSON.stringify(response.user));
        history.push("/complete-profile");
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "An error occured",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const submitPassword = (e) => {
    e.preventDefault();
    const validationErrors = validate(state, constraints);
    if (
      validationErrors &&
      (validationErrors.password || validationErrors.email)
    ) {
      setErrors({ ...errors, ...validationErrors });
    } else {
      setErrors({});
      createFirebaseAccount();
    }
  };

  const toggleTermsAgreed = () => {
    setIsTermsAgreed(!isTermsAgreed);
  };

  return (
    <Box w="full" h="100vh" bg="surface">
      <Box
        className="gradient-background mobile-remove"
        bg={["purple.500", null]}
        h={["15vh", "30vh"]}
        px={["2rem", "5.4rem"]}
        pb={["12rem", 0]}
      >
        <Header />
      </Box>
      <Box
        shadow="lg"
        bg="white"
        w={["calc(100% - 4rem)", "100%", "50%", "25%"]}
        maxWidth={453}
        minWidth={["calc(100% - 4rem)", 453]}
        m="auto"
        rounded="lg"
        mt={["-4rem", "-5rem"]}
      >
        <Box
          rounded="lg"
          h={"4rem"}
          bg="surface"
          d="flex"
          justifyContent="center"
          alignItems="center"
        ></Box>
        <Box py="2.5rem" px={["3rem", "1.9rem"]} pb={["10rem", "2rem"]}>
          <form onSubmit={submitPassword}>
            <Box
              w={[50, 75]}
              h={[50, 75]}
              borderRadius={75}
              bg="purple.500"
              d="flex"
              justifyContent="center"
              alignItems="center"
              m="auto"
              mt="-4rem"
            >
              <Box
                as={FaLock}
                size={["24px", "32px"]}
                color="white"
                bg="purple.500"
              ></Box>
            </Box>{" "}
            <Text type="title" textAlign="center" mt={4}>
              Create Account
            </Text>
            <FormControl
              maxW="300px"
              isInvalid={errors.email && errors.email.length > 0}
              px={4}
              my={4}
              mx="auto"
            >
              <Input
                onChange={handleChange}
                value={state.email}
                name="email"
                mt={6}
                variant="flushed"
                placeholder="Enter email address"
                type="email"
                autoFocus={true}
                h={[, 4]}
              />
              <FormErrorMessage data-test-id="email-error-message">
                {errors.email}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.password && errors.password.length > 0}
              px={4}
              mx="auto"
              maxW="300px"
            >
              <Input
                onChange={handleChange}
                value={state.password}
                name="password"
                mt={6}
                variant="flushed"
                placeholder="Create Password"
                type="password"
              />
              <FormErrorMessage data-test-id="password-error-message">
                {errors.password}
              </FormErrorMessage>
            </FormControl>
            <Box
              m="auto"
              textAlign="center"
              mt={["3rem", "1rem"]}
              mb={["3rem", 0]}
            >
              <Checkbox
                m="auto"
                size="lg"
                variantColor="purple"
                isChecked={isTermsAgreed}
                onChange={toggleTermsAgreed}
              >
                <Text type="bodyLight">
                  I agree to the terms and conditions
                </Text>
              </Checkbox>
            </Box>
            <Box w="full" textAlign="center" mt={[0, "1rem"]}>
              <Button type="submit" isLoading={isLoading} isDisabled={!isTermsAgreed}>
                Create Account
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(CreateAccount);
