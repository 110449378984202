import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ location, component: Component, ...rest }) => {
  const firebaseUser = localStorage.getItem("firebaseUser")
    ? JSON.parse(localStorage.getItem("firebaseUser"))
    : {};
  const token = firebaseUser.stsTokenManager
    ? firebaseUser.stsTokenManager.accessToken
    : "";
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  return (
    <Route
      {...rest}
      render={(props) =>
        token && token.length ? (
          user &&
          !user.fullname &&
          !location.pathname.includes("complete-profile") ? (
            <Redirect to="/complete-profile" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
