import React, { useState, useEffect } from "react";
import { Box, Select } from "@chakra-ui/core";

import { FaCaretDown, FaChevronRight } from "react-icons/fa";
import MobileGeneralFilters from "./MobileGeneralFilters";

const Filters = ({
  hideGeneral,
  generalFilters = [],
  detailedFilters = [],
  onSelectedDetailed = () => {},
  selectedGeneral,
  onSelectedGeneral,
}) => {
  const [selected, setSelected] = useState(selectedGeneral);
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={["column", null, null, "row"]}
    >
      {!hideGeneral && (
        <Box
          bg="white"
          borderRadius="2rem"
          d={["none", "flex"]}
          overflowX="auto"
          maxWidth={["calc(100vw - 5rem)", "100%"]}
          boxShadow={["0px 3px 15px #00000014", 0]}
        >
          {generalFilters.map((filter) => (
            <Box
              key={filter}
              bg={selected === filter ? "purple.500" : "white"}
              cursor="pointer"
              px={4}
              py={[1, 2]}
              borderRadius="2rem"
              color={selected === filter ? "white" : "black"}
              textAlign="center"
              margin="auto"
              fontWeight={[selected === filter ? "500" : "light", "500"]}
              fontSize={["1.1rem", "0.7rem"]}
              whiteSpace="nowrap"
              onClick={() => {
                setSelected(filter);
                onSelectedGeneral && onSelectedGeneral(filter);
              }}
            >
              {filter}
            </Box>
          ))}
        </Box>
      )}

      {!hideGeneral && (
        <MobileGeneralFilters
          generalFilters={generalFilters}
          onSelectedGeneral={onSelectedGeneral}
          selectedGeneral={selectedGeneral}
        />
      )}

      <Box d="flex" alignItems="center" mt={[generalFilters ? "1rem" : 0, 0]}>
        {detailedFilters.map((filter, index) => (
          <Select
            key={filter.name}
            icon={FaCaretDown}
            iconSize={["1rem", "0.7rem"]}
            w="100%"
            variant="unstyled"
            fontSize={["1rem", "0.6rem"]}
            maxW="6rem"
            color="text.500"
            placeholder={filter.name}
            value={filter.selected}
            onChange={(e) => onSelectedDetailed(index, e.target.value)}
          >
            {filter.options.map((option) => (
              <option
                key={option.id || option}
                value={option.value || option.name}
              >
                {option.name || option}
              </option>
            ))}
          </Select>
        ))}
      </Box>
    </Box>
  );
};

export default Filters;
