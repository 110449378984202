import React from "react";

import { Box } from "@chakra-ui/core";

const FeaturedContainer = ({ variant, children, ...boxProps }) => {
  return (
    <Box
      boxShadow="0px 3px 15px #0000000D"
      borderRadius="10px"
      bg="white"
      overflow="hidden"
      d="flex"
      mt={"1rem"}
      flexBasis={["100%", "50%", "50%", "32%"]}
      {...boxProps}
    >
      <Box w={["0.7rem", "0.5rem"]} bg={variant || "white"} />
      {children}
    </Box>
  );
};

export default FeaturedContainer;
