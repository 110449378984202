import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@chakra-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { getBatchOffers } from "../../actions/dashboardActions";

import Text from "../../common/Text";
import Filters from "../../common/Filters";
import Offer from "../../common/OfferDealStatus";
import {
  offersVariantsMapping as variantsMapping,
  myOffersTitlesMapping as titlesMapping,
  myOffersDescriptionsMapping as descriptionsMapping,
  myOffersTitlesToParams as titlesToParams,
} from "../../common/mappings";

const MyBatchOffers = ({ history, match }) => {
  const loading = useSelector((state) => state.dashboard.loading);
  const currentBatch = useSelector((state) => state.dashboard.currentBatch);
  const dispatch = useDispatch();
  const currentState = history.location.pathname.split("/")[5];
  const [detailedFilters, setDetailedFilters] = useState([
    {
      name: "Status",
      options: [
        "Offer Received",
        "Offer Accepted",
        "Offer Declined",
        "Offer Withdrawn",
        "Counter Offer Made",
        "Counter Offer Accepted",
        "Counter Offer Declined",
      ],
      selected: titlesMapping[currentState] || "",
    },
  ]);

  const { batchId } = match.params;
  useEffect(() => {
    dispatch(
      getBatchOffers(batchId, {
        state: currentState,
      })
    );
  }, []);

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    setDetailedFilters(newDetailedFilter);
    dispatch(getBatchOffers(batchId, { state: titlesToParams[value] || "" }));
    history.push(
      `/dashboard/batches/${batchId}/offers/${titlesToParams[value] || ""}`
    );
  };

  return (
    <Box mt="1rem">
      <Box
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        py="1rem"
      >
        <Box w="8rem">
          <Text type="header">Offers</Text>
          <Text type="bodyLight" maxW={["100%", "60%"]}>
            Showing {currentBatch.offers.length} offers
          </Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={selectDetailedFilter}
        />
      </Box>
      {loading ? (
        <>
          <Skeleton isLoaded={!loading}>
            <Box minHeight="7rem"></Box>
          </Skeleton>
          <Skeleton mt="1rem" isLoaded={!loading}>
            <Box minHeight="7rem"></Box>
          </Skeleton>
          <Skeleton mt="1rem" isLoaded={!loading}>
            <Box minHeight="7rem"></Box>
          </Skeleton>
        </>
      ) : (
        currentBatch.offers.map((offer) => (
          <Offer
            key={offer.id}
            id={offer.id}
            variant={variantsMapping[offer.state]}
            title={titlesMapping[offer.state]}
            description={descriptionsMapping[offer.state]}
            offer={offer}
            linkTo={`/dashboard/offer/details/${offer.id}`}
          />
        ))
      )}
    </Box>
  );
};

export default MyBatchOffers;
