import React from "react";
import { Button } from "@chakra-ui/core";

const CustomButton = (props) => {
  return (
    <Button
      variantColor={"purple"}
      borderRadius={"1.92rem"}
      minWidth={"7.2rem"}
      height={["46px", "1.92rem"]}
      width={["181px", "auto"]}
      fontSize={["1rem", "0.7rem"]}
      type="submit"
      boxShadow={["0px 3px 6px #00000029", "none"]}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default CustomButton;
