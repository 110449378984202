import React from "react";
import { Select } from "@chakra-ui/core";

const CustomSelect = (props) => {
  return (
    <Select
      variant="flushed"
      focusBorderColor="purple.500"
      color={!props.value || props.value == "" ? "#384364BF" : "text.500"}
      iconColor={!props.value || props.value == "" ? "#38436480" : "#707070BF"}
      borderColor="text.200"
      borderBottomWidth="1px"
      height={["1.7rem", "1.1rem"]}
      fontSize={["1rem", "0.6rem"]}
      paddingBottom={"0.4rem"}
      paddingX="0.4rem"
      opacity={!props.value || props.value == "" ? "0.75" : "1"}
      {...props}
    >
      {props.children}
    </Select>
  );
};

export default CustomSelect;
