import React, { useEffect, useRef, useState } from 'react';

import { Box, Image, Grid, SimpleGrid, IconButton, FormControl, Input, FormErrorMessage, Select } from "@chakra-ui/core"

import Text from "../../common/Text"
import FeaturedContainer from '../../common/FeaturedContainer';

import { Link } from "react-router-dom"
import { imageUpload } from '../../api';
import { FaPlus } from 'react-icons/fa';

import countries from "../../common/meta/countries.json";
import Button from "../../common/Button";

import { useDispatch, useSelector } from 'react-redux';
import { editUser } from "../../actions/authActions";
import { auth } from '../../api';
import Male from "../../assets/images/Male.png"
import CheckBox from '../../common/CheckBox';

const countriesArray = Object.keys(countries);

const EditBuyerProfile = ({ history, match }) => {
    const { userId } = match.params;

    const [fields, setFields] = useState({
        fullname: "",
        company: "",
        email: "",
        phone1: "",
        phone2: "",
        website: "",
        global_access: false,
        image: {},
    });
    const dispatch = useDispatch();

    const getUser = () => {
        auth.getUser(userId).then(res => {
            setFields({
                fullname: res.object?.fullname,
                company: res.object?.company,
                email: res.object?.email,
                phone1: res.object?.phone1,
                phone2: res.object?.phone2,
                website: res.object?.website,
                state: res.object?.state,
                global_access: res.object?.global_access,
                image: res.object?.image,
            })
        })
    }

    useEffect(getUser, []);

    const goBack = () => {
        history.push(`/user/${userId}/offers`);
    }

    const onSave = () => {
        auth.editUser(userId, fields).then(res => {
            goBack();
        })
    }

    return (
        <Box w="100%" h="100%">
            <Box
                w="100%"
                h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
                className="gradient-header"
                px={["1rem", "2.5rem", "3rem"]}
                py="1.5rem"
            >
                <Text type="header" color="white">
                    Edit Profile
                </Text>
                <Text type="secondaryBodyRegular" color="white" as={Link} to={"/profile/offers"} w="fit-content">
                    {
                        "‹ Back to My Profile"
                    }
                </Text>
            </Box>
            <FeaturedContainer d="flex" mt={"-3.5rem"} width="fit-content" p={["3rem", "3rem 5rem"]} ml="auto" mr="auto" alignItems="center" d="flex" flexDirection="column">
                <SimpleGrid columns={[1, 1, 2]} spacing={5} mt={5}>
                    <FormControl isDisabled={true} d="flex" flexDirection="column" gridRow="1/3">
                        <Image
                            cursor="pointer"
                            alt="profile"
                            src={fields.image?.url || Male}
                            objectFit="cover"
                            rounded="full"
                            size={[80, 105]}
                            m={["auto", 0]}
                        />
                    </FormControl>
                    <FormControl isDisabled={true}>
                        <Input
                            variant="flushed"
                            id="fullname"
                            placeholder="Full Name"
                            name="fullname"
                            value={fields.fullname}
                            data-test-id="fullname"
                        />
                    </FormControl>
                    <FormControl isDisabled={true} >
                        <Input
                            variant="flushed"
                            id="company"
                            placeholder="Company Name"
                            name="company"
                            value={fields.company}
                        />
                    </FormControl>
                    <FormControl isDisabled={true} >
                        <Input
                            variant="flushed"
                            id="email"
                            placeholder="Email"
                            name="email"
                            value={fields.email}
                        />
                    </FormControl>
                    <FormControl isDisabled={true}>
                        <Input
                            variant="flushed"
                            id="phone1"
                            placeholder="Telephone 1"
                            name="phone1"
                            value={fields.phone1}
                        />
                    </FormControl>
                    <FormControl isDisabled={true} >
                        <Input
                            variant="flushed"
                            id="tel2"
                            placeholder="Telephone 2"
                            name="phone2"
                            value={fields.phone2}
                        />
                    </FormControl>
                    <FormControl isDisabled={true}>
                        <Input
                            variant="flushed"
                            id="website"
                            placeholder="Website"
                            name="website"
                            value={fields.website}
                        />
                    </FormControl>
                </SimpleGrid>
                <Box mt="2rem" w="100%">
                    <Text type="secondaryBodyBold">{"Access & Privileges"}</Text>
                    <Text type="bodyLight">{"SeezOne Global Market Access"}</Text>
                    <CheckBox wrapperStyle={{ marginTop: "0.5rem" }} isChecked={fields.global_access} onChange={e => setFields({ ...fields, global_access: e.target.checked })}>
                        <Text type="bodyMedium">{"Grant Global Access"}</Text>
                    </CheckBox>
                </Box>
                <Box mt="2rem" w="min-content" d={["block", "flex"]}>
                    <Button onClick={onSave}>Save Changes</Button>
                    <Button ml={[0, "1rem"]} mt={["1rem", 0]} variantColor="gray" backgroundColor="gray.100" color="text.500" onClick={goBack}>Cancel</Button>
                </Box>
            </FeaturedContainer>
        </Box >
    );
};

export default EditBuyerProfile;