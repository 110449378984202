import React from "react";
import { Box, Grid } from "@chakra-ui/core";

import FeaturedContainer from "../../common/FeaturedContainer";
import batchTypes from "../../common/meta/batchTypes";
import TagCountDown from "../../common/TagCountDown";
import TagOutline from "../../common/TagOutline";
import Text from "../../common/Text";

const MyBatch = ({ batch, navigateToBatch }) => {
  return (
    <>
      <FeaturedContainer
        cursor="pointer"
        overflow="hidden"
        onClick={() => navigateToBatch(batch)}
        d={["none", "flex"]}
      >
        <Grid
          templateColumns="2fr 2fr 3fr"
          columnGap={["1rem", "4rem"]}
          p="1rem"
          minW="600px"
          w="100%"
        >
          <Text my="auto" type="largeBodyBold">
            {batch.title}
          </Text>
          <Text my="auto" type="secondaryBodyLight">
            Batch #{batch.id} / {batchTypes[batch.type]} / {batch.num_vehicles}{" "}
            Vehicle{batch.num_vehicles != 1 && "s"}
          </Text>
          <Grid
            templateColumns={["1fr 1fr 5fr", "1fr 1fr 3fr"]}
            alignItems="center"
          >
            {batch.num_offers ? (
              <TagOutline
                bg="transparent"
                borderWidth="1px"
                borderColor="text.200"
                color="text.200"
                fontSize="0.6rem"
                text={`${batch.num_offers} Offers`}
              />
            ) : (
              <Box></Box>
            )}
            {batch.num_deals ? (
              <TagOutline
                bg="transparent"
                borderWidth="1px"
                borderColor="urgent"
                color="urgent"
                fontSize="0.6rem"
                ml="0.5rem"
                text={`${batch.num_deals} Deals`}
              />
            ) : (
              <Box></Box>
            )}

            <TagCountDown
              m="auto"
              mr="0"
              fromTime={new Date(batch.expiry_date)}
            />
          </Grid>
        </Grid>
      </FeaturedContainer>
      <FeaturedContainer
        cursor="pointer"
        overflow="scroll"
        onClick={() => navigateToBatch(batch)}
        d={["block", "none"]}
      >
        <Box p="2rem">
          <Text fontSize="1.5rem" fontWeight="bold">
            {batch.title}
          </Text>
          <Text my="auto" fontSize="1rem" fontWeight="light">
            Batch #{batch.id} / {batchTypes[batch.type]} / {batch.num_vehicles}{" "}
            Vehicle{batch.num_vehicles != 1 && "s"}
          </Text>
          <Box d="flex" mt="1rem">
            {batch.num_offers ? (
              <TagOutline
                bg="transparent"
                borderWidth="1px"
                borderColor="text.200"
                color="text.200"
                fontSize="1rem"
                text={`${batch.num_offers} Offers`}
              />
            ) : (
              <Box></Box>
            )}
            {batch.num_deals ? (
              <TagOutline
                bg="transparent"
                borderWidth="1px"
                borderColor="urgent"
                color="urgent"
                fontSize="1rem"
                ml={batch.num_offers ? "0.5rem" : 0}
                text={`${batch.num_deals} Deals`}
              />
            ) : (
              <Box></Box>
            )}
          </Box>
          <TagCountDown mt="1rem" fromTime={new Date(batch.expiry_date)} />
        </Box>
      </FeaturedContainer>
    </>
  );
};

export default MyBatch;
