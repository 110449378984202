import {
  SET_USER,
  REGISTER_USER,
  SET_TOKEN,
  GET_SET_TOKEN,
  EDIT_CURRENT_USER
} from "../constants";
import { auth as authApi } from "../api";
import firebase from "../common/firebase";
import { pending, rejected, fulfilled } from "./actionCreators";

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const getAndSetToken = (forceRefresh = true) => ({
  type: GET_SET_TOKEN,
  payload: firebase.auth().currentUser.getIdToken(forceRefresh),
});

// export const editUser = (data) => ({
//   type: EDIT_CURRENT_USER,
//   payload: authApi.editLoggedInUser(data)
// })

export const editUser = (data, cb) => (dispatch) => {
  dispatch(pending(EDIT_CURRENT_USER));
  authApi
    .editLoggedInUser(data)
    .then((response) => {
      if (response) {
        dispatch(
          fulfilled(
            EDIT_CURRENT_USER,
            response
          )
        );
        if (cb) cb();
      }
    })
};

export const registerUser = (data, cb, errorCb) => (dispatch) => {
  dispatch(pending(REGISTER_USER));
  authApi
    .register(data)
    .then((response) => {
      if (response) {
        dispatch(
          fulfilled(
            REGISTER_USER,
            typeof response.object === "object" ? {} : response
          )
        );
        if (cb) cb();
      }
    })
    .catch((err) => {
      errorCb(err);
      dispatch(rejected(REGISTER_USER, err));
    });
};

export const getLoggedInUser = (cb) => (dispatch) => {
  const user = localStorage.getItem("user");
  if (user) {
    dispatch({ type: SET_USER, payload: JSON.parse(user) });
  } else {
    authApi.getLoggedInUser().then((res) => {
      if (res && res.object) {
        dispatch({ type: SET_USER, payload: res.object });
        localStorage.setItem("user", JSON.stringify(res.object));
        if (cb) cb();
      }
    });
  }
};
