import React from "react";
import { Input } from "@chakra-ui/core";

const CustomInput = (props) => {
  return (
    <Input
      variant="flushed"
      focusBorderColor="purple.500"
      _placeholder={{ color: "text.500", opacity: 0.75, fontWeight: "light" }}
      borderBottomColor="text.200"
      borderBottomWidth="1px"
      height={["1.5rem", "1.1rem"]}
      fontSize={["1rem", "0.6rem"]}
      paddingBottom={["0.6rem", "0.4rem"]}
      paddingX="0.4rem"
      opacity="0.75"
      my={["0.5rem", 0]}
      {...props}
    />
  );
};

export default CustomInput;
