import React, { useEffect } from "react";
import { Box, Skeleton } from "@chakra-ui/core";

import MyBatch from "./MyBatch";
import Text from "../../common/Text";
import FeaturedContainer from "../../common/FeaturedContainer";
import Filters from "../../common/Filters";

import { useSelector, useDispatch } from "react-redux";
import { getBatches, setBatch } from "../../actions/dashboardActions";
import { useHistory } from "react-router-dom";

const Batches = ({ }) => {
  const batches = useSelector((state) => state.dashboard.batches);
  const searchKeyword = useSelector((state) => state.dashboard.searchKeyword);
  const batchesCount = useSelector((state) => state.dashboard.searchResultsCount.batches);
  useEffect(() => {
    if (searchKeyword) dispatch(getBatches({ q: searchKeyword }));
  }, [searchKeyword])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBatches({ q: searchKeyword }));
  }, []);
  const history = useHistory();

  const loading = useSelector((state) => state.dashboard.loading);

  const navigateToBatch = (batch) => {
    dispatch(setBatch({ object: batch }));
    history.push(`/dashboard/batches/${batch.id}`);
  };

  const onFilterChange = (index, filter) => {
    dispatch(getBatches({ state: filter.toLowerCase(), q: searchKeyword }));
  };

  const detailedFilters = [
    {
      name: "Status",
      options: ["Active", "Unposted", "Expired"],
    },
  ];

  return (
    <Box mt="1.5rem">
      <Box d="flex" justifyContent="space-between" alignItems="center">
        <Box w="70%">
          <Text type="header">Batches</Text>
          <Text type="bodyLight">{searchKeyword ? `${batchesCount} result${batchesCount > 1 ? "s" : ""} that match your search in Batches` : "All your batches"}</Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={onFilterChange}
        />
      </Box>
      <Box>
        {!loading && batches && batches.length === 0 && (
          <FeaturedContainer
            height="100px"
            d="flex"
            alignItems="center"
            justifyConent="center"
            pl="1rem"
          >
            <Text type="secondaryBodyLight">
              No batches available{searchKeyword ? "" : `, create your first batch by selecting the
              "Create Batch" option above`}
            </Text>
          </FeaturedContainer>
        )}
        {batches &&
          batches.map((batch) => {
            return (
              <MyBatch
                key={batch.id}
                batch={batch}
                navigateToBatch={navigateToBatch}
              />
            );
          })}
        {loading &&
          [1, 2, 3, 4].map((key) => (
            <Skeleton
              key={key}
              borderRadius="10px"
              mt={"1rem"}
              height={"3rem"}
              isLoaded={false}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Batches;
