import React from "react";

import { Box, Text } from "@chakra-ui/core";

const IconText = ({ Icon, children }) => {
  return (
    <Box d="flex" justifyContent="flex-start" alignItems="center" mt={3}>
      <Box as={Icon} size="1.7rem" mr={"0.8rem"}></Box>
      <Text>{children}</Text>
    </Box>
  );
};

export default IconText;
