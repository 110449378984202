import React from "react";

import {
  Box,
  Text,
  PseudoBox,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Spinner
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { FaCar, FaBuffer, FaHandshake, FaCog } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";

const SidebarItem = ({ Icon, text, selected, path, count, ...other }) => {
  console.log(typeof count);
  return (
    <PseudoBox cursor="pointer">
      <Box
        as={NavLink}
        to={path}
        mt={"2rem"}
        h={"1rem"}
        d="flex"
        justifyContent="flex-start"
        alignItems="center"
        color={"text.500"}
        {...other}
      >
        <Box
          as={Icon}
          size={selected ? ["1.3rem", "1rem"] : ["1.2rem", "0.9rem"]}
          width={["1rem"]}
        />
        <Text
          fontWeight={selected ? "bold" : "normal"}
          fontSize={
            selected
              ? ["1.3rem", "1rem", "1rem", "0.7rem"]
              : ["1.2rem", "1rem", "1rem", "0.6rem"]
          }
          marginLeft="1rem"
          width={["11rem", "6rem"]}
        >
          {text}
          {
            typeof count !== "undefined" && (count ? ` (${count})` : <Spinner size="xs" />)
          }
        </Text>
      </Box>
    </PseudoBox>
  );
};

const Sidebar = ({ openSidebar, closeSidebar, isSidebarOpen, offersCount, dealsCount }) => {
  const selectedTab = window.location.pathname.split("/")[1];
  return (
    <>
      <Box
        h="calc(100vh - 4.2rem)"
        bg="white"
        boxShadow="0px 3px 15px #0000000D"
        overflow="hidden"
        flexDirection="column"
        justifyContent="start"
        alignItems="center"
        display={["none", "none", "none", "flex"]}
        position="relative"
      >
        <SidebarItem
          path="/marketplace"
          selected={selectedTab === "marketplace"}
          Icon={FaCar}
          text="Marketplace"
        />
        <SidebarItem
          selected={selectedTab === "offers"}
          path="/offers"
          Icon={FaBuffer}
          text={`My Offers`}
          count={offersCount}
        />
        <SidebarItem
          selected={selectedTab === "deals"}
          path="/deals"
          Icon={FaHandshake}
          text={`My Deals`}
          count={dealsCount}
        />
        <Text
          as={Link}
          to="/"
          textAlign="left"
          mt="55vh"
          fontSize="0.65rem"
          w="60%"
          color="text.500"
          opacity="0.5"
        >
          How it works
        </Text>
        <Text
          as={Link}
          to="/terms"
          textAlign="left"
          fontSize="0.65rem"
          w="60%"
          color="text.500"
          opacity="0.5"
        >
          Terms & Conditions
        </Text>
      </Box>
      <Drawer
        isOpen={isSidebarOpen}
        placement="left"
        size="xs"
        onClose={closeSidebar}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Logo />
          </DrawerHeader>

          <DrawerBody
            d="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="center"
          >
            <SidebarItem
              selected={selectedTab === "marketplace"}
              path="/marketplace"
              Icon={FaCar}
              text="Marketplace"
            />
            <SidebarItem
              selected={selectedTab === "offers"}
              path="/offers"
              Icon={FaBuffer}
              text="My Offers (3)"
            />
            <SidebarItem
              selected={selectedTab === "delas"}
              path="/deals"
              Icon={FaHandshake}
              text="My Deals (2)"
            />

            <SidebarItem
              selected={selectedTab === "how"}
              path="/how"
              Icon={FaCog}
              text="How it works?"
              mt={"60vh"}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Sidebar;
