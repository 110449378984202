import React, { useEffect, useState, Fragment } from "react";
import { Box, Grid, Image, Skeleton } from "@chakra-ui/core";

import Text from "../../common/Text";
import FeaturedContainer from "../../common/FeaturedContainer";

import { useDispatch, useSelector } from "react-redux";
import { getNetworkUsers } from "../../actions/dashboardActions";
import Filters from "../../common/Filters";

import Male from "../../assets/images/Male.png";

const NetworkMember = ({ user, navigateToUser }) => {
  const variant =
    user.state === "active"
      ? "accepted"
      : user.state === "inactive"
        ? "declined"
        : "pending";

  return (
    <FeaturedContainer
      variant={variant}
      d="flex"
      cursor="pointer"
      onClick={() => navigateToUser(user.id)}
    >
      <Image
        h="2rem"
        w="2rem"
        objectFit="cover"
        rounded="full"
        src={user?.image?.url || Male}
        mx="0.6rem"
        my="0.6rem"
      />
      <Box my="0.6rem">
        <Box d="flex" alignItems="center">
          <Text type="secondaryBodyBold">{user.fullname}</Text>
          <Text ml="0.5rem" type="largeBodyBold" color={variant}>
            {user.state[0].toUpperCase() + user.state.slice(1)}
          </Text>
        </Box>
        <Box>
          <Text type="secondaryBodyLight">{user.company}</Text>
          <Text type="secondaryBodyLight">{user.location}</Text>
        </Box>
      </Box>
    </FeaturedContainer>
  );
};

const detailedFilters = [
  {
    name: "Status",
    options: ["Active", "Inactive", "Pending"],
  },
];

const Network = ({ history }) => {
  const dispatch = useDispatch();
  const networkUsers = useSelector((state) => state.dashboard.networkUsers);
  const searchKeyword = useSelector((state) => state.dashboard.searchKeyword);
  const usersCount = useSelector((state) => state.dashboard.searchResultsCount.users);
  useEffect(() => {
    if (searchKeyword) dispatch(getNetworkUsers({ q: searchKeyword }));
  }, [searchKeyword])
  useEffect(() => {
    if (!networkUsers || !networkUsers.length) dispatch(getNetworkUsers());
  }, []);
  const isLoading = useSelector((state) => state.dashboard.loading);

  const onFilterChange = (index, filter) => {
    dispatch(getNetworkUsers({ state: filter.toLowerCase(), q: searchKeyword }));
  };

  const navigateToUser = (userId) => {
    history.push(`/user/${userId}/offers`);
  };

  return (
    <Box mt="1.5rem">
      <Box d="flex" justifyContent="space-between" alignItems="center">
        <Box w="70%">
          <Text type="header">Network</Text>
          <Text type="bodyLight">
            {
              searchKeyword ? `${usersCount} results that match your search in Users` : "Users in your network who can make offers on selections from your batches"
            }
          </Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={onFilterChange}
        />
      </Box>
      <Grid templateColumns={["1fr", "1fr 1fr 1fr"]} columnGap="0.6rem">
        {isLoading && (
          <Fragment>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
          </Fragment>
        )}
        {networkUsers?.length > 0 &&
          networkUsers.map((user, index) => (
            <NetworkMember
              key={index}
              user={user}
              navigateToUser={navigateToUser}
            />
          ))}
        {networkUsers && networkUsers.length === 0 && !isLoading && (
          <FeaturedContainer padding="1rem">
            <Text type="secondaryBodyRegular">
              There are no users in your network
            </Text>
          </FeaturedContainer>
        )}
      </Grid>
    </Box>
  );
};

export default Network;
