// AUTH
export const SET_USER = "SET_USER";
export const REGISTER_USER = "REGISTER_USER";
export const SET_TOKEN = "SET_TOKEN";
export const GET_SET_TOKEN = "GET_SET_TOKEN";
export const EDIT_CURRENT_USER = "EDIT_CURRENT_USER";

// MY DASHBOARD
export const GET_BATCHES = "GET_BATCHES";
export const CREATE_BATCH = "CREATE_BATCH";
export const GET_BATCH = "GET_BATCH";
export const SET_BATCH = "SET_BATCH";
export const ADD_VEHICLE = "ADD_VEHICLE";
export const GET_BATCH_VEHICLES = "GET_BATCH_VEHICLES";
export const GET_BATCH_OFFERS = "GET_BATCH_OFFERS";
export const GET_BATCH_DEALS = "GET_BATCH_DEALS";
export const GET_DASHBOARD_OFFERS = "GET_DASHBOARD_OFFERS";
export const GET_DASHBOARD_DEALS = "GET_DEALS_OFFERS";
export const GET_BATCH_VEHICLE = "GET_BATCH_VEHICLE";
export const SET_BATCH_VEHICLE = "SET_BATCH_VEHICLE";
export const GET_NETWORK_USERS = "GET_NETWORK_USERS";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const EDIT_BATCH = "EDIT_BATCH";
export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";

// MARKETPLACE
export const GET_FEATURED_BATCHES = "GET_FEATURED_BATCHES";
export const GET_MARKETPLACE_BATCHES = "GET_MARKETPLACE_BATCHES";
export const GET_MARKETPLACE_OFFERS = "GET_MARKETPLACE_OFFERS";
export const GET_MARKETPLACE_DEALS = "GET_MARKETPLACE_DEALS";
export const SET_CURRENT_OFFER = "SET_CURRENT_OFFER";

//NOTIFICATIONS
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

// STATICS
export const GET_CURRENCIES = "GET_CURRENCIES"