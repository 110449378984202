import typeToReducer from "type-to-reducer";
import { GET_NOTIFICATIONS } from "../constants";

const initialState = {
  objects: [],
  loading: false,
  error: {},
  isRejected: false,
};
const reducer = typeToReducer(
  {
    [GET_NOTIFICATIONS]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: true,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        isRejected: false,
      }),
    },
  },
  initialState
);

export default reducer;
