import React, { useState, useEffect } from 'react';

import { Box, Image, Grid } from "@chakra-ui/core"

import Text from "../../common/Text"
import FeaturedContainer from '../../common/FeaturedContainer';

import { Link, Switch, Route } from "react-router-dom"
import Filters from '../../common/Filters';
import Button from '../../common/Button';

import { useSelector } from 'react-redux';

import Male from "../../assets/images/Male.png";

import { auth } from "../../api";
import UserOffers from './UserOffers';
import UserDeals from './UserDeals';
import NetworkUserOffers from './NetworkUserOffers';
import NetworkUserDeals from './NetworkUserDeals';

const generalFilters = [
    "Offers",
    "Deals",
];

const filtersMap = generalFilters.map(filter => ({ [filter]: filter.toLowerCase() }))

const UserPofile = ({ history, match }) => {
    const [user, setUser] = useState(null);
    const loggedInUser = useSelector(state => state.auth.user)
    useEffect(() => {
        if (match?.params?.userId) {
            auth.getUser(match.params.userId).then(res => {
                setUser(res.object)
            })
        } else {
            setUser(loggedInUser)
        }
    }, [])

    const isNetworkUser = user?.id && user?.id != loggedInUser?.id

    const selectedFilter = filtersMap[history.location.pathname.split("/").pop().toLowerCase()];

    const navigateToFilter = (selectedTab) => {

        const rootPath = isNetworkUser ? `/user/${user.id}` : "/profile"

        switch (selectedTab) {
            case "Offers":
                history.push(`${rootPath}/offers`);
                break;
            case "Deals":
                history.push(`${rootPath}/deals`);
                break;

            default:
                break;
        }
    };

    const navigateToEdit = () => {
        history.push(isNetworkUser ? `/profile/edit/${user?.id}` : "/profile/edit");
    }

    const variant =
        user?.state === "active"
            ? "accepted"
            : user?.state === "inactive"
                ? "declined"
                : "pending";

    return (
        <Box w="100%" h="100%">
            <Box
                w="100%"
                h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
                className="gradient-header"
                px={["1rem", "2.5rem", "3rem"]}
                py="1.5rem"
            >
                <Text type="header" color="white">
                    User Profile
                </Text>
                <Text type="secondaryBodyRegular" color="white" as={Link} to={isNetworkUser ? "/dashboard/network" : "/marketplace"} w="fit-content">
                    {isNetworkUser ? "‹ Back to My Network" : "‹ Back to Marketplace"}
                </Text>
            </Box>
            <FeaturedContainer d="flex" mt={"-3.5rem"} mx={["1rem", "2.5rem"]} alignItems="center">
                <Image my="1.8rem" mx="1.3rem" objectFit="cover" rounded="full" size="5.3rem" src={user?.image?.url || Male} />
                <Box d="flex" flexDir="column" w="100%" h="5.3rem" position="relative">
                    <Text type="header">{user?.fullname}</Text>
                    <Grid templateColumns={["1fr 1fr 1fr 1fr"]} w="100%" h="100%" pt="0.5rem">
                        <Box d="flex" flexDir="column" justifyContent="space-between" h="100%">
                            <Box>
                                <Text type="secondaryBodyRegular" fontWeight="normal">{user?.job_title}</Text>
                                <Text type="secondaryBodyRegular" maxW="8rem" fontWeight="normal" maxW="100%">{user?.company}</Text>
                            </Box>
                            <Box d="flex" alignItems="center">
                                <Text type="largeBodyRegular" color={variant}>{user?.state[0].toUpperCase() + user?.state.slice(1)}</Text>
                                <Text type="bodyLight" ml="0.5rem">Joined 15 Apr 2020</Text>
                            </Box>
                        </Box>
                        <Box d="flex" flexDir="column">
                            <Text type="secondaryBodyRegular" mb="0.5rem">Contact Info</Text>
                            <Text type="bodyLight">{user?.phone1}</Text>
                            <Text type="bodyLight">{user?.phone2}</Text>
                            <Text type="bodyLight">{user?.email}</Text>
                        </Box>
                        <Box d="flex" flexDir="column">
                            <Text type="secondaryBodyRegular" mb="0.5rem">Address</Text>
                            <Text type="bodyLight">Unit 117, Halib Business Park</Text>
                            <Text type="bodyLight">320 Batteekh Road </Text>
                            <Text type="bodyLight">{user?.location}</Text>
                        </Box>
                        <Box d="flex" alignItems="flex-end" justifyContent="center">
                            <Box d="inline-flex">
                                <Text type="secondaryBodyRegular" mb="0.5rem">SeezOne Global market access: </Text>

                                <Text type="secondaryBodyRegular" color={user?.global_access ? "success" : "error"} ml="0.5rem">{user?.global_access ? "Yes" : "No"}</Text>
                            </Box>
                        </Box>
                    </Grid>
                    <Button onClick={navigateToEdit} color="purple.500" minWidth="0" variant="flushed" position="absolute" right="0" top="0">Edit Profile</Button>
                </Box>
            </FeaturedContainer>
            <Box mt="2rem">
                <Box mx={["1rem", "2.5rem"]}>
                    <Filters
                        generalFilters={generalFilters}
                        selectedGeneral={selectedFilter || "Offers"}
                        onSelectedGeneral={navigateToFilter}
                    />
                </Box>
                {
                    isNetworkUser ? (
                        <Switch>
                            <Route path="/user/:userId/offers" component={NetworkUserOffers} />
                            <Route path="/user/:userId/deals" component={NetworkUserDeals} />
                        </Switch>
                    ) : (
                            <Switch>
                                <Route path="/profile/offers" component={UserOffers} />
                                <Route path="/profile/deals" component={UserDeals} />
                            </Switch>
                        )
                }
            </Box>
        </Box >
    );
};

export default UserPofile;