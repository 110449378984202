import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  IconButton,
  Textarea,
  Image,
  Skeleton,
} from "@chakra-ui/core";

import Text from "../../common/Text";
import Input from "../../common/Input";
import Select from "../../common/Select";
import Button from "../../common/Button";
import { FaPlus, FaTimesCircle } from "react-icons/fa";

import makeData from "../../common/meta/static.json";

import Checkbox from "../../common/CheckBox";

import { useDispatch, useSelector } from "react-redux";
import {
  setBatch,
  getBatch,
  getVehicle,
  setVehicle as setVehicleRedux,
} from "../../actions/dashboardActions";
import { myDashboard, imageUpload, attachmentUpload } from "../../api/";

const years = [
  2020,
  2019,
  2018,
  2017,
  2016,
  2015,
  2014,
  2013,
  2012,
  2011,
  2010,
];

const AddVehicle = ({ history, match }) => {
  const currentBatch = useSelector((state) => state.dashboard.currentBatch);
  const currentVehicle = useSelector((state) => state.dashboard.currentVehicle);
  const loading = useSelector((state) => state.dashboard.loading);
  const currencies = useSelector(state => state.statics.currencies);
  const dollar = currencies.find(c => c.name === "USD");

  const dispatch = useDispatch();

  const { batchId, vehicleId } = match.params;

  const [vehicle, setVehicle] = useState({
    images: [],
    files: [],
  });
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (
      batchId &&
      (!currentBatch?.object?.id || currentBatch.object.id != batchId)
    ) {
      dispatch(getBatch(batchId));
    }
  }, [currentBatch, batchId]);

  useEffect(() => {
    if (!vehicleId) return;
    if (currentVehicle) {
      setVehicle({ ...vehicle, ...currentVehicle, currency: dollar?.name });
      setImages(currentVehicle.images.map((image) => ({ object: image })));
      setFiles(currentVehicle.attachments.map((file) => ({ object: file })));
    } else {
      dispatch(getVehicle(batchId, vehicleId));
    }
  }, [currentVehicle, vehicleId]);

  const inputImage = useRef(null);
  const onImageUploadClick = () => {
    inputImage.current.click();
  };

  const inputAttachment = useRef(null);
  const onAttachmentUploadClick = () => {
    inputAttachment.current.click();
  };

  const uploadMultiple = () => {
    history.push(`/dashboard/batches/${batchId}/multiple-vehicle-upload`);
  };

  const onImageUpload = (e) => {
    let filesArray = Array.from(e.target.files);

    const newFiles = filesArray.map(() => ({ uploading: true }));

    setImages([...images, ...newFiles]);

    const uploadedFiles = [];
    const totalFiles = newFiles.length;
    let totalUploadedFiles = 0;

    const onAllFilesUploaded = () => {
      setImages([
        ...images,
        ...uploadedFiles.map((file) => ({ object: file })),
      ]);
    };

    filesArray.forEach((file) => {
      imageUpload(file)
        .then((res) => {
          totalUploadedFiles++;
          if (!res) return;
          uploadedFiles.push(res.object);
          if (totalUploadedFiles === totalFiles) onAllFilesUploaded();
        })
        .catch((err) => {
          totalUploadedFiles++;
        });
    });
  };

  const onAttachmentUpload = (e) => {
    let filesArray = Array.from(e.target.files);

    const newFiles = filesArray.map(() => ({ uploading: true }));

    setFiles([...files, ...newFiles]);

    const uploadedFiles = [];
    const totalFiles = newFiles.length;
    let totalUploadedFiles = 0;

    const onAllFilesUploaded = () => {
      setFiles([...files, ...uploadedFiles.map((file) => ({ object: file }))]);
    };

    filesArray.forEach((file) => {
      attachmentUpload(file).then((res) => {
        totalUploadedFiles++;
        if (!res) return;
        uploadedFiles.push(res.object);
        if (totalUploadedFiles === totalFiles) onAllFilesUploaded();
      });
    });
  };

  const handleChange = (key, value) => {
    setVehicle({ ...vehicle, [key]: value });
  };

  const isEditing = vehicleId;

  const handleSubmit = () => {
    let data = {
      make: vehicle.make,
      model: vehicle.model,
      submodel: vehicle.submodel,
      year: Number(vehicle.year),
      mileage: Number(vehicle.mileage),
      color: vehicle.color,
      transmission: vehicle.transmission,
      regional_specs: vehicle.regional_specs,
      description: vehicle.description,
      price: Number(vehicle.price),
      num_doors: Number(vehicle.num_doors),
      num_cylinders: Number(vehicle.num_cylinders),
      fuel_type: vehicle.fuel_type,
      engine_displacement: Number(vehicle.engine_displacement),
      warranty: vehicle.warranty,
      service_history: vehicle.service_history,
      image_ids: images.map((image) => image.object.id),
      attachment_ids: files.map((file) => file.object.id),
      currency: vehicle.currency
    };

    if (isEditing) {
      myDashboard.editVehicle(vehicleId, data).then((res) => {
        if (res && res.success) {
          dispatch(
            setBatch({
              ...currentBatch,
              vehicles: [
                res.object,
                ...currentBatch.vehicles.filter(
                  (vehicle) => vehicle.id != vehicleId
                ),
              ],
            })
          );
          dispatch(setVehicleRedux(res.object));
          history.push(`/dashboard/batches/${batchId}/vehicles/${vehicleId}`);
        }
      });
    } else {
      myDashboard.addVehicle(batchId, data).then((res) => {
        if (res && res.success) {
          dispatch(
            setBatch({
              ...currentBatch,
              object: {
                ...currentBatch.object,
                num_vehicles: currentBatch.object.num_vehicles + 1,
              },
              vehicles: [res.object, ...currentBatch.vehicles],
            })
          );
          history.push(`/dashboard/batches/${batchId}`);
        }
      });
    }
  };

  const onRemoveImage = (index) => {
    let _images = [...images];
    _images.splice(index, 1);
    setImages(_images);
  };

  const onRemoveAttachment = (index) => {
    let _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);
  };

  const selectedMake = vehicle.make
    ? makeData.makes.find((make) => make.name === vehicle.make)
    : null;
  const selectedModel = selectedMake
    ? selectedMake.models.find((model) => model.name === vehicle.model)
    : null;

  const goBack = () => {
    history.push(
      isEditing
        ? `/dashboard/batches/${batchId}/vehicles/${vehicleId}`
        : `/dashboard/batches/${batchId}/vehicles`
    );
  };

  return (
    <Box w="100%" h="100%">
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text type="header" color="white">
          {isEditing ? "Edit" : "Add"} Vehicles
        </Text>
        <Text
          as={Link}
          to={
            isEditing
              ? `/dashboard/batches/${batchId}/vehicles/${vehicleId}`
              : `/dashboard/batches/${batchId}/vehicles`
          }
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
        >
          ‹ Back to {isEditing ? "Vehicle" : "Batch"} Details
        </Text>
      </Box>
      <Box w={["calc(100% - 5rem)", "60%"]} mx={["2.5rem", "auto"]} mt="-2rem">
        <Skeleton
          isLoaded={!loading}
          boxShadow="0px 3px 15px #0000000D"
          borderRadius="10px"
          p="2rem"
          bg="white"
        >
          {isEditing ? (
            <Box>
              <Text type="header">Edit Vehicle</Text>
              <Text type="secondaryBodyLight">
                Vehicle #{vehicle && vehicle.id}
              </Text>
            </Box>
          ) : (
              <>
                <Box
                  d={["none", "block"]}
                  h="1.92rem"
                  w={"19rem"}
                  borderRadius="22px"
                  backgroundColor="gray.500"
                >
                  <Button>Single Vehicle Upload</Button>
                  <Button
                    backgroundColor="gray.500"
                    color="#3843644D"
                    _focus={{ backgroundColor: "gray.500" }}
                    onClick={uploadMultiple}
                    variantColor="gray"
                  >
                    Multiple Vehicle Upload
                </Button>
                </Box>
                <Box
                  d={["block", "none"]}
                  h="100%"
                  w={"15rem"}
                  borderRadius="22px"
                  backgroundColor="gray.500"
                >
                  <Button boxShadow="none" w="3.5rem">
                    Single
                </Button>
                  <Button
                    boxShadow="none"
                    w="4rem"
                    backgroundColor="gray.500"
                    color="#3843644D"
                    _focus={{ backgroundColor: "gray.500" }}
                    onClick={uploadMultiple}
                  >
                    Multiple
                </Button>
                </Box>
              </>
            )}
          <Grid
            templateColumns={["100%", "repeat(3, 1fr)"]}
            rowGap="2rem"
            columnGap="4rem"
            mt={5}
            d={["flex", "grid"]}
            flexDirection="column"
          >
            <FormControl
              d="flex"
              flexDirection="column"
              gridColumn={["1", "1/4"]}
            >
              <FormLabel
                fontSize={["1rem", "0.6rem"]}
                color="text.500"
                mb="0.6rem"
                fontWeight="bold"
              >
                Photos
              </FormLabel>
              <Box d="flex" flexWrap="wrap">
                <IconButton
                  borderColor="#707070"
                  onClick={onImageUploadClick}
                  id="uploadPhoto"
                  variant="outline"
                  aria-label="Uplaod Photo"
                  fontSize="20px"
                  width="3rem"
                  height="3rem"
                  color="#D3D3D3"
                  borderWidth={1}
                  icon={FaPlus}
                />
                {images.map((file, index) => {
                  return !file.object ? (
                    <Skeleton
                      key={index}
                      width="3rem"
                      height="3rem"
                      ml="0.5rem"
                    />
                  ) : (
                      <Box key={file.object.id} position="relative">
                        <Image
                          src={file.object.url}
                          width="3rem"
                          height="3rem"
                          ml="0.5rem"
                          mb="0.5rem"
                        />
                        <Box
                          onClick={() => onRemoveImage(index)}
                          cursor="pointer"
                          position="absolute"
                          top={0}
                          right={0}
                          w="20px"
                          h="20px"
                          d="flex"
                          fontSize={"10px"}
                          justifyContent="center"
                          alignItems="center"
                          color="white"
                        >
                          ✖
                      </Box>
                      </Box>
                    );
                })}
                <input
                  onChange={onImageUpload}
                  multiple
                  type="file"
                  id="file"
                  ref={inputImage}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </Box>
            </FormControl>
            <FormControl gridColumn={["1", "1/4"]}>
              <FormLabel
                fontSize={["1rem", "0.6rem"]}
                color="text.500"
                mb="0.6rem"
                fontWeight="bold"
              >
                Description
              </FormLabel>
              <Textarea
                borderColor="text.400"
                h="4rem"
                value={vehicle.description || ""}
                onChange={(e) => handleChange("description", e.target.value)}
              />
            </FormControl>

            <FormControl>
              <Select
                placeholder="Make"
                value={vehicle.make || ""}
                onChange={(e) => handleChange("make", e.target.value)}
              >
                {makeData.makes.map((make) => (
                  <option key={make.id} value={make.name}>
                    {make.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                placeholder="Model"
                value={vehicle.model || ""}
                onChange={(e) => handleChange("model", e.target.value)}
                isDisabled={selectedMake ? false : true}
              >
                {selectedMake &&
                  selectedMake.models.map((model) => (
                    <option key={model.id} value={model.name}>
                      {model.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                placeholder="Submodel"
                value={vehicle.submodel || ""}
                onChange={(e) => handleChange("submodel", e.target.value)}
                isDisabled={selectedModel ? false : true}
              >
                {selectedModel &&
                  selectedModel.submodels.map((submodel) => (
                    <option key={submodel.id} value={submodel.name}>
                      {submodel.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                placeholder="Year"
                value={vehicle.year || ""}
                onChange={(e) => handleChange("year", e.target.value)}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Input
                placeholder="Mileage"
                type="number"
                value={vehicle.mileage || ""}
                onChange={(e) => handleChange("mileage", e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder="Color"
                value={vehicle.color || ""}
                onChange={(e) => handleChange("color", e.target.value)}
              />
            </FormControl>

            <FormControl>
              <Select
                placeholder="Transmission"
                value={vehicle.transmission || ""}
                onChange={(e) => handleChange("transmission", e.target.value)}
              >
                <option value="Automatic">Automatic</option>
                <option value="Manual">Manual</option>
              </Select>
            </FormControl>
            <FormControl>
              <Select
                placeholder="Fuel Type"
                value={vehicle.fuel_type || ""}
                onChange={(e) => handleChange("fuel_type", e.target.value)}
              >
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="Hybrid">Hybrid</option>
                <option value="Electricity">Electricity</option>
                <option value="Natural Gas">Natural Gas</option>
                <option value="Petroleum Gas">Petroleum Gas</option>
              </Select>
            </FormControl>
            <FormControl>
              <Select
                placeholder="Country Specification"
                value={vehicle.regional_specs || ""}
                onChange={(e) => handleChange("regional_specs", e.target.value)}
              >
                <option value="GCC">GCC</option>
                <option value="Japanese">Japanese</option>
                <option value="American">American</option>
                <option value="European">European</option>
              </Select>
            </FormControl>
            <FormControl d="flex">
              <Input
                placeholder="Price (optional)"
                type="number"
                value={vehicle.price || ""}
                onChange={(e) => handleChange("price", e.target.value)}
              />
              <Select value={vehicle?.currency} onChange={e => handleChange("currency", e.target.value)}>
                {
                  currencies.map(currency => <option key={currency.id} value={currency.name}>{currency.name}</option>)
                }
              </Select>
            </FormControl>

            <FormControl>
              <Input
                placeholder="Number of Cylinders"
                type="number"
                value={vehicle.num_cylinders || ""}
                onChange={(e) => handleChange("num_cylinders", e.target.value)}
                min={2}
                max={24}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder="Number of Doors"
                type="number"
                value={vehicle.num_doors || ""}
                onChange={(e) => handleChange("num_doors", e.target.value)}
                min={1}
                max={5}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder="Engine Size (ex. 2.2)"
                type="number"
                value={vehicle.engine_displacement || ""}
                onChange={(e) =>
                  handleChange("engine_displacement", e.target.value)
                }
              />
            </FormControl>

            <FormControl gridColumn="1/3">
              <Checkbox
                isChecked={vehicle.warranty || false}
                mb="1rem"
                onChange={(e) => handleChange("warranty", e.target.checked)}
              >
                <Box>
                  <Text type="secondaryBodyRegular">Warranty</Text>
                  <Text type="secondaryBodyLight">
                    This vehicle has valid warranty
                  </Text>
                </Box>
              </Checkbox>
              <Checkbox
                isChecked={vehicle.service_history || false}
                onChange={(e) =>
                  handleChange("service_history", e.target.checked)
                }
              >
                <Box>
                  <Text type="secondaryBodyRegular">Service history</Text>
                  <Text type="secondaryBodyLight">
                    This vehicle has service history available
                  </Text>
                </Box>
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel
                fontSize={["1rem", "0.6rem"]}
                color="text.500"
                mb="0.6rem"
                fontWeight="bold"
              >
                Attachments
              </FormLabel>
              <Box
                borderColor="text.500"
                borderBottomWidth="1px"
                height="1.1rem"
                fontSize="0.6rem"
                paddingBottom="0.4rem"
                paddingX="0.4rem"
                cursor="pointer"
                onClick={onAttachmentUploadClick}
              >
                <Box as={FaPlus} float="right" />
                <input
                  onChange={onAttachmentUpload}
                  multiple
                  type="file"
                  id="file"
                  ref={inputAttachment}
                  style={{ display: "none" }}
                />
              </Box>
              {files.map((file, index) =>
                file.uploading ? (
                  <Skeleton
                    key={index}
                    my="0.3rem"
                    h="25px"
                    w="100%"
                    borderRadius="25px"
                  ></Skeleton>
                ) : (
                    <Box
                      d="flex"
                      my="0.3rem"
                      justifyContent="space-between"
                      alignItems="center"
                      p="5px"
                      key={index}
                      w="100%"
                      borderRadius="25px"
                      border="1px solid"
                      borderColor="purple.500"
                    >
                      <Text
                        maxW="180px"
                        textOverflow="ellipsis"
                        fontSize="0.5rem"
                        color="purple.500"
                      >
                        {file?.object?.name}
                      </Text>
                      <Box
                        as={FaTimesCircle}
                        color="purple.500"
                        fontSize="16px"
                        cursor="pointer"
                        onClick={() => onRemoveAttachment(index)}
                      />
                    </Box>
                  )
              )}
            </FormControl>
          </Grid>
          <Box mx="auto" mt="1rem" d="flex" justifyContent="center">
            <Button onClick={handleSubmit}>
              {isEditing ? "Save Changes" : "Add Vehicle"}
            </Button>
            <Button
              backgroundColor="gray.500"
              color="white"
              ml="1rem"
              _focus={{ backgroundColor: "gray.400" }}
              onClick={goBack}
            >
              Cancel
            </Button>
          </Box>
        </Skeleton>
      </Box>
    </Box>
  );
};

export default AddVehicle;
