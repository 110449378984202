import React from "react";
import { Text } from "@chakra-ui/core";

const typography = {
  headline: {
    fontSize: "1.35rem",
    fontWeight: "bold",
  },
  title: {
    fontSize: ["1.8rem", "1.1rem"],
    fontWeight: ["bold", "medium"],
  },
  header: {
    fontSize: ["1.5rem", "0.95rem"],
    fontWeight: "bold",
  },
  largeBodyBold: {
    fontSize: ["0.9rem", "0.7rem"],
    fontWeight: "bold",
  },
  largeBodyRegular: {
    fontSize: ["0.9rem", "0.7rem"],
    fontWeight: "medium",
  },
  largeBodyLight: {
    fontSize: ["0.9rem", "0.7rem"],
    fontWeight: "light",
  },
  secondaryBodyBold: {
    fontSize: ["0.8rem", "0.6rem"],
    fontWeight: "bold",
  },
  secondaryBodyRegular: {
    fontSize: ["0.8rem", "0.6rem"],
    fontWeight: "medium",
  },
  secondaryBodyLight: {
    fontSize: ["0.8rem", "0.6rem"],
    fontWeight: "light",
  },
  bodyBold: {
    fontSize: ["0.8rem", "0.5rem"],
    fontWeight: "bold",
  },
  bodyMedium: {
    fontSize: ["0.8rem", "0.5rem"],
    fontWeight: "400",
  },
  bodyLight: {
    fontSize: ["0.8rem", "0.5rem"],
    fontWeight: "light",
  },
  placeholder: {
    fontSize: "0.45rem",
  },
};

const CustomText = ({ type, children, removeStyling = false, ...props }) => {
  return (
    <Text
      color="text.500"
      fontSize={type && typography[type].fontSize}
      fontWeight={type && typography[type].fontWeight}
      className={!removeStyling && "text"}
      {...props}
    >
      {children}
    </Text>
  );
};

export default CustomText;
