import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const loggedInUser = localStorage.getItem("firebaseUser")
    ? JSON.parse(localStorage.getItem("firebaseUser"))
    : {};
  const token = loggedInUser.stsTokenManager
    ? loggedInUser.stsTokenManager.accessToken
    : "";
  return (
    <Route
      {...rest}
      render={(props) =>
        token && token.length && restricted ? (
          <Redirect to="/marketplace" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
