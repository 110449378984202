import React from "react";
import { Grid, Box, Button } from "@chakra-ui/core";
import { FaCar } from "react-icons/fa";

import Text from "./Text";
import TagOutline from "./TagOutline";
import TagCountDown from "./TagCountDown";
import SalesRepresentative from "./SalesRepresentative";
import FeaturedContainer from "./FeaturedContainer";

import moment from "moment";

const BatchInfo = ({ variant, isOwner, isLoaded = true, batch, ...props }) => {
  return (
    <Grid
      templateColumns={!isOwner ? ["1fr", "7fr 3fr"] : "1fr"}
      columnGap="0.6rem"
      isLoaded={false}
      {...props}
    >
      <FeaturedContainer variant={variant}>
        <Box py="1rem" px={["1.5rem", "0.5rem"]} w="100%">
          <Box
            d="flex"
            alignItems="center"
            justifyContent={["space-between", "flex-start"]}
            mt={"5px"}
            fontSize={["1rem", "0.65rem"]}
          >
            <Text fontWeight="light" color="text.400">
              Batch #{batch.id}
            </Text>
            <Text fontWeight="light" color="text.400" d="flex" ml="0.5rem">
              <Text display={["none", "block"]}>Posted</Text>{" "}
              <span style={{ marginLeft: "0.5rem" }}>
                {moment(batch.create_data).format("DD MMM yyyy")}
              </span>
            </Text>
            {variant && (
              <Text color={variant} fontWeight={"bold"} ml={"0.5rem"}>
                {variant === "urgent" ? "Urgent" : "Hot"}
              </Text>
            )}
            {isOwner && (
              <Box ml="auto">
                <Button variantColor="purple" variant="ghost">
                  + Add Vehicles
                </Button>
                <Button variantColor="purple" variant="ghost">
                  Edit Batch
                </Button>
                <Button variantColor="red" variant="ghost">
                  Unpost Batch
                </Button>
              </Box>
            )}
          </Box>
          <Text type="header" lineHeight={["1.5rem", "1rem"]} mt={2}>
            {batch.title}
          </Text>
          <Box d="flex">
            <Text type="bodyLight" color="text.400" mt={2} maxW="60%">
              {batch.description}
            </Text>
            {isOwner && (
              <Box d="flex" ml="auto">
                <TagOutline
                  bg="transparent"
                  borderWidth="1px"
                  borderColor="text.200"
                  color="text.200"
                  fontSize="0.6rem"
                  text={`8 Offers`}
                />
                <TagOutline
                  bg="transparent"
                  borderWidth="1px"
                  borderColor="urgent"
                  color="urgent"
                  fontSize="0.6rem"
                  ml="0.5rem"
                  text={`4 Deals`}
                />
              </Box>
            )}
          </Box>
          <Box
            w="100%"
            mt={["1rem", 1]}
            d="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {isOwner ? (
              <Box d="flex">
                <Text type="secondaryBodyBold">Prices</Text>
                <Text ml="0.2rem" type="secondaryBodyRegular">
                  Visible
                </Text>
                <Text ml="0.5rem" type="secondaryBodyBold">
                  Location
                </Text>
                <Text ml="0.2rem" type="secondaryBodyRegular">
                  Undisclosed
                </Text>
                <Text ml="0.5rem" type="secondaryBodyBold">
                  Distribution
                </Text>
                <Text ml="0.2rem" type="secondaryBodyRegular">
                  Network & GLobal
                </Text>
              </Box>
            ) : (
              <Box color="text.500" d="flex" alignItems="center">
                <TagOutline text="Used" />
                <Box ml="13px" as={FaCar} size={("1.5rem", "0.8rem")}></Box>
                <Text fontSize={["1rem", "0.6rem"]} ml="0.5rem" d="flex">
                  {batch.num_vehicles}{" "}
                  <Text ml="0.5rem" display={["none", "block"]}>
                    vehicles
                  </Text>
                </Text>
              </Box>
            )}
            {batch.expiry_date && (
              <TagCountDown d={["none", "flex"]} fromTime={batch.expiry_date} />
            )}
          </Box>
          <Box mt="1rem" d={["block", "none"]}>
            {batch.expiry_date && <TagCountDown fromTime={batch.expiry_date} />}
          </Box>
        </Box>
      </FeaturedContainer>
      {!isOwner && (
        <SalesRepresentative
          representative={batch.dealer || {}}
          network={batch.network || {}}
        />
      )}
    </Grid>
  );
};

export default BatchInfo;
