import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  registerUser as registerUserAction,
  getLoggedInUser,
} from "../../actions/authActions";

import { Box, useToast } from "@chakra-ui/core";

import Header from "../login/Header";
import Stepper from "./Stepper";
import ProfileInformation from "./ProfileInformation";
import BusinessInformation from "./BusinessInformation";
import Welcome from "./Welcome";

const CompleteProfile = ({ history, match }) => {
  const store = useSelector((state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
  }));
  const dispatch = useDispatch();
  const toast = useToast();
  const { inviter_id } = match.params;

  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [profileFields, setProfileFields] = useState({
    first_name: "",
    last_name: "",
    image_id: "",
    company: "",
    job_title: "",
    city: "",
    country: "",
    phone1: "",
    phone2: "",
    website: "",
  });
  const [profileErrors, setProfileErrors] = useState({});
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const [businessFields, setBusinessFields] = useState({
    purchase_vol: "",
    purchase_countries: "",
    sales_vol: "",
    sales_countries: "",
  });
  const [businessErrors, setBusinessErrors] = useState({});

  const handleProfileFieldsChange = (e) => {
    const fields = { ...profileFields, [e.target.name]: e.target.value };
    setProfileFields(fields);
    let filledFields = 0;
    Object.keys(fields).forEach((field) => {
      if (profileFields[field].length > 0) {
        filledFields++;
      }
    });
    setProgress((100 * filledFields) / Object.keys(fields).length);
  };

  const handleBusinessFieldsChange = (e) => {
    const fields = { ...businessFields, [e.target.name]: e.target.value };
    setBusinessFields(fields);
    let filledFields = 0;
    Object.keys(fields).forEach((field) => {
      if (businessFields[field].length > 0) {
        filledFields++;
      }
    });
    setProgress((100 * filledFields) / Object.keys(fields).length);
  };

  const goToNextStep = () => {
    const newStep = step + 1;
    setStep(newStep);
    if (newStep === 3) {
      dispatch(getLoggedInUser());
    }
    setProgress(0);
  };

  const goToDashboard = () => {
    history.push("/marketplace");
  };

  const handleError = (err) => {
    const description = err.detail
      ? `${err.detail[0].loc[0]} ${err.detail[0].msg}`
      : JSON.stringify(err);
    toast({
      position: "top-right",
      title: "An error occured",
      description,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  const registerUser = () => {
    const data = {
      ...profileFields,
      image_id:
        profileFields.image_id && profileFields.image_id >= 0
          ? profileFields.image_id
          : undefined,
      fullname: profileFields.first_name + " " + profileFields.last_name,
      write_date: new Date(),
      create_date: new Date(),
      ...businessFields,
      firebase_uid: store.user.firebase_uid,
      inviter_id: inviter_id ? Number(inviter_id) : undefined,
    };
    dispatch(registerUserAction(data, goToNextStep, handleError));
  };

  return (
    <Box w="full" h="100vh" cursor={step === 3 && "progress"}>
      <Box
        className="gradient-background mobile-remove"
        bg={["purple.500", null]}
        h={["15vh", "30vh"]}
        px={["2rem", "5.4rem"]}
        pb={["12rem", 0]}
      >
        <Header />
      </Box>
      <Box
        shadow="lg"
        rounded="lg"
        bg="white"
        width={
          step === 3
            ? ["calc(100% - 4rem)", "25%"]
            : ["calc(100% - 4rem)", "50%"]
        }
        maxWidth={900}
        margin="auto"
        mt={-24}
      >
        {step < 3 && <Stepper step={step} progress={progress} />}
        {step === 1 && (
          <Box py={8} px="4rem">
            <ProfileInformation
              fields={profileFields}
              setFields={handleProfileFieldsChange}
              errors={profileErrors}
              setErrors={setProfileErrors}
              uploadedImageUrl={uploadedImageUrl}
              setUploadedImageUrl={setUploadedImageUrl}
              goToNextStep={goToNextStep}
            />
          </Box>
        )}
        {step === 2 && (
          <Box py={8} px="2rem">
            <BusinessInformation
              fields={businessFields}
              setFields={handleBusinessFieldsChange}
              errors={businessErrors}
              setErrors={setBusinessErrors}
              goToNextStep={registerUser}
              loading={store.loading}
            />
          </Box>
        )}
        {step === 3 && (
          <Welcome
            goToNextStep={goToNextStep}
            register={goToDashboard}
            name={profileFields.first_name}
            image={uploadedImageUrl}
          />
        )}
      </Box>
    </Box>
  );
};

export default CompleteProfile;
