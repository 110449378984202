import React, { useEffect, useState } from "react";
import { Grid, Box, Skeleton, Button } from "@chakra-ui/core";

import Text from "../../common/Text";
import CarRow from "../../common/CarRow";
import Filters from "../../common/Filters";

import { useSelector, useDispatch } from "react-redux";
import { getVehicles, setBatch } from "../../actions/dashboardActions";

import makes from "../../common/meta/makes.json";
import years from "../../common/meta/years.js";

const MyBatchVehicles = ({ match, history }) => {
  let currentBatch = useSelector((state) => state.dashboard.currentBatch);
  let loading = useSelector((state) => state.dashboard.loading);
  let dispatch = useDispatch();
  let { batchId } = match.params;
  useEffect(() => {
    dispatch(getVehicles(batchId));
  }, []);

  const queryVehicles = () => {
    const params = {
      make: detailedFilters[0].selected,
      model: detailedFilters[1].selected,
      year: detailedFilters[2].selected,
    };
    dispatch(getVehicles(batchId, params));
  };

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    if (filterIndex === 0 && value.length > 0) {
      newDetailedFilter[1].options = makes[value].models;
    }
    setDetailedFilters(newDetailedFilter);
    queryVehicles();
  };

  const [detailedFilters, setDetailedFilters] = useState([
    { name: "Make", options: Object.keys(makes), selected: "" },
    { name: "Model", options: [], selected: "" },
    { name: "Year", options: years, selected: "" },
  ]);


  const setCar = (car) => {
    let batch = { ...currentBatch };
    let index = batch.vehicles.findIndex(vehicle => vehicle.id === car.id);
    batch.vehicles[index] = { ...batch.vehicles[index], ...car };
    dispatch(setBatch(batch))
  }


  if (loading || !currentBatch.object || !currentBatch.vehicles) {
    return (
      <Skeleton
        background="white"
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
        mt="1rem"
        height={"4rem"}
      />
    );
  }

  return (
    <Box
      background="white"
      boxShadow="0px 3px 15px #0000000D"
      borderRadius="10px"
      mt="1rem"
    >
      <Box
        d="flex"
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
        py="1.5rem"
        flexDir={["column", "row"]}
        mx="1.5rem"
      >
        <Box w={["100%", "8rem"]}>
          <Text
            fontWeight="bold"
            fontSize={["1.8rem", "0.95rem"]}
            color="text.500"
            lineHeight={["2rem", "1rem"]}
          >
            Batch Summary
          </Text>
          <Text
            fontWeight="light"
            fontSize={["1rem", "0.5rem"]}
            color="text.400"
            maxW="60%"
          >
            Showing {currentBatch.object.num_vehicles} vehicles
          </Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={selectDetailedFilter}
        />
      </Box>
      {!currentBatch.vehicles.length && (
        <Box mx="1.5rem">
          <Text type="secondaryBodyRegular">
            This batch does not have any vehicles. Add vehicles by selecting the
            " + Add Vehicles" option above.
          </Text>
        </Box>
      )}
      <Grid mx="1.5rem" py="1.5rem" rowGap="0.25rem" overflowY="scroll">
        {loading &&
          [1, 2, 3, 4].map((key) => <Skeleton key={key} height={"2rem"} />)}
        {currentBatch.vehicles.map((vehicle) => (
          <CarRow
            setCar={setCar}
            key={vehicle.id}
            car={vehicle}
            type="batch"
            isOwner={true}
            linkTo={`vehicles/${vehicle.id}`}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default MyBatchVehicles;
