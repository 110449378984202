import React from "react";
import { Switch, Route } from "react-router-dom";

import OffersList from "./MyOffersList";
import OfferDetails from "./MyOfferDetails";
import VehicleDetails from "../vehicleDetails/VehicleDetails";

const OffersRouter = () => {
  return (
    <Switch>
      <Route
        path="/dashboard/offer/details/:offerId/batch/:batchId/vehicle/:vehicleId"
        component={VehicleDetails}
      />
      <Route
        path="/dashboard/offer/details/:offerId"
        component={OfferDetails}
      />
    </Switch>
  );
};

export default OffersRouter;
