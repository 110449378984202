import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getOffers as getOffersAction } from "../../actions/dashboardActions";

import { Box, Skeleton } from "@chakra-ui/core";

import Text from "../../common/Text";
import Offer from "../../common/OfferDealStatus";
import Filters from "../../common/Filters";

import {
  offersVariantsMapping as variantsMapping,
  myOffersTitlesMapping as titlesMapping,
  myOffersCounterTitlesMapping as counterTitlesMapping,
  myOfferCounterDescriptionsMapping as counterDescriptionsMapping,
  myOffersDescriptionsMapping as descriptionsMapping,
  myOffersTitlesToParams as titlesToParams,
} from "../../common/mappings";

const Offers = ({ history }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    offers: state.dashboard.offers.objects,
    pages: state.dashboard.offers.pages,
    page: state.dashboard.offers.page,
    loading: state.dashboard.offers.loading,
  }));
  const currentState = history.location.pathname.split("/")[3];
  const [detailedFilters, setDetailedFilters] = useState([
    {
      name: "Status",
      options: [
        "Offer Received",
        "Offer Accepted",
        "Offer Declined",
        "Offer Withdrawn",
        "Counter Offer Made",
        "Counter Offer Accepted",
        "Counter Offer Declined",
      ],
      selected: titlesMapping[currentState] || "",
    },
  ]);

  const searchKeyword = useSelector((state) => state.dashboard.searchKeyword);
  const offersCount = useSelector((state) => state.dashboard.searchResultsCount.offers);
  useEffect(() => {
    if (searchKeyword) getOffers();
  }, [searchKeyword])

  useEffect(() => {
    getOffers();
  }, []);

  const getOffers = (page = 1) => {
    const params = {
      state: history.location.pathname.split("/")[3],
      q: searchKeyword
    };
    dispatch(getOffersAction(params));
  };

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    setDetailedFilters(newDetailedFilter);
    dispatch(getOffersAction({ state: titlesToParams[value] || "" }));
    history.push(`/dashboard/offers/${titlesToParams[value] || ""}`);
  };

  return (
    <Box mt="1.5rem">
      <Box
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="1rem"
      >
        <Box>
          <Text type="header">Offers</Text>
          <Text type="bodyLight">{searchKeyword ? `${offersCount} result${offersCount > 1 ? "s" : ""} that match your search in Offers` : "All offers made on your batches"}</Text>
        </Box>
        <Filters
          hideGeneral={true}
          detailedFilters={detailedFilters}
          onSelectedDetailed={selectDetailedFilter}
        />
      </Box>
      <Box>
        <Skeleton isLoaded={!store.loading}>
          {store.offers.map((offer) => (
            <Offer
              showBuyer={true}
              buyer={offer.buyer}
              key={offer.id}
              id={offer.id}
              variant={variantsMapping[offer.state]}
              title={
                offer.is_counter
                  ? counterTitlesMapping[offer.state]
                  : titlesMapping[offer.state]
              }
              description={
                offer.is_counter
                  ? counterDescriptionsMapping[offer.state]
                  : descriptionsMapping[offer.state]
              }
              offer={offer}
              linkTo={`/dashboard/offer/details/${offer.id}`}
            />
          ))}
        </Skeleton>
      </Box>
    </Box>
  );
};

export default Offers;
