import typeToReducer from "type-to-reducer";
import {
  GET_FEATURED_BATCHES,
  GET_MARKETPLACE_BATCHES,
  GET_MARKETPLACE_OFFERS,
  GET_MARKETPLACE_DEALS,
  SET_CURRENT_OFFER,
} from "../constants";

const initalState = {
  batches: {
    objects: [],
    loading: false,
    error: {},
    isRejected: false,
  },
  featured: {
    objects: [],
    loading: false,
    error: {},
    isRejected: false,
  },
  offers: {
    objects: [],
    loading: false,
    error: {},
    isRejected: false,
  },
  deals: {
    objects: [],
    loading: false,
    error: {},
    isRejected: false,
  },
  currentOffer: {},
};

const reducer = typeToReducer(
  {
    [GET_MARKETPLACE_BATCHES]: {
      PENDING: (state) => ({
        ...state,
        batches: { ...state.batches, loading: true },
      }),
      REJECTED: (state, action) => ({
        ...state,
        batches: {
          ...state.batches,
          loading: false,
          isRejected: true,
          error: action.payload,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        batches: {
          ...state.batches,
          ...action.payload,
          loading: false,
          isRejected: false,
        },
      }),
    },
    [GET_FEATURED_BATCHES]: {
      PENDING: (state) => ({
        ...state,
        featured: { ...state.featured, loading: true },
      }),
      REJECTED: (state, action) => ({
        ...state,
        featured: {
          ...state.featured,
          loading: false,
          isRejected: true,
          error: action.payload,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        featured: {
          ...state.featured,
          ...action.payload,
          loading: false,
          isRejected: false,
        },
      }),
    },
    [GET_MARKETPLACE_OFFERS]: {
      PENDING: (state) => ({
        ...state,
        offers: { ...state.offers, loading: true },
      }),
      REJECTED: (state, action) => ({
        ...state,
        offers: {
          ...state.offers,
          loading: false,
          isRejected: true,
          error: action.payload,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        offers: {
          ...state.offers,
          ...action.payload,
          loading: false,
          isRejected: false,
        },
      }),
    },
    [GET_MARKETPLACE_DEALS]: {
      PENDING: (state) => ({
        ...state,
        deals: { ...state.deals, loading: true },
      }),
      REJECTED: (state, action) => ({
        ...state,
        deals: {
          ...state.deals,
          loading: false,
          isRejected: true,
          error: action.payload,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deals: {
          ...state.deals,
          ...action.payload,
          loading: false,
          isRejected: false,
        },
      }),
    },
    [SET_CURRENT_OFFER]: (state, action) => ({
      ...state,
      currentOffer: action.payload,
    }),
  },
  initalState
);

export default reducer;
