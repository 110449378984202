import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import { offers as offersApi, batches as batchesApi } from "../../api";
import useFetchHook from "../../common/hooks/useFetchHook";
import { currencyFormat } from "../../common/utils";
import moment from "moment";

import { Box, Grid, Skeleton } from "@chakra-ui/core";

import BatchInfo from "../../common/BatchInfo";
import FeaturedContainer from "../../common/FeaturedContainer";
import OfferSummaryModal from "../../common/OfferSummaryModal";
import MyOfferStates from "./MyOfferStates";
import MyCounterOfferStates from "./MyCounterOfferStates";

import Filters from "../../common/Filters";
import CarRow from "../../common/CarRow";
import Text from "../../common/Text";

import makes from "../../common/meta/makes.json";
import years from "../../common/meta/years.js";

import {
  offersVariantsMapping as variantsMapping,
  myOffersLayoutMapping as layoutMapping,
  myOffersCounterLayoutMapping as counterLayoutMapping,
} from "../../common/mappings";

const OfferDetails = ({ history, match }) => {
  const { offerId } = match.params;
  const [offer, dispatchOffer] = useFetchHook();
  const [vehicles, dispatchVehicles] = useFetchHook();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modifying, setModifying] = useState(false);
  const [detailedFilters, setDetailedFilters] = useState([
    { name: "Make", options: Object.keys(makes), selected: "" },
    { name: "Model", options: [], selected: "" },
    { name: "Year", options: years, selected: "" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(
    function init() {
      dispatchOffer({ type: "pending" });
      offersApi.getSingle(offerId).then((response) => {
        if (response.object.is_counter) {
          offersApi.getVehicles(response.object.id).then((vehicle_response) => {
            response.object.counter_vehicle_offers = vehicle_response.objects;
            dispatchOffer({ type: "fulfilled", payload: response.object });
          });
        } else {
          dispatchOffer({ type: "fulfilled", payload: response.object });
        }
      });
    },
    [offerId]
  );

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    if (filterIndex === 0 && value.length > 0) {
      newDetailedFilter[1].options = makes[value].models;
    }
    setDetailedFilters(newDetailedFilter);
    getVehicles();
  };

  const modifyOffer = () => getVehicles(true);

  const addToOffer = (index, offerMade) => {
    const _vehicles = [...vehicles.list];
    _vehicles[index].offerMade = offerMade;
    dispatchVehicles({ type: "set", payload: _vehicles });
    setIsChanged(true);
  };

  const removeFromOffer = (index) => {
    const _vehicles = [...vehicles.list];
    delete _vehicles[index].offerMade;
    dispatchVehicles({ type: "set", payload: _vehicles });
    setIsChanged(true);
  };

  const updateOffer = () => {
    const vehicle_offers = [];
    vehicles.list.forEach((vehicle) => {
      if (vehicle.offerMade > 0) {
        vehicle_offers.push({
          vehicle_ids: [vehicle.id],
          price: vehicle.offerMade,
        });
      }
    });
    dispatchVehicles({ type: "pending" });
    offersApi.counter(offerId, vehicle_offers).then((response) => {
      setModifying(false);
      toggleModal();
      history.push(`/dashboard/offer/details/${response.object.id}`);
      dispatchOffer({
        type: "fulfilled",
        payload: { ...offer.data, id: response.object.id },
      });
    });
  };

  const getVehicles = () => {
    const params = {
      make: detailedFilters[0].selected,
      model: detailedFilters[1].selected,
      year: detailedFilters[2].selected,
    };
    dispatchVehicles({ type: "pending" });
    batchesApi.getVehicles(offer.data.batch.id, params).then((response) => {
      const offerVehicles = offer.data.vehicle_offers;
      response.objects.forEach((batchVehicle, batchVehicleIndex) => {
        const index = offerVehicles.findIndex(
          (offerVehicle) => offerVehicle.vehicles[0].id === batchVehicle.id
        );
        if (index >= 0) {
          response.objects[batchVehicleIndex].offerMade =
            offerVehicles[index].price;
        }
      });
      dispatchVehicles({ type: "fulfilled", payload: response });
      setModifying(true);
    });
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const declineOffer = () => {
    setIsLoading(true);
    offersApi.reject(offerId).then((response) => {
      setIsLoading(false);
      dispatchOffer({ type: "fulfilled", payload: response.object });
    });
  };

  const acceptOffer = () => {
    setIsLoading(true);
    offersApi.accept(offerId).then((response) => {
      setIsLoading(false);
      dispatchOffer({ type: "fulfilled", payload: response.object });
    });
  };

  const calculateOffer = () => {
    let value = 0;
    if (offer.data.vehicle_offers) {
      offer.data.vehicle_offers.forEach((vehicle) => {
        value += Number(vehicle.price);
      });
    }
    return value;
  };
  const currentOffer = calculateOffer();
  const type = offer.data.is_counter ? "pending" : offer.data.state || "";

  return (
    <Box>
      <Box
        w={["auto", "100%"]}
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text type="header" color="white">
          Offer Details
        </Text>
        <Text
          as={Link}
          to="/dashboard/offers"
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
          cursor="pointer"
        >
          ‹ Back to My Offers
        </Text>
      </Box>
      <Box mx="2.5rem" mt="2rem">
        <Skeleton isLoaded={!offer.loading}>
          <BatchInfo
            innerProps={{ px: "1rem" }}
            mt="-5rem"
            batch={offer.data.batch || {}}
          />
        </Skeleton>
        <Skeleton isLoaded={!offer.loading} mt={offer.loading ? "1rem" : 0}>
          <Grid
            templateColumns={
              offer.data.is_counter ? counterLayoutMapping : layoutMapping[type]
            }
            columnGap="0.6rem"
          >
            <FeaturedContainer
              minH="7rem"
              mt={["1rem", "0.6rem"]}
              variant="purple.500"
              opacity={
                type === "withdrawn" || offer.data.is_counter || modifying
                  ? 0.5
                  : 1
              }
            >
              <Box
                p="1rem"
                pb="2rem"
                d="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Text type="header"> Offer Received </Text>
                <Text type="secondaryBodyLight">
                  {offer.data.title} {moment(offer.data.create_date).fromNow()}
                </Text>
                <Text type="header" mt="0.5rem">
                  {offer.data.is_counter
                    ? offer.data.original_offer.vehicle_offers?.length
                    : offer.data.vehicle_offers?.length}{" "}
                  Vehicles /{" "}
                  {currencyFormat(
                    offer.data.is_counter
                      ? offer.data.original_offer.price
                      : offer.data.price
                  )}
                </Text>
              </Box>
            </FeaturedContainer>
            <FeaturedContainer
              variant={
                type === "pending" && !offer.data.is_counter
                  ? ""
                  : variantsMapping[type]
              }
              minH="7rem"
              mt={["1rem", "0.6rem"]}
              opacity={
                offer.data.is_counter && offer.data.state === "rejected"
                  ? 0.5
                  : 1
              }
            >
              <MyOfferStates
                type={type}
                offer={offer.data}
                modify={modifyOffer}
                modifying={modifying}
                isChanged={isChanged}
                vehicles={vehicles.list}
                decline={declineOffer}
                accept={acceptOffer}
                loading={isLoading || vehicles.loading}
                update={updateOffer}
              />
            </FeaturedContainer>
            {offer.data.is_counter && (
              <MyCounterOfferStates
                type={offer.data.state}
                deal={offer.data.deal}
              />
            )}
          </Grid>
        </Skeleton>
      </Box>
      <Box
        background="white"
        mx="2.5rem"
        mt={["2rem", "0.6rem"]}
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
      >
        <Box
          d="flex"
          alignItems={["flex-start", "center"]}
          justifyContent="space-between"
          py="1.5rem"
          flexDir={["column", "row"]}
          mx="2.5rem"
        >
          <Box w={["100%", "8rem"]}>
            <Text type="header">Offer Summary</Text>
            <Text
              fontWeight="light"
              fontSize={["1rem", "0.5rem"]}
              color="text.400"
              maxW="60%"
            >
              Showing{" "}
              {(modifying ? vehicles.list : offer.data?.vehicle_offers)?.length}{" "}
              vehicles
            </Text>
          </Box>
          <Filters
            hideGeneral={true}
            detailedFilters={detailedFilters}
            onSelectedDetailed={modifying ? selectDetailedFilter : () => { }}
          />
        </Box>
        <Grid mx="1.5rem" pb="1.5rem" rowGap="0.25rem" overflowY="scroll">
          {(modifying
            ? vehicles.list
            : offer.data.is_counter
              ? offer.data.counter_vehicle_offers
              : offer.data?.vehicle_offers
          )?.map((car, index) => {
            return (
              <CarRow
                key={!modifying ? car.vehicles[0].id : car.id}
                index={index}
                type={!modifying && "offer"}
                linkTo={`${offerId}/batch/${offer.data.batch.id}/vehicle/${!modifying ? car.vehicles[0].id : car.id
                  }`}
                car={modifying ? car : { ...car.vehicles[0], offerMade: car.price }}
                offerMade={modifying && car.offerMade}
                showPrice
                addToOffer={addToOffer}
                removeFromOffer={removeFromOffer}
              />
            );
          })}
        </Grid>
      </Box>
      <OfferSummaryModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        currentOffer={currentOffer}
        footerText="Continue to Offer Details"
        headerText="Your counter-offer has been sent to the buyer"
        linkTo={`/dashboard/offer/details/${offer.data.id}`}
        offer={offer}
      />
    </Box>
  );
};

export default withRouter(OfferDetails);
