import React, { useEffect } from "react";
import { Box, Image } from "@chakra-ui/core";

import Button from "../../common/Button";
import Text from "../../common/Text";

const Welcome = ({ register, image, name }) => {
  useEffect(function () {
    setTimeout(register, 2000);
  }, []);
  return (
    <Box
      cursor="progress"
      d="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt="4rem"
      minW="19rem"
    >
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          height={[50, 63]}
          width={[50, 63]}
          rounded="full"
          d="flex"
          justifyContent="center"
          alignItems="center"
          className="image-border-gradient"
          m="auto"
          marginTop={["1rem", "-1rem"]}
        >
          {image && image.length && (
            <Image
              objectFit="cover"
              rounded="full"
              size={60}
              src={image}
              alt="Inviter"
            />
          )}
        </Box>
        <Text type="header" textAlign="center" my="0.6rem">
          Welcome {name}!
        </Text>
      </Box>
    </Box>
  );
};

export default Welcome;
