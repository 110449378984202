import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getFeatured,
  getBatches as getBatchesAction,
} from "../../actions/marketplaceActions";

import { Box, Skeleton } from "@chakra-ui/core";

import Text from "../../common/Text";
import Filters from "../../common/Filters";
import Pagination from "../../common/Pagination";
import FeaturedBox from "./FeaturedBox";
import Batch from "./Batch";

import makes from "../../common/meta/makes.json";
import years from "../../common/meta/years.js";

const generalFilters = [
  "All Batches",
  "New",
  "Used",
  "Ex-rental",
  "Ex-fleet",
  "Mixed",
];

const filtersMap = {
  new: "New",
  used: "Used",
  exrental: "Ex-rental",
  exfleet: "Ex-fleet",
  mixed: "Mixed",
};

const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // setInterval(() => {
    //   setCurrentIndex(currentIndex + 1);
    // }, 5000);
  }, [currentIndex]);

  return children.length > 0 ? (
    children.slice(currentIndex, currentIndex + 3)
  ) : (
      <Box h="2rem" />
    );
};

const Marketplace = ({ history }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    featured: state.marketplace.featured.objects,
    batches: state.marketplace.batches.objects,
    pages: state.marketplace.batches.pages,
    page: state.marketplace.batches.page,
    batchesLoading: state.marketplace.batches.loading,
    featuredLoading: state.marketplace.featured.loading,
  }));
  const selectedFilter = filtersMap[history.location.pathname.split("/")[2]];

  const [detailedFilters, setDetailedFilters] = useState([
    { name: "Make", options: Object.keys(makes), selected: "" },
    { name: "Model", options: [], selected: "" },
    { name: "Year", options: years, selected: "" },
  ]);

  useEffect(function init() {
    if (store.featured.length == 0) {
      dispatch(getFeatured());
    }
    if (store.batches.length == 0) {
      getBatches();
    }
  }, []);

  const getBatches = (page = 1) => {
    const params = {
      type: history.location.pathname.split("/")[2],
      make: detailedFilters[0].selected,
      model: detailedFilters[1].selected,
      year: detailedFilters[2].selected,
      page,
    };
    dispatch(getBatchesAction(params));
  };

  const navigateToFilter = (selectedTab) => {
    switch (selectedTab) {
      case "All Batches":
        history.push("/marketplace");
        break;
      case "New":
        history.push("/marketplace/new");
        break;
      case "Used":
        history.push("/marketplace/used");
        break;
      case "Ex-rental":
        history.push("/marketplace/exrental");
        break;
      case "Ex-fleet":
        history.push("/marketplace/exfleet");
        break;
      case "Mixed":
        history.push("/marketplace/mixed");
        break;

      default:
        break;
    }
    getBatches();
  };

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    if (filterIndex === 0 && value.length > 0) {
      newDetailedFilter[1].options = makes[value].models;
    }
    setDetailedFilters(newDetailedFilter);
    getBatches();
  };

  return (
    <Box w="100%" h="100%" cursor="busy">
      <Box
        w="100%"
        h={["20vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px={"2.5rem"}
        py="1.5rem"
      >
        <Text type="header" color="white">
          Marketplace
        </Text>
        <Text fontSize={["0.9rem", "0.6rem"]} color="white">
          View batches of cars. Filter by category. Sort by make, model and
          year. Make offers on custom selections of cars from batches.
        </Text>
      </Box>
      <Skeleton
        mt={["-3.5rem"]}
        mx={"2.5rem"}
        d="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        isLoaded={!store.featuredLoading}
      >
        <Carousel>
          {store.featured.map((batch) => (
            <FeaturedBox
              key={batch.id}
              batch={batch}
              variant={batch.urgent ? "urgent" : batch.hot ? "hot" : ""}
            />
          ))}
        </Carousel>
      </Skeleton>
      <Box mx={"2.5rem"} mt="2rem">
        <Filters
          generalFilters={generalFilters}
          detailedFilters={detailedFilters}
          onSelectedDetailed={selectDetailedFilter}
          selectedGeneral={selectedFilter || "All Batches"}
          onSelectedGeneral={navigateToFilter}
        />
      </Box>
      <Skeleton mx={"2.5rem"} mt="2rem" isLoaded={!store.batchesLoading}>
        {store.batches.map((batch) => (
          <Batch key={batch.id} batch={batch} changePage={getBatches} />
        ))}
      </Skeleton>
      <Pagination pages={store.pages} currentPage={store.page} />
    </Box>
  );
};

export default Marketplace;
