import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import useFetchHook from "../../common/hooks/useFetchHook";
import { deals as dealsApi } from "../../api";
import { currencyFormat } from "../../common/utils";
import moment from "moment";

import { Box, Button, Grid, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Skeleton } from "@chakra-ui/core";

import { FaCar, FaCaretDown, FaCircle } from "react-icons/fa";
import Text from "../../common/Text";
import SalesRepresentative from "../../common/SalesRepresentative";
import FeaturedContainer from "../../common/FeaturedContainer";
import TagOutline from "../../common/TagOutline";
import TagCountDown from "../../common/TagCountDown";
import Filters from "../../common/Filters";
import CarRow from "../../common/CarRow";

import makes from "../../common/meta/makes.json";
import years from "../../common/meta/years.js";

import {
  dealsVariantsMapping as variantsMapping,
  dealsTitlesMapping as titlesMapping,
  dealsDescriptionsMapping as descriptionsMapping,
} from "../../common/mappings";

const DealDetails = ({ history, match }) => {
  const { dealId } = match.params;
  const [deal, dispatchDeal] = useFetchHook();
  const [vehicles, dispatchVehicles] = useFetchHook();

  const [detailedFilters, setDetailedFilters] = useState([
    { name: "Make", options: Object.keys(makes), selected: "" },
    { name: "Model", options: [], selected: "" },
    { name: "Year", options: years, selected: "" },
  ]);

  useEffect(function init() {
    getDeal();
    getVehicles();
  }, []);

  const getDeal = () => {
    dispatchDeal({ type: "pending" });
    dealsApi.getSingle(dealId).then((response) => {
      dispatchDeal({ type: "fulfilled", payload: response.object });
    });
  };

  const getVehicles = () => {
    const params = {
      make: detailedFilters[0].selected,
      model: detailedFilters[1].selected,
      year: detailedFilters[2].selected,
    };
    dispatchVehicles({ type: "pending" });
    dealsApi
      .getVehicles(dealId, params)
      .then((response) => {
        dispatchVehicles({ type: "fulfilled", payload: response });
      })
      .catch((err) => dispatchVehicles({ type: "rejected" }));
  };

  const updateStatus = (state) => {
    dispatchDeal({ type: "pending" });
    dealsApi.editDeal(deal.data.id, { state }).then((response) => {
      dispatchDeal({ type: "fulfilled", payload: response.object });
    })
  }

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    if (filterIndex === 0 && value.length > 0) {
      newDetailedFilter[1].options = makes[value].models;
    }
    setDetailedFilters(newDetailedFilter);
    getVehicles();
  };
  const type = deal.data.state || "in_progress";
  return (
    <Box>
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text type="header" color="white">
          Deal Details
        </Text>
        <Text
          as={Link}
          to={
            window.location.pathname.includes("dashboard")
              ? "/dashboard/deals"
              : "/deals"
          }
          fontSize={["1rem", "0.6rem"]}
          color="white"
        >
          ‹ Back to My Deals
        </Text>
      </Box>
      <Skeleton mx="2.5rem" mt="-3.5rem" isLoaded={!deal.loading}>
        <Grid templateColumns={["1fr", "7fr 3fr"]} columnGap="0.6rem">
          <Grid templateColumns={["1fr", "4fr 6fr"]} columnGap="0.6rem">
            <FeaturedContainer variant={variantsMapping[type]} minH="7rem" overflow="visible">
              <Box
                p="1rem"
                pb="1.5rem"
                d="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box d="flex" alignItems="center" position="relative">
                  <Text color={variantsMapping[type]} type="header">
                    {titlesMapping[type]}
                  </Text>
                  <ChangeDealStatusDropdown color={variantsMapping[type]} deal={deal} updateStatus={updateStatus} />
                </Box>
                <Text type="secondaryBodyLight" fontSize={["1rem", "0.6rem"]}>
                  Deal #{deal.data.id} {moment(deal.data.create_date).fromNow()}
                </Text>
                <Text type="header">
                  {deal.data.vehicle_deals?.length} vehicles /{" "}
                  {currencyFormat(deal.data.price)}
                </Text>
                <Text type="secondaryBodyLight" fontSize={["1rem", "0.6rem"]}>
                  {descriptionsMapping[type]}
                </Text>
              </Box>
            </FeaturedContainer>
            <FeaturedContainer minH="7rem">
              <Box
                py="0.8rem"
                px="1.5rem"
                d="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Text type="secondaryBodyLight" fontSize={["1rem", "0.6rem"]}>
                  Batch #{deal.data.batch?.id}
                </Text>
                <Text type="largeBodyBold" fontSize={["1.5rem", "0.8rem"]}>
                  {deal.data.batch?.title}
                </Text>
                <Box
                  color="text.500"
                  d="flex"
                  alignItems="center"
                  my={["1rem", 0]}
                >
                  <TagOutline text={deal.data.batch?.type} />
                  <Box ml="13px" as={FaCar} size={["1.2rem", "0.8rem"]}></Box>
                  <Text fontSize={["1rem", "0.6rem"]} ml="6px">
                    {deal.data.batch?.vehicles_preview.length} vehicles
                  </Text>
                </Box>
                {deal.data.batch && (
                  <TagCountDown
                    mt={2}
                    fromTime={new Date(deal.data.batch?.expiry_date)}
                  />
                )}
              </Box>
            </FeaturedContainer>
          </Grid>
          <SalesRepresentative representative={deal.data.batch?.dealer} />
        </Grid>
      </Skeleton>
      <Box
        background="white"
        mx="2.5rem"
        mt={["2rem", "0.6rem"]}
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
      >
        <Box
          d="flex"
          alignItems={["flex-start", "center"]}
          justifyContent="space-between"
          py="1.5rem"
          px={["1.5rem", 0]}
          flexDirection={["column", "row"]}
        >
          <Box w={["100%", "8rem"]} mx={[0, "1.5rem"]} mb={["1rem", 0]}>
            <Text
              fontWeight="bold"
              fontSize={["1.7rem", "0.95rem"]}
              color="text.500"
              lineHeight={["2rem", "1rem"]}
            >
              Deal Summary
            </Text>
            <Text
              fontWeight="light"
              fontSize={["0.9rem", "0.5rem"]}
              color="text.400"
              maxW="60%"
            >
              Showing {vehicles.list.length} vehicles
            </Text>
          </Box>
          <Filters
            hideGeneral={true}
            showSort={true}
            detailedFilters={detailedFilters}
            onSelectedDetailed={selectDetailedFilter}
          />
        </Box>
        <Grid mx="1.5rem" pb="1.5rem" rowGap="0.25rem" overflowY="scroll">
          {vehicles.list.map((vehicle, i) => (
            <CarRow
              car={vehicle.vehicle[0]}
              offerMade={vehicle.price}
              type="offer"
              showPrice
              noBorder={i == vehicles.length}
              disabled={vehicle.sold}
              index={i}
              key={i}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const ChangeDealStatusDropdown = ({ color, deal, updateStatus }) => {
  const initialFocusRef = useRef()

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="bottom-start"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button fontSize="1.5rem" variantColor="transparent" background="transparent" p="0" m="0"><Box color={color} as={FaCaretDown} /></Button>
      </PopoverTrigger>
      <PopoverContent
        w="auto"
        zIndex={4}
        color="white"
        bg="gray.100"
        borderColor="gray.100"
        boxShadow="0px 3px 15px #00000029"
      >
        <PopoverArrow />
        <PopoverBody>
          <ChangeDealOption text="In Progress" isSelected={deal.data.state === "in_progress"} onClick={() => updateStatus("in_progress")} />
          <Box w="100%" h="1px" backgroundColor="#dbdee3" my="0.3rem" />
          <ChangeDealOption text="Completed" isSelected={deal.data.state === "completed"} onClick={() => updateStatus("completed")} />
          <Box w="100%" h="1px" backgroundColor="#dbdee3" my="0.3rem" />
          <ChangeDealOption text="Canceled" isSelected={deal.data.state === "canceled"} onClick={() => updateStatus("canceled")} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

const ChangeDealOption = ({ text, isSelected, onClick }) => {
  return (
    <Box d="flex" alignItems="center" justifyContent="space-between" cursor="pointer" onClick={isSelected ? null : onClick}>
      <Text type={isSelected ? "secondaryBodyBold" : "secondaryBodyLight"}>{text}</Text>
      {isSelected && <Box ml="1rem" color="text.500"><FaCircle color="text.500" size="0.2rem" pl="1rem" /></Box>}
    </Box>
  )
}

export default DealDetails;
