import React from "react";
import { Box } from "@chakra-ui/core";

import { currencyFormat } from "./utils";
import Modal from "./Modal";
import Text from "./Text";
import { FaCheck } from "react-icons/fa";

const OfferSummaryModal = ({
  isModalOpen,
  toggleModal,
  offer,
  footerText,
  headerText,
  currentOffer,
  linkTo,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={toggleModal}
      showFooter
      footerText={footerText}
      linkTo={linkTo || `/offers/details/${offer.data.id}`}
    >
      <Box p="0.5rem">
        <Box
          w={[65, 85]}
          h={[65, 85]}
          borderRadius={85}
          bg="purple.500"
          d="flex"
          justifyContent="center"
          alignItems="center"
          m="auto"
          borderColor="#1ADDFF"
          borderWidth="3px"
        >
          <Box as={FaCheck} size="32px" color="white" bg="purple.500"></Box>
        </Box>
        <Text type="header" my="1.5rem" textAlign={["center", "left"]}>
          {headerText}
        </Text>
        <Text type="secondaryBodyLight" mb="1rem" fontSize={["1rem", "0.6rem"]}>
          Offer Summary
        </Text>
        {offer.list.map((vehicle) => (
          <Box
            key={vehicle.id}
            d="flex"
            justifyContent="space-between"
            borderBottomColor="gray.500"
            borderBottomWidth="1px"
          >
            <Text type="secondaryBodyBold" fontSize={["1rem", "0.6rem"]}>
              {vehicle.title}
            </Text>
            <Text
              type="secondaryBodyBold"
              color="purple.500"
              fontSize={["1rem", "0.6rem"]}
            >
              {currencyFormat(vehicle.offerMade)}
            </Text>
          </Box>
        ))}
        <Box
          width={["10rem", "7.2rem"]}
          height={["3rem", "1.92rem"]}
          borderRadius={["3rem", "1.92rem"]}
          backgroundColor="gray.500"
          d="flex"
          alignItems="center"
          justifyContent="center"
          m="auto"
          mt="1.5rem"
          boxShadow={["0px 3px 6px #00000029", "none"]}
        >
          <Text type="header" textAlign="center">
            {currencyFormat(currentOffer)}
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};

export default OfferSummaryModal;
