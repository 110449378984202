export default [
  {
    title: "General Conditions",
    subSections: [
      {
        title: "User Agreement",
        content:
          "The Owner of these General Terms and Conditions for SeezOne shall be Seez (add all the company details). The object of these Terms is to regulate the sale of movable property by the Owner, businesses, or private persons (hereafter referred to as the “Seller”) to persons who are also businesses (hereafter referred to as the “Buyer”). Sales shall be arranged via SeezOne Internet platform at seez.one. These Terms form the legal basis between SeezOne, the Seller and the Buyer (the “contracting parties”). Where reference to “vehicles” and “batches” is made in these Terms, they may also include other objects for sale via the platform. The current version of the following Terms shall also apply for future transactions between the contracting parties. Other general business terms shall not apply here, even if not explicitly contradicted.",
      },
      {
        title: "Exemption from Liability",
        content:
          "The User of the Internet platform shall release SeezOne from all claims that other Users or third parties may make against Seez, whether through violation of their rights in the offers and subject matter a User places on the website, or through his using the Seez website for other purposes. The User shall also bear the costs of any legal defence Seez may require, including all court and solicitor’s costs. This shall not apply if the User is not responsible for the infringement.",
      },
    ],
  },
  {
    title: "Registration, Use of the Internet Platform",
    subSections: [
      {
        title: "Registration Procedure",
        content:
          "A User must register to use all the services SeezOne offers. No-one is entitled to register at SeezOne. Registration is free and carried out by the User opening a user account and agreeing to these Terms. By registering, a contract on the use of the SeezOne Internet platform is concluded between SeezOne and the User. The data provided for SeezOne at registration must be accurate and complete. Should there be a change in user data after registration, the User shall be obliged to notify SeezOne of this change at once. Upon registration, the User shall select a User name and password. The User’s name may not violate third party rights, particularly not trade names or copyrights. The password must be kept confidential. The User shall be liable for the misuse of a User name or password. A User’s account is not transferable.",
      },
      {
        title: "Use of the Internet Platform",
        content:
          "When using SeezOne websites, the User is not entitled to employ any devices, soft-­ware or other practices likely to affect the functioning of the Internet platform. The User may not employ any measures likely to result in unreasonable or excessive infrastructure loads. Subject matter on the SeezOne Internet platform may not be copied, passed on, used or reproduced in any way without the owner’s prior permission. This shall also apply for “robot/crawler” search engine copies, “screen scraping” and other automatic devices. The layout of SeezOne websites may only be reproduced and/or used on other websites with SeezOne prior written permission.",
      },
      {
        title: "Inactivation of Accounts",
        content:
          "SeezOne may inactivate a user account at any time, especially in the case of violations against the general and particular Terms. As soon as a user account is inactivated, the User may no longer use the SeezOne website or re-register without SeezOne express permission.",
      },
      {
        title: "Termination of Contract",
        content:
          "The User may terminate his user contract at any time, without notice. Written notification or an e-mail to alessio@seez.co shall serve to state termination. The User is entitled to collect the refundable Deposit, whether a Deposit has been placed at the time of User activation.",
      },
    ],
  },
  {
    title: "Concluding a Contract in an Auction",
    subSections: [
      {
        title: "SeezOne Auction Conditions",
        content:
          "The Seller is entitled to decide how to run the auctions. These could be blind auctions, without any pricing disclosure, or with an explicit asking price on each vehicle, and batch. The auctioning of objects is prohibited where their tendering, sale, or purchase violates the statutory regulations or the rules of common decency. In particular, objects may not be described or put up for auction if their advertising, tendering or sale violates copyright and intellectual property rights, industrial property rights or any other rights (e.g. the right to one’s own image, name and personality).",
      },
      {
        title: "Auction Procedure",
        content:
          "The beginning, end and course of an auction shall be controlled by SeezOne and by its Sellers. SeezOne reserves the right to cancel or alter scheduled auctions. Only in exceptional cases shall SeezOne be entitled to invalidate auctions without given reason, without a legal claim ensuing on the User’s part. Invalidation may take place before or during an auction.",
      },
      {
        title: "Placing Items for Auction",
        content:
          "The Seller must place stocks of vehicles for auction in the appropriate category, with a correct and complete written and illustrated description. This shall include an accurate indication of all the essential properties and features required to make a purchase, and any defects likely to reduce the object’s value, i.e be a truthful statement of its actual condition. Furthermore, the Seller must give Users full information as to who owns the object under tender, the owner’s right to put it up for auction, and the details of payment and collection. The description and images used must not violate third party rights and must refer solely to the object under tender (original photographs). Descriptions, drawings and photographs may not contain any advertising for anything other than the object under tender, and especially not include links to a personal website, telephone numbers, etc. WhereSeezOne is the Seller, stocks being auctioned will be described by a contract partner or service provider, without SeezOne conducting a second appraisal.",
      },
      {
        title: "Placement",
        content:
          "The User may terminate his user contract at any time, without notice. Written notification or an e-mail to alessio@seez.co shall serve to state termination. The User is entitled to collect the refundable Deposit, whether a Deposit has been placed at the time of User activation.",
      },
    ],
  },
];
