import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/core";

import EnterEmail from "./EnterEmail";
import CreatePassword from "./CreatePassword";

import firebase from "../../common/firebase";

const AcceptInvitation = ({ history }) => {
  const [fields, setFields] = useState({ email: "", password: "" });
  const [paramData, setParamData] = useState({ dealer: {}, network: {} });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const data = params.get("data");
    setParamData(JSON.parse(atob(data)));
  }, []);

  const handleChange = (e) =>
    setFields({ ...fields, [e.target.name]: e.target.value });

  const createFirebaseAccount = () => {
    setIsLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(fields.email, fields.password)
      .then((response, error) => {
        setIsLoading(false);
        localStorage.setItem("firebaseUser", JSON.stringify(response.user));
        history.push(
          `/complete-profile/${paramData.dealer.id ? paramData.dealer.id : ""}`
        );
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "An error occured",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };
  console.log(paramData);
  return (
    <Switch>
      <Route
        exact
        path="/accept-invitation/password"
        render={() => (
          <CreatePassword
            handleChange={handleChange}
            password={fields.password}
            isLoading={isLoading}
            createFirebaseAccount={createFirebaseAccount}
            paramData={paramData}
          />
        )}
      />
      <Route
        exact
        path="/accept-invitation"
        render={() => (
          <EnterEmail
            email={fields.email}
            handleChange={handleChange}
            paramData={paramData}
          />
        )}
      />
    </Switch>
  );
};

export default AcceptInvitation;
