import React from "react";

import { Grid, Box, useDisclosure } from "@chakra-ui/core";

import LoginScreen from "./LoginScreen";
import Header from "./Header";
import ForgotPassword from "../forgotPassword/ForgotPassword";

const Login = () => {
  return (
    <Grid
      w="100vw"
      h={"100vh"}
      templateColumns={["1fr", "1fr", "5fr 4fr", "3fr 2fr", "2fr 1fr"]}
      overflowX="hidden"
    >
      <Box gridColumn="1/3">
        <LoginScreen />
      </Box>
    </Grid>
  );
};

export default Login;
