export default {
  email: {
    presence: true,
    email: {
      message: "not valid please provide a valid email.",
    },
  },
  password: {
    length: {
      minimum: 6,
    },
  },
};
