import React from "react";
import { Box, Input } from "@chakra-ui/core";

import Modal from "./Modal";
import Text from "./Text";
import Button from "./Button";
import { currencyFormat, mileageFormat } from "./utils";

const AddToOfferModal = ({
  isModalOpen,
  toggleModal,
  car,
  showPrice,
  currencySymbol,
  handleOfferChange,
  offer,
  onAddToOffer,
}) => {
  return (
    <Modal isOpen={isModalOpen} onClose={toggleModal}>
      <Box w={["100%", "80%"]} py="1rem" pb="2rem">
        <Text type="header" mb="0.7rem">
          Add to Offer
        </Text>
        <Text
          type="secondaryBodyLight"
          mb="0.5rem"
          fontSize={["1rem", "0.6rem"]}
        >
          Vehicle #{car.id}
        </Text>
        <Text type="header" mb="0.7rem" w="100%">
          {car.title}
        </Text>
        {
          showPrice && (
            <Text type="secondaryBodyBold" fontSize={["1rem", "0.6rem"]}>
              Price
            </Text>
          )
        }
        {
          showPrice && (
            <Text
              type="largeBodyBold"
              color="purple.500"
              mb="1.5rem"
              fontSize={["1rem", "0.6rem"]}
            >
              {currencyFormat(car.price, currencySymbol)}
            </Text>
          )
        }
        <Text type="bodyLight" fontSize={["1rem", "0.5rem"]}>
          Your offer for this vehicle
        </Text>
        <Box
          d="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexDirection={["column", "row"]}
        >
          <Input
            height={["46px", "1.92rem"]}
            width={["70%", "7.2rem"]}
            fontSize={["1rem", "0.7rem"]}
            borderRadius={"1.92rem"}
            backgroundColor="gray.500"
            placeholder="-"
            textAlign="center"
            _placeholder={{ color: "black", fontWeight: "bold" }}
            _focus={{ border: "none" }}
            value={offer}
            onChange={handleOfferChange}
            type="number"
          />
          <Button
            ml={[0, "1rem"]}
            mt={["1rem", 0]}
            onClick={onAddToOffer}
            disabled={offer < 1}
            w={["70%", "auto"]}
          >
            Add to Offer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddToOfferModal;
