import { headers, authHeaders } from "./headers";
import firebase from "../common/firebase";

export const BASE_URL = "https://test-api.seez-cdn.co/one";

export const toUrlParams = (params = {}) => {
  const result = [];
  Object.keys(params).forEach((param) => {
    if (params[param]) result.push(`${param}=${params[param]}`);
  });
  return result.length > 0 ? `?${result.join("&")}` : "";
};

const toFormData = (data) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(
      name,
      data[name],
      data[name].name || +new Date() + "_image"
    );
  }

  return formData;
};

const handleResponse = async (res) => {
  if (!res.ok) {
    const err = await res.clone().json();
    throw err;
  }
  const response = await res.json();
  return response;
};

const checkToken = () =>
  new Promise((resolve, reject) => {
    let tokenCreatedAt = localStorage.getItem("tokenCreatedAt");
    let now = new Date();
    tokenCreatedAt = new Date(tokenCreatedAt);
    // refresh every 45 minutes
    if (firebase.auth().currentUser && now - tokenCreatedAt > 45 * 60 * 1000) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          localStorage.setItem("idToken", idToken);
          localStorage.setItem("tokenCreatedAt", new Date());
          resolve(idToken);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      resolve(localStorage.getItem("idToken"));
    }
  });

export const get = async (url, { isAuth = false }) => {
  if (isAuth) {
    const token = await checkToken();
    return fetch(`${BASE_URL}/${url}`, {
      headers: authHeaders(token),
    }).then((res) => {
      return handleResponse(res);
    });
  } else {
    return fetch(`${BASE_URL}/${url}`, {
      headers,
    }).then((res) => {
      return handleResponse(res);
    });
  }
};

export const post = async (
  url,
  { data, isAuth = false, isFormData = false }
) => {
  if (isAuth) {
    const token = await checkToken();
    return fetch(`${BASE_URL}/${url}`, {
      method: "POST",
      headers: authHeaders(token, isFormData),
      body: isFormData ? toFormData(data) : JSON.stringify(data),
    }).then((res) => {
      return handleResponse(res);
    });
  } else {
    return fetch(`${BASE_URL}/${url}`, {
      method: "POST",
      headers: headers(isFormData),
      body: isFormData ? toFormData(data) : JSON.stringify(data),
    })
      .then((res) => {
        return handleResponse(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const head = async (
  url,
  { data, isAuth = false, isFormData = false }
) => {
  if (isAuth) {
    const token = await checkToken();
    return fetch(`${BASE_URL}/${url}`, {
      method: "HEAD",
      headers: authHeaders(token, isFormData),
      body: isFormData ? toFormData(data) : JSON.stringify(data),
    }).then((res) => {
      return res.headers;
    });
  } else {
    return fetch(`${BASE_URL}/${url}`, {
      method: "HEAD",
      headers: headers(isFormData),
      body: isFormData ? toFormData(data) : JSON.stringify(data),
    })
      .then((res) => {
        return res.headers;
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const patch = async (url, { data, isAuth = false }) => {
  if (isAuth) {
    const token = await checkToken();
    return fetch(`${BASE_URL}/${url}`, {
      method: "PATCH",
      headers: authHeaders(token),
      body: JSON.stringify(data),
    })
      .then((res) => {
        return handleResponse(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    return fetch(`${BASE_URL}/${url}`, {
      method: "PATCH",
      headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return handleResponse(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const del = async (url, { data, isAuth = false }) => {
  if (isAuth) {
    const token = await checkToken();
    return fetch(`${BASE_URL}/${url}`, {
      method: "DELETE",
      headers: authHeaders(token),
      body: JSON.stringify(data),
    }).then((res) => {
      return handleResponse(res);
    });
  } else {
    return fetch(`${BASE_URL}/${url}`, {
      method: "DELETE",
      headers,
      body: JSON.stringify(data),
    }).then((res) => {
      return handleResponse(res);
    });
  }
};

export const fileUpload = async (file, path, { isAuth = false }) => {
  var formdata = new FormData();
  formdata.append("file", file, file.name);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  if (isAuth) {
    const token = await checkToken();
    requestOptions.headers = authHeaders(token, true)
  }

  return fetch(`${BASE_URL}/${path}`, requestOptions)
    .then((res) => {
      return handleResponse(res);
    })
    .catch((err) => {
      throw err
    });
};
