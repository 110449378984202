import React, { useEffect, Fragment } from "react";
import { Box, Grid, Skeleton } from "@chakra-ui/core";

import Text from "../../common/Text";
import FeaturedContainer from "../../common/FeaturedContainer";
import UserTag from "../../common/UserTag";

import { getActivities } from "../../actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment"
import { currencyFormat } from "../../common/utils";

const ActivityDay = ({ month, description, activities }) => {
  return <Box mt="1.3rem">
    <Box d="flex">
      <Text type="secondaryBodyBold" mr="1rem">{month}</Text>
      <Text type="secondaryBodyLight">{description}</Text>
    </Box>
    <Grid templateColumns={["1fr", "1fr 1fr"]} columnGap="0.5rem">
      {
        activities.map(activity => (
          <FeaturedContainer key={activity.id} py="0.5rem" d="flex" justifyContent="space-between" alignItems="center">
            {console.log(activity)}
            <Text ml="1rem" type="bodyLight" color="purple.400">{moment(activity.create_date).format("DD MMM YYYY")}</Text>
            <UserTag name={activity.user?.fullname} company={activity.user?.company} image={activity.user?.image?.url} />
            <Text mr='3rem' type="secondaryBodyBold">{activity.num_vehicles} vehicle{activity.num_vehicles > 1 ? "s" : ""} / {currencyFormat(activity.price)}</Text>
          </FeaturedContainer>
        ))
      }

    </Grid>
  </Box>
}

const ActivityLog = () => {
  const dispatch = useDispatch();
  const activities = useSelector(state => state.dashboard.activities)
  useEffect(() => {
    if (!activities || !activities.length)
      dispatch(getActivities())
  }, [])
  const isLoading = useSelector(state => state.dashboard.loading)

  return (
    <Box mt="1.5rem">
      <Box>
        <Text type="header">Activity Log</Text>
        <Text type="bodyLight">
          See all confirmed deals happening outside your network made by buyers
          that you introduced to the SeezOne global marketplace
        </Text>
      </Box>
      <Box>
        {
          isLoading && <Fragment>
            <Skeleton isLoaded={false} h="3rem"></Skeleton>
          </Fragment>
        }
        {
          (activities && activities.length) ? activities.map(activity => <ActivityDay key={activity.date_} month={moment(activity.date).format("MMM YYYY")} description={`${activity.num_vehicles} vehicle${activity.num_vehicle > 1 ? "s" : ""} / ${currencyFormat(activity.price)}`} activities={activity.activities} />) : null
        }
      </Box>
    </Box>
  );
};

export default ActivityLog;