import React, { useEffect, useState } from "react";

import { Box, Text } from "@chakra-ui/core";

import { MdTimer } from "react-icons/md";

const TagCountDown = ({ fromTime, showExpiresText, isNotPosted, ...other }) => {
  const [timeLeft, setTimeLeft] = useState("");
  let interval;
  useEffect(() => {
    calculateRemainingTime();
    interval = setInterval(() => {
      calculateRemainingTime();
    }, 10000);
    return clearInterval(interval);
  }, []);

  const calculateRemainingTime = () => {
    let diff = (new Date(fromTime) - new Date()) / 1000;
    // get total seconds between the times
    if (diff < 0) {
      setTimeLeft("Batch Expired");
      return;
    }

    // calculate (and subtract) whole days
    const days = Math.floor(diff / 86400);
    diff -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(diff / 3600) % 24;
    diff -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(diff / 60) % 60;
    diff -= minutes * 60;

    let weeks = Number(days / 7).toFixed();

    if (weeks > 0) return setTimeLeft(`${weeks} Week${weeks > 1 ? "s" : ""}`);

    let timeString = days + " day" + (days > 1 ? "s " : " ");
    timeString += hours + " hour" + (hours > 1 ? "s " : " ");
    timeString += minutes + " min" + (minutes > 1 ? "s " : " ");

    setTimeLeft(timeString);
  };

  return (
    <Box
      borderRadius="1.4rem"
      bg="gray.400"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      w="fit-content"
      px="1rem"
      py="0.4rem"
      {...other}
    >
      <Box as={MdTimer} color="text.500" size="0.9rem" mr="0.5rem"></Box>
      <Box>
        {showExpiresText && (
          <Text
            fontSize="0.6rem"
            lineHeight="0.6rem"
            fontWeight="light"
            color="text.400"
          >
            Batch Expires
          </Text>
        )}
        <Text
          fontWeight="bold"
          lineHeight={["0.9rem", "0.7rem"]}
          fontSize={["0.9rem", "0.7rem"]}
          color="text.500"
        >
          {isNotPosted && timeLeft !== "Batch Expired"
            ? `Not Posted (${timeLeft})`
            : timeLeft}
        </Text>
      </Box>
    </Box>
  );
};

export default TagCountDown;
