import React from "react";
import { Box } from "@chakra-ui/core";
import Text from "../../common/Text";

const Stepper = ({ step, progress }) => {
  return (
    <>
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        px={["1rem", "2rem"]}
        pt={8}
      >
        <Text
          type="largeBodyRegular"
          color={step >= 1 ? "purple.500" : "#384364"}
        >
          Your Profile
        </Text>
        <Text
          type="largeBodyRegular"
          color={step === 2 ? "purple.500" : "#384364"}
          opacity={step === 2 ? "1" : "0.2"}
        >
          Business Information
        </Text>
        <Text type="largeBodyRegular" mr="1rem" color="#384364" opacity="0.2">
          Done
        </Text>
      </Box>

      <Box d="flex" justifyContent="center" alignItems="center" px="3rem">
        <Box bg="purple.500" h="1rem" w="1rem" borderRadius={100}></Box>

        <Box h="0.2rem" w="42.5%" d="flex" justifyContent="flex-start">
          <Box
            h="100%"
            bg="purple.500"
            w={step === 1 ? `${progress}%` : "100%"}
          ></Box>
          <Box
            h="100%"
            bg="surface"
            w={step === 1 ? `${100 - progress}%` : "0%"}
          ></Box>
        </Box>

        <Box
          bg={step === 2 ? "purple.500" : "surface"}
          h="1rem"
          w="1rem"
          borderRadius={100}
        ></Box>

        <Box h="0.2rem" w="42.5%" d="flex" justifyContent="flex-start">
          <Box
            h="100%"
            bg="purple.500"
            w={step === 2 ? `${progress}%` : "0%"}
          ></Box>
          <Box
            h="100%"
            bg="surface"
            w={step === 2 ? `${100 - progress}%` : "100%"}
          ></Box>
        </Box>

        <Box
          bg={step > 2 ? "purple.500" : "surface"}
          h="1rem"
          w="1rem"
          borderRadius={100}
        ></Box>
      </Box>
    </>
  );
};

export default Stepper;
