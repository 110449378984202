import typeToReducer from "type-to-reducer";
import {
  GET_CURRENCIES
} from "../constants";

const initalState = {
  currencies: [],
};

const reducer = typeToReducer(
  {
    [GET_CURRENCIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        currencies: action.payload ? action.payload.objects : [],
      }),
    },
  },
  initalState
);

export default reducer;
