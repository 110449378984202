import React from "react";
import { Switch, Route } from "react-router-dom";

import MyDashboard from "./MyDashboard";

import BatchForm from "../myBatches/BatchForm";
import MyBatchDetails from "../myBatches/MyBatchDetails";
import AddVehicle from "../myBatches/AddVehicle";
import MultipleVehicleUpload from "../myBatches/MultipleVehicleUpload";
import VehicleDetails from "../vehicleDetails/VehicleDetails";

import MyOfferDetails from "../myOffers/MyOffersRouter";
import DealDetails from "../deals/DealDetails";

const MyDashboardRouter = () => {
  return (
    <Switch>
      <Route path="/dashboard/deals/details/:dealId" component={DealDetails} />
      <Route
        path="/dashboard/offer/details/:offerId"
        component={MyOfferDetails}
      />
      <Route
        path="/dashboard/batches/:batchId/vehicles/:vehicleId/edit"
        component={AddVehicle}
      />
      <Route
        path="/dashboard/batches/:batchId/vehicles/:vehicleId"
        component={VehicleDetails}
      />
      <Route
        path="/dashboard/batches/:batchId/multiple-vehicle-upload"
        component={MultipleVehicleUpload}
      />
      <Route
        path="/dashboard/batches/:batchId/add-vehicle"
        component={AddVehicle}
      />
      <Route
        path="/dashboard/batches/:batchId/edit-batch"
        component={BatchForm}
      />
      <Route path="/dashboard/batches/:batchId" component={MyBatchDetails} />
      <Route path="/dashboard/create-batch" component={BatchForm} />
      <Route path="/dashboard" component={MyDashboard} />
    </Switch>
  );
};

export default MyDashboardRouter;
