import React from "react";
import { Switch, Route } from "react-router-dom";

import DealsList from "./DealsList";
import DealDetails from "./DealDetails";
import VehicleDetails from "../vehicleDetails/VehicleDetails";

const DealsRouter = () => {
  return (
    <Switch>
      <Route
        path="/deals/details/:id/vehicle/:vehicleId"
        component={VehicleDetails}
      />
      <Route path="/deals/details/:dealId" component={DealDetails} />
      <Route path="/deals" component={DealsList} />
    </Switch>
  );
};

export default DealsRouter;
