import React from "react";

import { Box, Text, Image } from "@chakra-ui/core";

import logo from "../../assets/images/logo.png";

const Header = () => {
  return (
    <Box
      d={["flex", "block"]}
      alignItems="center"
      justifyContent="space-between"
      pt={["1rem", "1.5rem"]}
      h={["5rem", "7rem"]}
      w="100%"
    >
      <Image w="8rem" src={logo}></Image>
      <Box w={["12rem", "9rem"]} ml={["2rem", "0"]}>
        <Text
          fontWeight={"bold"}
          fontSize={["0.9rem", "0.7rem"]}
          lineHeight={1.2}
          color="white"
          mt={["0", "1rem"]}
        >
          The global digital marketplace for wholesale vehicle trading
        </Text>
      </Box>
    </Box>
  );
};

export default Header;
