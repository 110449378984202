import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/core";

import Text from "../../common/Text";
import Button from "../../common/Button";

import { FaPlus, FaPaperclip } from "react-icons/fa";
import { csvUpload } from "../../api";

const MultipleVehicleUpload = ({ history, match }) => {
  const { batchId } = match.params

  const toast = useToast();

  const uploadSingle = () =>
    history.push("add-vehicle");

  const [csv, setCsv] = useState()

  const inputAttachment = useRef(null);
  const onAttachmentUploadClick = () => {
    inputAttachment.current.click();
  };

  const onAttachmentUpload = (e) => {
    let file = e?.target?.files[0];
    setCsv(file);
    console.log(file);
  };

  const onCancel = () => {
    history.push("vehicles");
  }

  const onAddVehicles = () => {
    csvUpload(batchId, csv).then(onCancel).catch(error => {
      toast({
        position: "top-right",
        title: "An error occured",
        description: error.message || error.detail,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    })
  }

  return (
    <Box w="100%" h="100%">
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px={["1rem", "2.5rem"]}
        py="1.5rem"
      >
        <Text type="header" color="white">
          Add Vehicles
        </Text>
        <Text
          as={Link}
          to={`vehicles`}
          fontSize={"0.6rem"}
          color="white"
        >
          ‹ Back to Batch Details
        </Text>
      </Box>
      <Box w={["100%", "24rem"]} mx="auto" mt="-2rem">
        <Box
          boxShadow="0px 3px 15px #0000000D"
          borderRadius="10px"
          p="2rem"
          bg="white"
        >
          <Text type="header">Add Vehicles</Text>
          <Box
            h="1.92rem"
            w="100%"
            borderRadius="22px"
            backgroundColor="gray.500"
            my="1rem"
            d="flex"
            justifyContent="space-between"
          >
            <Button
              backgroundColor="gray.500"
              color="#3843644D"
              _focus={{ backgroundColor: "gray.500" }}
              onClick={uploadSingle}
              pl="1.5rem"
              variantColor="gray"
            >
              Single Vehicle Upload
            </Button>
            <Button>Multiple Vehicle Upload</Button>
          </Box>
          <Box>
            <Text type="secondaryBodyBold">Select a file upload</Text>
            <Text type="bodyLight">
              File should be in Excel or CSV format and follow the SeezOne
              vehicle upload guidelines.
            </Text>
          </Box>
          <Box d="flex" mt="1rem">
            <Box
              borderColor="text.500"
              borderBottomWidth="1px"
              height="1.1rem"
              fontSize="0.6rem"
              paddingBottom="0.4rem"
              paddingX="0.4rem"
              cursor="pointer"
              flex={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mr="1rem"
              onClick={onAttachmentUploadClick}

            >
              <Text type="secondaryBodyRegular">{csv?.name}</Text>
              <Box as={FaPlus} float="right" color="gray.700" />
              <input
                onChange={onAttachmentUpload}
                multiple
                type="file"
                id="file"
                ref={inputAttachment}
                style={{ display: "none" }}
              />
            </Box>
            <Box flex={1} d="flex">
              <Box as={FaPaperclip} color="purple.500" />
              <Text type="largeBodyBold" color="purple.500" ml="0.2rem">
                Vehicle Upload Template
              </Text>
            </Box>
          </Box>
          <Box mx="auto" mt="2rem" d="flex" justifyContent="center">
            <Button disabled={!csv?.name} onClick={onAddVehicles}>Add Vehicles</Button>
            <Button
              backgroundColor="gray.500"
              color="white"
              ml="1rem"
              _focus={{ backgroundColor: "gray.400" }}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleVehicleUpload;
