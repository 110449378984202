import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  setUser,
  setToken,
  getAndSetToken,
  getLoggedInUser,
} from "./actions/authActions";

import { ThemeProvider, CSSReset } from "@chakra-ui/core";

import { Switch } from "react-router-dom";
import PrivateRoute from "./common/routes/PrivateRoute";
import PublicRoute from "./common/routes/PublicRoute";

import Login from "./app/login/Login";
import CompleteProfile from "./app/createAccount/CompleteProfile";
import CreateAccount from "./app/createAccount/CreateAccount";
import AcceptInvitation from "./app/acceptInvitation/AcceptInvitation";
import Welcome from "./app/createAccount/Welcome";
import LandingPage from "./app/landingPage/LandingPage";
import Faq from "./app/landingPage/Faq";

import Dashboard from "./app/dashboard/Dashboard";

import firebase from "./common/firebase";

import theme from "./theme";
import ForgotPassword from "./app/forgotPassword/ForgotPassword";
import "react-datepicker/dist/react-datepicker.css";
import TermsConditions from "./app/landingPage/TermsConditions";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  useEffect(function listenToFirebaseAuthChange() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setIsLoggedIn(true);
        localStorage.setItem("firebaseUser", JSON.stringify(user));
        dispatch(setUser({ firebase_uid: user.uid }));
        dispatch(getAndSetToken(true));
        dispatch(getLoggedInUser());
      } else {
        localStorage.removeItem("firebaseUser");
        setIsLoggedIn(false);
        dispatch(setToken(null));
        dispatch(setUser({}));
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Switch>
        <PublicRoute exact path="/" component={LandingPage} />
        <PublicRoute exact path="/faq" component={Faq} />
        <PublicRoute
          exact
          path="/terms-conditions"
          component={TermsConditions}
        />
        <PublicRoute exact path="/login" component={Login} restricted />
        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          restricted
        />
        <PublicRoute
          path="/accept-invitation"
          component={AcceptInvitation}
          restricted
        />
        <PublicRoute
          path="/create-account"
          component={CreateAccount}
          restricted
        />
        <PrivateRoute
          exact
          path="/complete-profile/:inviter_id?"
          component={CompleteProfile}
          restricted
        />
        <PrivateRoute path="/" component={Dashboard} restricted />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
