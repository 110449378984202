import { createStore, applyMiddleware, compose } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const middleware = applyMiddleware(promise, thunk);

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default function configureStore(initialState = {}) {
  return createStore(rootReducer, initialState, composeEnhancers(middleware));
}
