import {
  GET_CURRENCIES,
} from "../constants";

import { statics } from "../api";

export const getCurrencies = () => ({
  type: GET_CURRENCIES,
  payload: statics.currencies(),
});
