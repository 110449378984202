import React from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/core";

import Text from "./Text";
import { FaRegTimesCircle } from "react-icons/fa";

const CustomModal = ({
  onClose,
  isOpen,
  showFooter,
  footerText,
  linkTo,
  hideCloseButton,
  onFooterClick,
  ...props
}) => {
  return (
    <Modal onClose={onClose} size={["sm", "xs"]} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="10px">
        {onClose && !hideCloseButton && (
          <ModalCloseButton
            as={FaRegTimesCircle}
            h="1rem"
            w="1rem"
            color="text.500"
            opacity={0.25}
          />
        )}
        <ModalBody>{props.children}</ModalBody>
        {showFooter && (
          <ModalFooter
            borderBottomRightRadius="10px"
            borderBottomLeftRadius="10px"
            backgroundColor="gray.500"
            minHeight="2.5rem"
            justifyContent="center"
          >
            <Text
              type="secondaryBodyBold"
              color="purple.500"
              fontSize={["1.2rem", "0.6rem"]}
              as={Link}
              to={linkTo}
              onClick={onFooterClick}
            >
              {footerText}
            </Text>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
