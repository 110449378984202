import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOffer } from "../../actions/marketplaceActions";

import { Box, Grid, Skeleton, useToast } from "@chakra-ui/core";
import { Link } from "react-router-dom";

import { batches as batchesApi } from "../../api";

import useFetchHook from "../../common/hooks/useFetchHook";
import { currencyFormat } from "../../common/utils";

import OfferSummaryModal from "../../common/OfferSummaryModal";
import Text from "../../common/Text";
import BatchInfo from "../../common/BatchInfo";
import Button from "../../common/Button";
import Filters from "../../common/Filters";
import CarRow from "../../common/CarRow";
import SelectAllModal from "./SelectAllModal";

import makes from "../../common/meta/makes.json";
import years from "../../common/meta/years.js";
import specs from "../../common/meta/specs.json";

const BatchDetails = ({ history, match }) => {
  const { batchId } = match.params;
  const toast = useToast();
  const dispatch = useDispatch();

  const [batch, dispatchBatch] = useFetchHook();
  const [vehicles, dispatchVehicles] = useFetchHook();
  const [offer, dispatchOffer] = useFetchHook();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectAllOpened, setIsSelectAllOpened] = useState(false);
  const _currentOffer = useSelector((state) => state.marketplace.currentOffer);

  const [detailedFilters, setDetailedFilters] = useState([
    { name: "Make", options: Object.keys(makes), selected: "" },
    { name: "Model", options: [], selected: "" },
    { name: "Year", options: years, selected: "" },
    { name: "Specs", options: specs, selected: "" },
  ]);

  useEffect(function init() {
    getBatch();
    getVehicles();
    checkForOffer();
  }, []);

  const checkForOffer = () => {
    if (_currentOffer.batchId === batchId) {
      dispatchOffer({ type: "set", payload: _currentOffer.offer });
    }
  };

  const getBatch = () => {
    dispatchBatch({ type: "pending" });
    batchesApi
      .getSingle(batchId)
      .then((response) => {
        if (response)
          dispatchBatch({ type: "fulfilled", payload: response.object });
      })
      .catch((err) => {
        dispatchBatch({ type: "rejected" });
      });
  };

  const getVehicles = () => {
    const params = {
      make: detailedFilters[0].selected,
      model: detailedFilters[1].selected,
      year: detailedFilters[2].selected,
      regional_specs: detailedFilters[3].selected.toLowerCase(),
    };
    dispatchVehicles({ type: "pending" });
    batchesApi
      .getVehicles(batchId, params)
      .then((response) => {
        if (response) {
          if (_currentOffer.batchId === batchId) {
            _currentOffer.offer.forEach((offerVehicle) => {
              const index = response.objects.findIndex(
                (vehicle) => vehicle.id === offerVehicle.id
              );
              response.objects[index] = offerVehicle;
            });
          }
          dispatchVehicles({ type: "fulfilled", payload: response });
        }
      })
      .catch((err) => dispatchVehicles({ type: "rejected" }));
  };

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    if (filterIndex === 0 && value.length > 0) {
      newDetailedFilter[1].options = makes[value].models;
    }
    setDetailedFilters(newDetailedFilter);
    getVehicles();
  };

  const addToOffer = (index, offerMade) => {
    const _vehicles = [...vehicles.list];
    _vehicles[index].offerMade = offerMade;
    const vehicle = _vehicles[index];
    const _offer = [...offer.list];
    _offer.push(vehicle);
    dispatchVehicles({ type: "set", payload: _vehicles });
    dispatchOffer({ type: "set", payload: _offer });
    dispatch(setCurrentOffer({ batchId, offer: _offer }));
  };

  const addAllToOffer = () => {
    const _vehicles = vehicles.list.map(vehicle => ({ ...vehicle, isSelected: true }))
    dispatchVehicles({ type: "set", payload: _vehicles });
    dispatchOffer({ type: "set", payload: _vehicles });
    dispatch(setCurrentOffer({ batchId, offer: _vehicles }));
  };

  const removeFromOffer = (index) => {
    const _vehicles = [...vehicles.list];
    delete _vehicles[index].offerMade;
    const vehicle = _vehicles[index];
    const _offer = [...offer.list];
    const offerIndex = _offer.findIndex(
      (offerVehicle) => offerVehicle.id == vehicle.id
    );
    _offer.splice(offerIndex, 1);
    dispatchVehicles({ type: "set", payload: _vehicles });
    dispatchOffer({ type: "set", payload: _offer });
    dispatch(setCurrentOffer({ batchId, offer: _offer }));
  };

  const createOffer = () => {
    dispatchOffer({ type: "pending" });
    const vehcileOffers = offer.list.map((vehicle) => ({
      vehicle_ids: [vehicle.id],
      price: vehicle.offerMade,
    }));

    console.log("HJER");

    batchesApi
      .createOffer(batchId, vehcileOffers)
      .then((response) => {
        dispatchOffer({ type: "set_data", payload: response.object });
        toggleModal();
      })
      .catch((err) => {
        dispatchOffer({ type: "rejected" });
        toast({
          position: "top-right",
          title: "An error occured",
          description: err.detail,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    clearCurrentOffer();
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const calculateCurrentOffer = () => {
    let value = 0;
    offer.list.forEach((vehicle) => {
      value += Number(vehicle.offerMade);
    });
    return value;
  };

  const clearCurrentOffer = () => {
    dispatch(setCurrentOffer({}));
  };

  const currentOffer = calculateCurrentOffer();

  const toggleSelectAll = () => {
    setIsSelectAllOpened(!isSelectAllOpened);
  }

  const sendOfferAllVehicles = (price) => {
    dispatchOffer({ type: "pending" });
    const vehcileOffers = offer.list.map((vehicle) => ({
      vehicle_ids: vehicles.list.map(v => v.id),
      price
    }));

    batchesApi
      .createOffer(batchId, vehcileOffers)
      .then((response) => {
        dispatchOffer({ type: "set_data", payload: response.object });
        toggleModal();
      })
      .catch((err) => {
        dispatchOffer({ type: "rejected" });
        toast({
          position: "top-right",
          title: "An error occured",
          description: err.detail,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    clearCurrentOffer();
  }

  return (
    <Box w="100%" h="100%">
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text fontSize={["1.6rem", "0.95rem"]} fontWeight="bold" color="white">
          Batch Details
        </Text>
        <Text
          as={Link}
          to="/marketplace"
          onClick={clearCurrentOffer}
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
        >
          ‹ Back to Marketplace
        </Text>
      </Box>
      <Skeleton isLoaded={!batch.loading}>
        <BatchInfo
          variant={batch.data.urgent ? "urgent" : batch.data.hot ? "hot" : ""}
          mt={"-3.5rem"}
          mx="2.5rem"
          isOwner={false}
          batch={batch.data}
        />
      </Skeleton>
      <Box
        background="white"
        mx={"2.5rem"}
        mt="0.6rem"
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
        d="flex"
        alignItems={["flex-start", "center"]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <Box w={["80%", "50%"]} m="1rem">
          <Text
            fontWeight="bold"
            fontSize={["1.5rem", "0.95rem"]}
            color="text.500"
            lineHeight={["2rem", "1rem"]}
          >
            Make an Offer
          </Text>
          <Text
            fontWeight="light"
            fontSize={["0.9rem", "0.5rem"]}
            color="text.400"
            maxW={["", "60%"]}
          >
            Add vehicles from this batch to make an offer on your custom
            selection.
          </Text>
        </Box>
        <Box
          d="flex"
          mr={["2rem", "1rem"]}
          pb={["1rem", "0"]}
          alignSelf={["center", ""]}
          justifyContent="flex-end"
          alignItems={["flex-end", "center"]}
          flexDirection={["column", "row"]}
          width="100%"
        >
          {offer.list.length > 0 && (
            <Text
              type="largeBodyRegular"
              mr={[0, "1rem"]}
              width={["181px", "auto"]}
              textAlign="center"
              fontSize={["1rem", "0.7rem"]}
              mb={["0.5rem", 0]}
            >
              {offer.list.length} Vehicles
            </Text>
          )}
          <Button variantColor={"gray"} mr={[0, "2.5rem"]} boxShadow="none">
            {currentOffer > 0 ? currencyFormat(currentOffer) : "-/-"}
          </Button>
          <Button
            disabled={currentOffer == 0}
            onClick={createOffer}
            isLoading={offer.loading}
            mt={["1rem", 0]}
          >
            Make offer
          </Button>
        </Box>
      </Box>
      <Box
        background="white"
        mx={"2.5rem"}
        mt="0.6rem"
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
      >
        <Box
          d="flex"
          alignItems={["flex-start", "center"]}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box w={["100%", "60%"]} my="1.5rem" mx="1rem" mb={[0, "1.5rem"]}>
            <Text
              fontWeight="bold"
              fontSize={["1.5rem", "0.95rem"]}
              color="text.500"
              lineHeight={["2rem", "1rem"]}
            >
              Batch Summary
            </Text>
            <Text
              fontWeight="light"
              fontSize={["0.9rem", "0.5rem"]}
              color="text.400"
              maxW="60%"
            >
              Showing {batch.data.num_vehicles} vehicles
            </Text>
          </Box>
          <Box
            d={["initial", "flex"]}
            mx={["1rem", 0]}
            // w={["100%", undefined]}
            justifyContent="flex-end"
          >
            <Filters
              hideGeneral={true}
              showSort={true}
              detailedFilters={detailedFilters}
              onSelectedDetailed={selectDetailedFilter}
            />
            <Button
              disabled
              variant="outline"
              mr={["2rem", "1rem"]}
              my={["1rem", "none"]}
              width={["100px", "auto"]}
              height={["35px", "1.92rem"]}
              float={["right", undefined]}
              boxShadow={["none"]}
              onClick={toggleSelectAll}
            >
              Select all
            </Button>
          </Box>
        </Box>
        <Skeleton
          as={Grid}
          mx="1rem"
          rowGap="0.25rem"
          overflowY="scroll"
          isLoaded={!vehicles.loading}
        >
          {vehicles.list.map((vehicle, i) => (
            <CarRow
              showPrice={batch.data.show_prices}
              noBorder={i == vehicles.length}
              disabled={vehicle.sold}
              addToOffer={addToOffer}
              removeFromOffer={removeFromOffer}
              offerMade={vehicle.offerMade}
              index={i}
              key={i}
              car={vehicle}
              linkTo={`${batchId}/vehicle/${vehicle.id}`}
            />
          ))}
        </Skeleton>
      </Box>
      <OfferSummaryModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        currentOffer={currentOffer}
        footerText="Continue to Offer Details ›"
        headerText="Your offer has been sent to the seller"
        offer={offer}
      />
      <SelectAllModal
        isModalOpen={isSelectAllOpened}
        toggleModal={toggleSelectAll}
        vehicles={vehicles?.list?.length}
        batchId={batchId}
        onSubmitOffer={sendOfferAllVehicles}
      />
    </Box>
  );
};

export default BatchDetails;
