import React, { useState, useEffect } from "react";

import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  FormHelperText,
  FormControl,
} from "@chakra-ui/core";

import Text from "../../common/Text";
import Button from "../../common/Button";
import copy from "copy-to-clipboard";
import { myDashboard } from "../../api";
import { FaCheckCircle } from "react-icons/fa";

import Modal from "../../common/Modal";

const InviteDealers = ({ isOpen, onClose }) => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [copied, setCopied] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [invitationUrl, setInvitationUrl] = useState("");

  useEffect(() => {
    myDashboard.getInvitationsUrl().then(res => {
      setInvitationUrl(res.object)
    })
  }, [])

  const onAddEmail = (e) => {
    e && e.preventDefault();
    if (email.trim().length) {
      setEmails([...emails, email.trim()]);
      setEmail("");
    }
  };

  const onRemoveEmail = (index) => {
    let _emails = [...emails];
    _emails.splice(index, 1);
    setEmails(_emails);
  };

  const onChangeEmail = (e) => {
    let value = e.target.value;
    if (" ,".includes(value.slice(-1)) && value.length > 3) {
      onAddEmail();
    } else {
      setEmail(e.target.value);
    }
  };

  function isValidEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  const copyToClipboard = () => {
    copy(invitationUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false)
    }, 5000);
  }

  const sendInvitations = () => {
    myDashboard.sendInvitations(emails).then((res) => {
      setIsSent(true);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} showFooter={isSent} footerText="Back to My Dashboard ›" hideCloseButton={true}>
      {
        !isSent
          ? <Box p={["0.5rem"]} pt={"1rem"}>
            <Text type="header">Invite Dealers to Network</Text>
            <Text type="secondaryBodyBold" mt={["1.6rem"]}>Share URL</Text>
            <Text type="bodyLight" maxW="400px">Share this URL with your contacts and they will automatically become part of your network when they register.</Text>
            <FormControl isReadOnly>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  variant="flushed"
                  variantColor="purple"
                  focusBorderColor="purple.500"
                  value={invitationUrl || "Loading..."}
                />
                <InputRightElement width="4.5rem">
                  <Text type="bodyBold" color="text.500" onClick={copyToClipboard} cursor="pointer">Copy Link</Text>
                </InputRightElement>
              </InputGroup>
              {
                copied && <FormHelperText>
                  <Text type="secondaryBodyRegular">{"URL copied to clipboard!"}</Text>
                </FormHelperText>
              }
            </FormControl>
            <Text type="secondaryBodyBold" mt={["1.25rem"]}>Send invitation email</Text>
            <Text type="bodyLight" mt={["1.25rem"]}>Enter email addresses below to send an automatic invitation email to dealers. Separate email address with a comma or write each address on a new line.</Text>

            <form onSubmit={onAddEmail}>
              <Box></Box>
              <Box d="flex" flexWrap="wrap" mt={["1.25rem"]}>
                <Box d="flex" flexWrap="wrap">
                  {emails.map((email, index) => (
                    <Box
                      px="0.5rem"
                      m="5px"
                      key={index}
                      d="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="25px"
                      borderWidth="1px"
                      borderColor={isValidEmail(email) ? "purple.500" : "red.500"}
                    >
                      <Text
                        m="5px"
                        type="bodyLight"
                        color={isValidEmail(email) ? "purple.500" : "red.500"}
                      >
                        {email}
                      </Text>
                      <Box
                        cursor="pointer"
                        fontSize="0.5rem"
                        color={isValidEmail(email) ? "purple.500" : "red.500"}
                        onClick={() => onRemoveEmail(index)}
                      >
                        ⓧ
                    </Box>
                    </Box>
                  ))}
                </Box>
                <Input
                  onBlur={onAddEmail}
                  variant="flushed"
                  variantColor="purple"
                  focusBorderColor="purple.500"
                  value={email}
                  placeholder="Type email here..."
                  onChange={onChangeEmail}
                />
              </Box>
            </form>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={["row-reverse", "row"]}
              mt={["1.25rem"]}
            >
              <Button
                variantColor={["white", "gray"]}
                color="text.500"
                onClick={onClose}
                boxShadow="none"
              >
                Cancel
            </Button>
              <Button onClick={sendInvitations} w={["70%", "50%"]}>
                Send Invitations
            </Button>
            </Box>
          </Box>
          :
          <Box
            p={["1.7rem"]}
            maxW="530px"
            d="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              as={FaCheckCircle}
              color="purple.400"
              size={12}
              marginTop={"5rem"}
              marginBottom={"1rem"}
            />
            <Text type="title">
              {emails.length} Invitation{emails.length > 1 && "s"} Sent
          </Text>
            <Text
              marginBottom="6rem"
              type="secondaryBodyLight"
              color="purple.400"
            >
              Your email invitation{emails.length > 1 && "s"}{" "}
              {emails.length > 1 ? "have" : "has"} been sent
          </Text>
          </Box>
      }
    </Modal>
  );
};

export default InviteDealers;
