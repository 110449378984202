import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getDeals as getDealsAction } from "../../actions/marketplaceActions";

import { Box, Skeleton } from "@chakra-ui/core";

import Text from "../../common/Text";
import Filters from "../../common/Filters";
import Pagination from "../../common/Pagination";
import Deal from "../../common/OfferDealStatus";

import {
  dealsVariantsMapping as variantsMapping,
  dealsTitlesMapping as titlesMapping,
  dealsDescriptionsMapping as descriptionsMapping,
} from "../../common/mappings";
const generalFilters = ["All Deals", "In Progress", "Completed", "Cancelled"];

const filtersMap = {
  in_progress: "In Progress",
  completed: "completed",
  cancelled: "cancelled",
};

const DealsList = ({ history }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    userId: state.auth.user.id,
    deals: state.marketplace.deals.objects,
    pages: state.marketplace.deals.pages,
    page: state.marketplace.deals.page,
    loading: state.marketplace.deals.loading,
  }));

  useEffect(() => {
    getDeals();
  }, []);

  const getDeals = (page = 1) => {
    const params = {
      state: history.location.pathname.split("/")[2],
      page,
    };
    dispatch(getDealsAction(params));
  };

  const navigateToFilter = (selectedTab) => {
    switch (selectedTab) {
      case "All Deals":
        history.push("/deals");
        break;
      case "In Progress":
        history.push("/deals/in_progress");
        break;
      case "Completed":
        history.push("/deals/completed");
        break;
      case "Cancelled":
        history.push("/deals/canceled");
        break;

      default:
        break;
    }
    getDeals();
  };

  const selectedFilter = filtersMap[history.location.pathname.split("/")[2]];
  return (
    <Box w="100%" h="100%">
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px={["2.5rem", "3rem"]}
        py="1.5rem"
      >
        <Text type="header" color="white">
          My Deals
        </Text>
        <Text fontSize={["1.1rem", "0.6rem"]} color="white">
          Accepted offers by both parties are your deals
        </Text>
      </Box>
      <Box mx="2.5rem" mt="2rem">
        <Filters
          generalFilters={generalFilters}
          selectedGeneral="All Deals"
          onSelectedGeneral={navigateToFilter}
          selectedGeneral={selectedFilter || "All Deals"}
        />
        {store.loading ? (
          <>
            <Skeleton isLoaded={!store.loading}>
              <Box minHeight="7rem" mt="1rem"></Box>
            </Skeleton>
            <Skeleton mt="1rem" isLoaded={!store.loading}>
              <Box minHeight="7rem"></Box>
            </Skeleton>
            <Skeleton mt="1rem" isLoaded={!store.loading}>
              <Box minHeight="7rem"></Box>
            </Skeleton>
          </>
        ) : (
            store.deals.map((deal) => (
              <Deal
                key={deal.id}
                id={deal.id}
                variant={variantsMapping[deal.state || "in_progress"]}
                title={titlesMapping[deal.state || "in_progress"]}
                description={descriptionsMapping[deal.state || "in_progress"]}
                linkTo={`deals/details/${deal.id}`}
                deal={deal}
                type="deal"
              />
            ))
          )}
      </Box>
      <Pagination
        pages={store.pages}
        currentPage={store.page}
        setPage={getDeals}
      />
    </Box>
  );
};

export default DealsList;
