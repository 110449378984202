import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/core";
import { MdChevronRight } from "react-icons/md";
import moment from "moment";

import Text from "../../common/Text";
import Button from "../../common/Button";
import { currencyFormat } from "../../common/utils";

const OfferStates = (props) => {
  const findType = () => {
    if (!props.offer.is_counter) {
      switch (props.type) {
        case "pending":
          return <Pending {...props} />;
        case "accepted":
          return <Accepted {...props} />;
        case "rejected":
          return <Rejected {...props} />;
        case "withdrawn":
          return <Withdrawn />;
        default:
          return null;
      }
    } else {
      return <Counter {...props} />;
    }
  };
  return props.modifying ? <Modifying {...props} /> : findType();
};

const Modifying = ({ loading, update, vehicles }) => {
  const calculateCurrentOffer = () => {
    let value = 0;
    let vehiclesCount = 0;
    vehicles.forEach((vehicle) => {
      if (vehicle.offerMade) {
        value += Number(vehicle.offerMade);
        vehiclesCount++;
      }
    });
    return { value, vehicles: vehiclesCount };
  };
  const currentOffer = calculateCurrentOffer();

  return (
    <Box p="1rem" pl="2rem" w="100%">
      <Text type="header" mb="0.5rem">
        Modify Offer
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        Select vehicles from this batch to modify offer
      </Text>
      <Box
        float="right"
        d="flex"
        flexDir={["column", "row"]}
        alignItems="center"
      >
        <Text mr="1rem" type="largeBodyRegular" fontSize={["1.2rem", "0.7rem"]}>
          {currentOffer.vehicles} vehicles
        </Text>
        <Button variantColor={"gray"} mr={[0, "1rem"]} boxShadow="none">
          {currentOffer.value > 0 ? currencyFormat(currentOffer.value) : "-/-"}
        </Button>
        <Button
          isLoading={loading}
          mt={["1rem", 0]}
          onClick={update}
          boxShadow="none"
        >
          Modify Offer
        </Button>
      </Box>
    </Box>
  );
};

const Pending = ({ loading, modify, withdraw }) => {
  return (
    <Box p="1rem">
      <Text type="header" mb="0.5rem">
        Offer Made
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        Your offer has been submitted and awaits a response from the seller.
      </Text>
      <Box
        d={["flex", "block"]}
        alignItems="flex-end"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Button isLoading={loading} onClick={modify}>
          Modify Offer
        </Button>
        <Button bg="error" ml={[0, "1rem"]} mt={["1rem", 0]} onClick={withdraw}>
          Withdraw Offer
        </Button>
      </Box>
    </Box>
  );
};

const Accepted = ({ offer }) => {
  return (
    <Box w="100%" h="100%">
      <Box p="1rem" h="70%">
        <Text type="header" mb="0.5rem" color="success">
          Offer Accepted
        </Text>
        <Text type="secondaryBodyLight" mb="1rem" fontSize={["1rem", "0.6rem"]}>
          This offer was accepted by both parties. You can view it in Deals.
        </Text>
      </Box>
      <Box
        bg="gray.400"
        w="100%"
        h={["3rem", "30%"]}
        minHeight="2rem"
        d="flex"
        alignItems="center"
      >
        <Text
          as={Link}
          to={offer.deal?.id ? `/deals/details/${offer.deal.id}` : "/deals"}
          color="purple.500"
          d="flex"
          alignItems="center"
          type="secondaryBodyBold"
          ml="1rem"
          fontSize={["1.1rem", "0.6rem"]}
        >
          Continue to Deal Details{" "}
          <Box as={MdChevronRight} fontWeight="light" />
        </Text>
      </Box>
    </Box>
  );
};

const Rejected = () => {
  return (
    <Box p="1rem">
      <Text type="header" mb="0.5rem" color="error">
        Offer Declined
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        Seller declined this offer.
      </Text>
    </Box>
  );
};

const Counter = (props) => {
  const { offer } = props;
  return (
    <Box
      p="1rem"
      pb="2rem"
      d="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Text type="header"> Counter-Offer Received </Text>

      <Text type="secondaryBodyLight">
        {offer.countered_offer?.title}{" "}
        {moment(offer.countered_offer?.create_date).fromNow()}
      </Text>
      <Text type="header" mt="0.5rem">
        {props.offer.vehicle_offers.length} Vehicles /{" "}
        {currencyFormat(offer.price)}
      </Text>
    </Box>
  );
};

const Withdrawn = () => {
  return (
    <Box p="1rem">
      <Text type="header" mb="0.5rem" color="error">
        Offer Withdrawn
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        You withdrew this offer.
      </Text>
    </Box>
  );
};

export default OfferStates;
