import React from "react";
import {
  Box,
  FormControl,
  SimpleGrid,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/core";

import Select from "../../common/Select";
import Input from "../../common/Input";
import Button from "../../common/Button";
import Text from "../../common/Text";

import countries from "../../common/meta/countries.json";

import validate from "validate.js";
const constraints = {
  purchase_vol: {
    presence: { allowEmpty: false },
  },
  purchase_countries: {
    presence: { allowEmpty: false },
  },
  sales_vol: {
    presence: { allowEmpty: false },
  },
  sales_countries: {
    presence: { allowEmpty: false },
  },
};

const BusinessInformation = ({
  goToNextStep,
  fields,
  setFields,
  errors,
  setErrors,
  loading,
}) => {
  const submit = (e) => {
    e.preventDefault();
    const validationErrors = validate(fields, constraints);
    if (validationErrors) setErrors(validationErrors);
    else goToNextStep();
  };

  const countriesArray = Object.keys(countries);

  return (
    <>
      <Text type="header" textAlign="center">
        A few questions about your business
      </Text>
      <Box mt={15}>
        <SimpleGrid columns={[1, 1, 2]} spacing={5} mt={5}>
          <FormControl
            isInvalid={errors.purchase_vol && errors.purchase_vol.length > 0}
            pr={[0, "3rem"]}
          >
            <FormLabel
              color="text.500"
              fontWeight="light"
              minHeight="2.5rem"
              fontSize={["1rem", "0.6rem"]}
            >
              How many cars do you purchase per year?
            </FormLabel>
            <Input
              variant="flushed"
              id="purchase_vol"
              name="purchase_vol"
              value={fields.purchase_vol}
              onChange={setFields}
              type="number"
              data-test-id="purchase_vol"
            />
            <FormErrorMessage data-test-id="purchase_vol-error">
              {errors.purchase_vol && errors.purchase_vol[0]}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              errors.purchase_countries && errors.purchase_countries.length > 0
            }
            pl={[0, "3rem"]}
          >
            <FormLabel
              color="text.500"
              fontWeight="light"
              minHeight="2.5rem"
              fontSize={["1rem", "0.6rem"]}
            >
              Which countries do you purchase from?
            </FormLabel>
            <Select
              variant="flushed"
              placeholder="Country"
              name="purchase_countries"
              value={fields.country}
              onChange={setFields}
              data-test-id="purchase_countries"
            >
              {countriesArray.map((country) => (
                <option key={country}>{country}</option>
              ))}
            </Select>
            <FormErrorMessage data-test-id="purchase_countries-error">
              {errors.purchase_countries && errors.purchase_countries[0]}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.sales_vol && errors.sales_vol.length > 0}
            pr={[0, "3rem"]}
          >
            <FormLabel
              color="text.500"
              fontWeight="light"
              minHeight="2.5rem"
              fontSize={["1rem", "0.6rem"]}
            >
              How many cars do you offer for sale per year?
            </FormLabel>
            <Input
              variant="flushed"
              id="sales_vol"
              name="sales_vol"
              value={fields.sales_vol}
              onChange={setFields}
              data-test-id="sales_vol"
            />
            <FormErrorMessage data-test-id="sales_vol-error">
              {errors.sales_vol && errors.sales_vol[0]}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              errors.sales_countries && errors.sales_countries.length > 0
            }
            pl={[0, "3rem"]}
          >
            <FormLabel
              color="text.500"
              fontWeight="light"
              minHeight="2.5rem"
              fontSize={["1rem", "0.6rem"]}
            >
              Which countries do you sell to?
            </FormLabel>
            <Select
              id="country"
              variant="flushed"
              placeholder="Country"
              name="sales_countries"
              value={fields.country}
              onChange={setFields}
              data-test-id="sales_countries"
            >
              {countriesArray.map((country) => (
                <option key={country}>{country}</option>
              ))}
            </Select>
            <FormErrorMessage data-test-id="sales_countries-error">
              {errors.sales_countries && errors.sales_countries[0]}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Box w="full" textAlign="center" pt="2rem">
          <Button
            isLoading={loading}
            onClick={submit}
            data-test-id="complete-registration"
          >
            Complete Registration
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BusinessInformation;
