import {
  GET_BATCHES,
  CREATE_BATCH,
  GET_BATCH,
  SET_BATCH,
  GET_BATCH_VEHICLES,
  GET_BATCH_OFFERS,
  GET_BATCH_DEALS,
  GET_DASHBOARD_OFFERS,
  GET_DASHBOARD_DEALS,
  GET_BATCH_VEHICLE,
  SET_BATCH_VEHICLE,
  GET_NETWORK_USERS,
  GET_ACTIVITIES,
  EDIT_BATCH,
  SET_SEARCH_KEYWORD,
  SET_SEARCH_RESULTS
} from "../constants";
import { myDashboard, offers, deals } from "../api";

export const getBatches = (params = {}) => ({
  type: GET_BATCHES,
  payload: myDashboard.batches(params),
});

export const createBatch = (data) => ({
  type: CREATE_BATCH,
  payload: myDashboard.createBatch(data),
});

export const getBatch = (batchId) => ({
  type: GET_BATCH,
  payload: myDashboard.getBatch(batchId),
});

export const setBatch = (batch) => ({
  type: SET_BATCH,
  payload: batch,
});

export const addVehicle = (batchId, data) => ({
  type: GET_BATCH,
  payload: myDashboard.addVehicle(batchId, data),
});

export const getVehicles = (batchId, params) => ({
  type: GET_BATCH_VEHICLES,
  payload: myDashboard.getVehicles(batchId, params),
});

export const getVehicle = (batchId, vehicleId) => ({
  type: GET_BATCH_VEHICLE,
  payload: myDashboard.getVehicle(batchId, vehicleId),
});

export const setVehicle = (vehicle) => ({
  type: SET_BATCH_VEHICLE,
  payload: vehicle,
});

export const getBatchOffers = (batchId, params = {}) => ({
  type: GET_BATCH_OFFERS,
  payload: myDashboard.getBatchOffers(batchId, params),
});

export const getBatchDeals = (batchId, params = {}) => ({
  type: GET_BATCH_DEALS,
  payload: myDashboard.getBatchDeals(batchId, params),
});

export const getOffers = (params = {}) => ({
  type: GET_DASHBOARD_OFFERS,
  payload: offers.getOffers(params),
});

export const getDeals = (params = {}) => ({
  type: GET_DASHBOARD_DEALS,
  payload: deals.getDeals(params),
});

export const getNetworkUsers = (params = {}) => ({
  type: GET_NETWORK_USERS,
  payload: myDashboard.getNetworkUsers(params),
});

export const getActivities = (params = {}) => ({
  type: GET_ACTIVITIES,
  payload: myDashboard.getActivities(params),
});

export const editBatch = (batchId, data = {}) => ({
  type: EDIT_BATCH,
  payload: myDashboard.editBatch(batchId, data),
});

export const setSearchKeyword = (keyword) => ({
  type: SET_SEARCH_KEYWORD,
  payload: keyword
});

export const setSearchResults = (results) => ({
  type: SET_SEARCH_RESULTS,
  payload: results
});
