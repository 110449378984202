import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, Image, FormControl, FormErrorMessage } from "@chakra-ui/core";

import Input from "../../common/Input";
import Text from "../../common/Text";
import Header from "../login/Header";
import Button from "../../common/Button";
import Checkbox from "../../common/CheckBox";
import Male from "../../assets/images/Male.png";

import validate from "validate.js";
import constraints from "../../common/generalConstraints";

const EnterEmail = ({ history, email, handleChange, paramData }) => {
  const [errors, setErrors] = useState({});
  const [isTermsAgreed, setIsTermsAgreed] = useState(true);

  const submitEmail = (e) => {
    e.preventDefault();
    const validationErrors = validate({ email }, constraints);

    if (validationErrors && validationErrors.email) {
      setErrors({ ...errors, email: validationErrors.email[0] });
    } else {
      history.push("/accept-invitation/password");
    }
  };

  const toggleTermsAgreed = () => {
    setIsTermsAgreed(!isTermsAgreed);
  };

  return (
    <Box w="full" h="100vh">
      <Box
        className="gradient-background mobile-remove"
        bg={["purple.500", null]}
        h={["15vh", "30vh"]}
        px={["2rem", "5.4rem"]}
        pb={["12rem", 0]}
      >
        <Header />
      </Box>
      <Box
        shadow="lg"
        bg="white"
        w={["calc(100% - 4rem)", "100%", "50%", "25%"]}
        maxWidth={453}
        minWidth={["calc(100% - 4rem)", 453]}
        m="auto"
        rounded="lg"
        mt={["-4rem", "-5rem"]}
      >
        <Box
          borderTopRightRadius="lg"
          borderTopLeftRadius="lg"
          h={100}
          bg="surface"
          d="flex"
          justifyContent="center"
          alignItems="center"
        ></Box>
        <Box px={"1rem"} mt="-2.5rem">
          <Box d="flex" alignItems="center" flexDirection="column">
            <Box
              height={112}
              width={112}
              rounded="full"
              d="flex"
              justifyContent="center"
              alignItems="center"
              className="image-border-gradient"
            >
              <Image
                objectFit="cover"
                rounded="full"
                size={105}
                src={paramData.dealer.image || Male}
                alt="Inviter"
                m="auto"
              />
            </Box>
            <Text mt={4} type="title">
              {paramData.dealer.fullname}
            </Text>
            <Text
              color="purple.500"
              type="placeholder"
              fontSize={["0.8rem", "0.5rem"]}
            >
              {paramData.network.name}
            </Text>
            <Text
              mt={1}
              maxW="67%"
              textAlign="center"
              type="secondaryBodyLight"
              fontSize={["1rem", "0.6rem"]}
            >
              has invited you to join their
              <br /> network of car dealers on SeezOne.
            </Text>
          </Box>
        </Box>
        <Box pb="2.5rem" px={"1.5rem"}>
          <form onSubmit={submitEmail}>
            <Text
              textAlign="center"
              type="bodyBold"
              py="1.5rem"
              px={["3rem", 0]}
              fontSize={["1rem", "0.5rem"]}
            >
              Join this network to get access to the latest vehicles for sale
            </Text>
            <FormControl
              isInvalid={errors.email && errors.email.length > 0}
              px={6}
            >
              <Input
                onChange={handleChange}
                value={email}
                name="email"
                className="mt-6"
                variant="flushed"
                placeholder="Email address"
                autoFocus={true}
                mt={4}
                placeholder="Enter email address..."
              />
              <FormErrorMessage fontSize={15} className="email-error-message">
                {errors.email}
              </FormErrorMessage>
            </FormControl>
            <Box m="auto" textAlign="center" mt="1rem">
              <Checkbox
                m="auto"
                size="lg"
                variantColor="purple"
                isChecked={isTermsAgreed}
                onChange={toggleTermsAgreed}
              >
                <Text type="bodyLight">
                  I agree to the terms and conditions
                </Text>
              </Checkbox>
            </Box>
            <Box w="full" textAlign="center" mt="1rem" type="submit">
              <Button fontSize={["1.5rem", "0.7rem"]}>Accept Invitation</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(EnterEmail);
