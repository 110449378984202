import React from "react";
import { Box, Text, Image } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import LandingHeader from "./LandingHeader";

import Button from "../../common/Button";
import Input from "../../common/Input";

import landingRight from "../../assets/images/landingRight.png";
import dealership from "../../assets/images/dealership.png";
import bullseye from "../../assets/images/bullseye.svg";
import logo from "../../assets/images/logo-03.png";

import {
  FaCar,
  FaImages,
  FaBuffer,
  FaHandshake,
  FaCalculator,
} from "react-icons/fa";

const LandingPage = ({ history }) => {
  const register = () => history.push("/create-account");
  return (
    <Box>
      <Box
        w="100vw"
        minH={["80vh", "100vh"]}
        px={["3rem", "5.4rem"]}
        background="linear-gradient(180deg, #545ffe 0%, #7f00ff 100%);"
      >
        <LandingHeader />
        <Box
          w="100%"
          h={["auto", "calc(100vh - 7rem)"]}
          d="flex"
          alignItems={["space-between", "center"]}
          justifyContent="space-between"
          flexDirection={["column-reverse", "row"]}
        >
          <Box color="white" w={["100%", "45%"]} mt={["5rem", 0]}>
            <Text
              fontSize="0.7rem"
              fontWeight="medium"
              letterSpacing="3px"
              textTransform="uppercase"
              mb="1.5rem"
              d={["none", "block"]}
            >
              How it works
            </Text>
            <Text fontSize={["2.5rem", "3rem"]} fontWeight="black">
              The global digital marketplace
            </Text>
            <Text fontSize="1.3rem">for wholesale vehicle trading</Text>
            <Button
              backgroundColor="white"
              color="purple.400"
              mt="1.5rem"
              fontSize={["1.5rem", "0.7rem"]}
              onClick={register}
            >
              Sign Up
            </Button>
          </Box>
          <Image src={landingRight} objectFit="cover" w={["100%", "58%"]} />
        </Box>
      </Box>
      <Box
        w="100%"
        minH="100vh"
        d="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="space-between"
        background="#f8f8fa"
        py="3rem"
      >
        <Text
          color="purple.400"
          fontSize={["1.2rem", "0.7rem"]}
          fontWeight="medium"
          letterSpacing="3px"
          textTransform="uppercase"
        >
          What is seezone?
        </Text>
        <Box mx={["1rem", 0]} mt={["1rem", 0]}>
          <Text
            fontSize={["1.5rem", "1rem"]}
            fontWeight={["bold", "normal"]}
            textAlign={["center", "left"]}
            color="text.500"
          >
            A new online solution for international B2B vehicle trading.
          </Text>
          <Text textAlign={["center", "left"]} color="text.500">
            Buy and sell vehicles digitally, easily.{" "}
          </Text>
        </Box>
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={["column", "row"]}
          maxW={["100%", "70%"]}
          w="70%"
          flexWrap="wrap"
        >
          <InfoBox
            text="Access unique vehicles available in batches"
            background="#e9faf0"
            Icon={FaCar}
          />
          <InfoBox
            text="See specs, pictures and inspection reports"
            background="#ffeae6"
            Icon={FaImages}
          />
          <InfoBox
            text="Make custom offers on stock"
            background="#f2e5ff"
            Icon={FaBuffer}
          />
          <InfoBox
            text="Receive counter-offers and make deals"
            background="#f2e5ff"
            Icon={FaHandshake}
          />
          <InfoBox
            text="Track your deals and budget"
            background="#fff6e7"
            Icon={FaCalculator}
          />
        </Box>
      </Box>
      <Banner
        items={[
          "Ex-rental and leasing cars",
          "New, tax-free cars",
          "Commercial vehicles",
          "Trade-ins and demo cars",
          "Cars with mixed specs, based on country of import",
        ]}
      />
      <Box
        w="100%"
        minH="100vh"
        d="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="flex-start"
        background="#E8E9EC"
      >
        <Box
          maxW={["100%", "70%"]}
          d="flex"
          flexDir={["column", "row"]}
          justifyContent="space-between"
          alignItems="center"
        >
          <Image
            w={["100%", "50%"]}
            objectFit="contain"
            src={dealership}
          ></Image>
          <Box
            w={["100%", "45%"]}
            px={["2rem", 0]}
            h="80%"
            d="flex"
            flexDir="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Text
              color="#7F00FF"
              letterSpacing="3px"
              fontSize={["1.2rem", "0.7rem"]}
              textTransform="uppercase"
            >
              SeezOne is used by
            </Text>
            <Box color="text.500" fontSize="1.3rem" fontWeight="bold">
              <Text>Parallel dealers</Text>
              <Text>Traders</Text>
              <Text>Importers</Text>
              <Text>Showrooms and dealerships</Text>
            </Box>
            <Button my={["2rem", 0]} fontSize={["1.2rem", "0.7rem"]}>
              Signup as a Buyer
            </Button>
          </Box>
        </Box>
        {/* <Box
          w="62%"
          background="#7F00FF"
          boxShadow="0px 24px 48px #00323829;"
          borderRadius="10px"
          py="2.15rem"
          px="3rem"
          d={["none", "block"]}
        >
          <Text
            color="white"
            letterSpacing="3px"
            fontWeight="medium"
            textTransform="uppercase"
            fontSize="0.7rem"
          >
            Seezone
          </Text>
          <Grid
            templateColumns="3fr 2fr 2fr"
            columnGap="3rem"
            rowGap="1rem"
            color="white"
          >
            <Box>
              <Text
                fontSize="2.15rem"
                lineHeight="2rem"
                color="white"
                letterSpacing="-1px"
              >
                Unique car stock
              </Text>
            </Box>
            <Box>
              <Box as={FaCar} color="#bf80ff" />
              <Text fontSize="1rem" fontWeight="bold">
                Ex-rental and leasing cars
              </Text>
            </Box>
            <Box>
              <Box as={FaCar} color="#bf80ff" />
              <Text fontSize="1rem" fontWeight="bold">
                New, tax-free cars
              </Text>
            </Box>
            <Box>
              <Box as={FaCar} color="#bf80ff" />
              <Text fontSize="1rem" fontWeight="bold">
                Cars with mixed specs, based on country of import
              </Text>
            </Box>
            <Box>
              <Box as={FaCar} color="#bf80ff" />
              <Text fontSize="1rem" fontWeight="bold">
                Trade-ins & demo cars
              </Text>
            </Box>
            <Box>
              <Box as={FaCar} color="#bf80ff" />
              <Text fontSize="1rem" fontWeight="bold">
                Commercial vehicles
              </Text>
            </Box>
          </Grid>
        </Box> */}
        <Box
          borderRadius="10px"
          backgroundColor="purple.500"
          p={["2rem", "1rem"]}
          pt={["3rem", "3.5rem"]}
          w="80%"
          m="auto"
          mt="2rem"
          position="relative"
        >
          <Box
            backgroundColor="white"
            height={["5rem", "4rem"]}
            width={["5rem", "4rem"]}
            borderRadius="100%"
            d="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="-40px"
            left={["calc(50% - 2.5rem)", "calc(50% - 1.5rem)"]}
          >
            <Image src={bullseye} size={["4rem", "3rem"]} />
          </Box>
          <Text
            color="white"
            fontSize={["2rem", "1.5rem"]}
            fontWeight="bold"
            textAlign="center"
          >
            SeezOne Mission
          </Text>
          <Text
            textAlign="center"
            color="white"
            my={["2rem", "1rem"]}
            fontSize={["1rem", "0.7rem"]}
          >
            SeezOne is bringing trust and transparency to the wholesale
            automotive trading industry by giving buyers and sellers from
            different countries the opportunity to safely connect and establish
            new business relationships.
          </Text>
        </Box>
        <Box
          d="flex"
          mt={["2rem", "10rem"]}
          p={["3rem", "2rem"]}
          flexDir={["column", "row"]}
          className="dealer-background"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Text color="white" fontSize="1.2rem" fontWeight="bold">
              Want to start selling your cars internationally with SeezOne?
            </Text>
            <Text color="white" fontSize="1rem" fontWeight="bold" my="1rem">
              We’ll call you with all the details.
            </Text>
          </Box>
          <Box
            borderTopRightRadius="10px"
            borderTopLeftRadius="10px"
            background="white"
            p={["3rem", "2rem"]}
            pt="2rem"
            w={["100%", "40%"]}
            textAlign="center"
            mt={[0, "-8rem"]}
            boxShadow="0px 24px 48px #00000015"
          >
            <Text
              fontSize={["1.2rem", "1.5rem"]}
              color="text.500"
              fontWeight="bold"
              textAlign="left"
              ml={[0, "1rem"]}
            >
              Contact Form
            </Text>
            <Box d="flex" flexDir={["column", "row"]} flexWrap="wrap">
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="First Name"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="Last Name"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="Phone Number"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="Email Address"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="Location"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="Which countries do you sell to?"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="How many cars do you sell per year?"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
              <Input
                mx={[0, "1rem"]}
                my="0.5rem"
                placeholder="How many cars do you have in stock?"
                flex={["0 100%", "0 calc(50% - 2rem)"]}
              />
            </Box>
            <Button
              backgroundColor="text.500"
              color="white"
              mt="1.5rem"
              fontSize={["1.5rem", "0.7rem"]}
              mx="auto"
            >
              Submit Form
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor="text.500"
          p="3rem"
          w="100%"
          d="flex"
          justifyContent="flex-start"
          alignItems={["flex-start", "flex-end"]}
          flexDir={["column", "row"]}
        >
          <Box>
            <Text
              fontSize={["1rem", "0.8rem"]}
              fontWeight="bold"
              color="white"
              mb="2rem"
            >
              Subscribe to our automotive <br /> industry newsletter,
              Up-To-Speed{" "}
            </Text>
            <Input
              placeholder="Enter email address"
              className="white-placeholder"
              color="white"
              borderBottomColor="white"
              focusBorderColor="white"
              opacity={1}
            />
          </Box>
          <Button
            fontSize={["1.2rem", "0.7rem"]}
            my={["1rem", 0]}
            mx={[0, "2rem"]}
          >
            Subscribe
          </Button>
          <Box
            color="white"
            fontSize="0.8rem"
            mt={["2rem", 0]}
            mx={[0, "2rem"]}
          >
            <Text as={Link} mx={["0.5rem", "1rem"]} to="/contact-us">
              Contact Us
            </Text>
            <Text as={Link} mx={["0.5rem", "1rem"]} to="/terms">
              Terms of Conditions
            </Text>
            <Text as={Link} mx={["0.5rem", "1rem"]} to="/privacy">
              Privacy Policy
            </Text>
          </Box>
          <Box
            d="flex"
            flexDir={["row", "column"]}
            alignItems="center"
            justifyContent="space-between"
            w={["100%", "auto"]}
          >
            <Image src={logo} w="10rem" h="5rem" />
            <Text fontSize="0.8rem" color="surface">
              Copyright © {new Date().getFullYear()}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const InfoBox = ({ text, background, Icon }) => {
  return (
    <Box
      mb="1.8rem"
      mx={[0, "0.9rem"]}
      w={["100%", "28%"]}
      py={["2rem", "1rem"]}
      px={["2rem", 0]}
      boxShadow="0px 24px 48px #00000015"
      borderRadius="10px"
      d="flex"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
    >
      <Box
        w={["122px", "112px"]}
        h={["74px", "112px"]}
        background={background}
        d="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="10px"
      >
        <Box w="50%" h="50%" as={Icon} />
      </Box>
      <Text
        maxW={["80%", "8rem"]}
        mt="1rem"
        textAlign="center"
        color="#384364"
        fontSize={["1.1rem", "0.75rem"]}
        fontWeight="bold"
      >
        {text}
      </Text>
    </Box>
  );
};

const Banner = ({ items }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      px={[0, "2rem"]}
      backgroundColor="purple.500"
    >
      {items.map((item, index) => (
        <Box
          backgroundColor="purple.500"
          py="2.5rem"
          borderBottomWidth={["2px", 0]}
          borderBottomColor="white"
          flex={[items.length - 1 === index ? "0 100%" : "0 50%", "0 20%"]}
        >
          <Text
            fontWeight="bold"
            color="white"
            textAlign="center"
            px="1rem"
            borderRightWidth={[
              (index + 1) % 2 === 0 ? 0 : "1px",
              items.length - 1 === index ? 0 : "1px",
            ]}
            borderRightColor="white"
          >
            Buy and sell vehicles digitally, easily.
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default LandingPage;
