import React from "react";
import { Route, Redirect } from "react-router-dom";

const RestrictedRoute = ({ location, component: Component, ...rest }) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.is_seller ? (
          <Component {...props} />
        ) : (
          <Redirect to="/access-denied" />
        )
      }
    />
  );
};

export default RestrictedRoute;
