import React, { useState, useEffect } from "react";

import { Box, Grid, Spinner } from "@chakra-ui/core";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { Switch, Route } from "react-router-dom";

import NotificationsList from "./Notifications";
import Deals from "../deals/DealsRouter";
import Offers from "../offers/OffersRouter";
import MarketplaceRouter from "../marketplace/MarketplaceRouter";
import MyDashboardRouter from "../myDashboard/MyDashboardRouter";
import UserPofile from "../userProfile/UserProfile"
import EditProfile from "../userProfile/EditProfile";

import RestrictedRoute from "../../common/routes/RestrictedRoute";
import NotFound from "../../common/errorPages/NotFound";
import AccessDenied from "../../common/errorPages/AccessDenied";

import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../actions/staticsActions"

import { marketplace } from "../../api"
import EditBuyerProfile from "../userProfile/EditBuyerProfile";


const Dashboard = ({ history }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);

  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);
  const toNotifications = () => history.push("/notifications");
  const toMarketplace = () => history.push("/marketplace");
  const toProfile = () => history.push("/profile/offers");

  const [offersCount, setOffersCount] = useState(null)
  const [dealsCount, setDealsCount] = useState(null)

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    marketplace.headOffers().then(headers => {
      setOffersCount(headers.get("x-total-count"));
    })
    marketplace.headDeals().then(headers => {
      setDealsCount(headers.get("x-total-count"));
    })
    dispatch(getCurrencies());
  }

  return (
    <Box w="100vw" h="100vh" bg="gray.50">
      <Header
        openSidebar={openSidebar}
        toNotifications={toNotifications}
        toMarketplace={toMarketplace}
        toProfile={toProfile}
      ></Header>
      <Grid
        gridTemplateColumns={["100vw", "100vw", "100vw", "12rem auto"]}
        overflowX="hidden"
      >
        <Sidebar
          offersCount={offersCount}
          dealsCount={dealsCount}
          openSidebar={openSidebar}
          closeSidebar={closeSidebar}
          isSidebarOpen={isSidebarOpen}
        />
        <Box
          w="100%"
          h="100vh"
          bg="surface"
          maxH="calc(100vh - 4.2rem)"
          overflowY="scroll"
          overflowX="hidden"
        >
          {idToken ? (
            <Switch>
              <Route path="/marketplace" component={MarketplaceRouter} />
              <Route path="/offers" component={Offers} />
              <Route path="/deals" component={Deals} />
              <Route path="/profile/edit/:userId" component={EditBuyerProfile} />
              <Route path="/profile/edit/" component={EditProfile} />
              <Route path="/user/:userId" component={UserPofile} />
              <Route path="/profile" component={UserPofile} />
              <RestrictedRoute
                path="/dashboard"
                component={MyDashboardRouter}
              />
              <Route path="/notifications" component={NotificationsList} />
              <Route path="/access-denied" component={AccessDenied} />
              <Route path="*" component={NotFound} />
            </Switch>
          ) : (
              <Box d="flex" alignItems="center" justifyContent="center" h="100%">
                <Spinner />
              </Box>
            )}
        </Box>
      </Grid>
    </Box>
  );
};

export default Dashboard;
