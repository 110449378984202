import React, { Fragment, useEffect, useState } from 'react';

import { auth } from "../../api"
import { myOfferCounterDescriptionsMapping, myOffersCounterTitlesMapping, myOffersDescriptionsMapping, myOffersTitlesMapping, offersVariantsMapping } from '../../common/mappings';

import Offer from "../../common/OfferDealStatus"
import Text from "../../common/Text"
import Filters from "../../common/Filters"

import { Box, Skeleton } from '@chakra-ui/core';
import Pagination from '../../common/Pagination';

const detailedFilters = [{
    name: "Status",
    options: [
        "All Offers",
        "Pending",
        "Accepted",
        "Declined",
        "Withdrawn",
    ]
}];

const NetworkUserOffers = ({ match }) => {
    const [offers, setOffers] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(true);

    const { userId } = match.params

    useEffect(() => {
        getOffers()
    }, [page, state])

    const getOffers = () => {
        setLoading(true);
        const params = {
            state,
            page
        }
        auth.getUserOffers(userId, params).then(res => {
            setLoading(false);
            setOffers(res.objects);
            setPages(res.pages);
        })
    }

    const setFilter = (selectedTab) => {
        switch (selectedTab) {
            case "All Offers":
                setState("")
                break;
            case "Pending":
                setState("pending")
                break;
            case "Accepted":
                setState("accepted")
                break;
            case "Declined":
                setState("rejected")
                break;
            case "Withdrawn":
                setState("withdrawn")
                break;

            default:
                break;
        }
    };

    return <Fragment>
        <Box
            mx="2.5rem"
            mt={"1rem"}
            d="flex"
            justifyContent="space-between"
        >
            <Box>
                <Text type="header">Offers</Text>
                <Text type="bodyLight">Showing {offers.length} Offers</Text>
            </Box>
            <Filters
                onSelectedDetailed={(index, tab) => setFilter(tab)}
                detailedFilters={detailedFilters}
            />
        </Box>

        <Box mx="2.5rem">
            {loading ? (
                <>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                    <Skeleton my="1rem" minHeight={["8rem", "6rem"]}></Skeleton>
                </>
            ) : (
                    offers.map(offer => (
                        <Offer
                            key={offer.id}
                            id={offer.id}
                            variant={offersVariantsMapping[offer.state]}
                            title={
                                offer.is_counter
                                    ? myOffersCounterTitlesMapping[offer.state]
                                    : myOffersTitlesMapping[offer.state]
                            }
                            description={
                                offer.is_counter
                                    ? myOfferCounterDescriptionsMapping[offer.state]
                                    : myOffersDescriptionsMapping[offer.state]
                            }
                            offer={offer}
                            linkTo={`/offers/details/${offer.id}`}
                        />
                    ))
                )
            }
        </Box>
        <Pagination
            pages={pages}
            currentPage={page}
            setPage={setPage}
        />
    </Fragment>
};

export default NetworkUserOffers;