import React, { useState } from "react";
import { Box, Input } from "@chakra-ui/core";

import Modal from "../../common/Modal";
import Text from "../../common/Text";
import Button from "../../common/Button";
import { currencyFormat } from "../../common/utils";

const SelectAllModal = ({
    isModalOpen,
    toggleModal,
    vehicles,
    onSubmitOffer,
    batchId
}) => {

    const [offer, setOffer] = useState("");

    return (
        <Modal isOpen={isModalOpen} onClose={toggleModal}>
            <Box w={["100%", "80%"]} py="1rem" pb="2rem">
                <Text type="header" mb="0.7rem">
                    Add to Offer
                </Text>
                <Text type="secondaryBodyLight" mb="0.5rem" fontSize={["1rem", "0.6rem"]}>
                    Batch #{batchId}
                </Text>
                <Text type="header" mb="0.7rem" w="100%">
                    {vehicles} Vehicles
                </Text>
                <Text type="bodyLight" fontSize={["1rem", "0.5rem"]}>
                    Your offer for this batch
                 </Text>
                <Box
                    d="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    flexDirection={["column", "row"]}
                >
                    <Input
                        height={["46px", "1.92rem"]}
                        width={["70%", "7.2rem"]}
                        fontSize={["1rem", "0.7rem"]}
                        borderRadius={"1.92rem"}
                        backgroundColor="gray.500"
                        placeholder="-"
                        textAlign="center"
                        _placeholder={{ color: "black", fontWeight: "bold" }}
                        _focus={{ border: "none" }}
                        value={offer}
                        onChange={e => setOffer(e.target.value)}
                        type="number"
                    />
                    <Button
                        ml={[0, "1rem"]}
                        mt={["1rem", 0]}
                        onClick={() => onSubmitOffer(offer)}
                        disabled={offer < 1}
                        w={["70%", "auto"]}
                    >
                        Add to Offer
                     </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default SelectAllModal;
