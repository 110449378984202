import { marketplace as marketplaceApi } from "../api";
import {
  GET_FEATURED_BATCHES,
  GET_MARKETPLACE_BATCHES,
  GET_MARKETPLACE_OFFERS,
  GET_MARKETPLACE_DEALS,
  SET_CURRENT_OFFER,
} from "../constants";

export const getFeatured = () => ({
  type: GET_FEATURED_BATCHES,
  payload: marketplaceApi.featured(),
});

export const getBatches = (params) => ({
  type: GET_MARKETPLACE_BATCHES,
  payload: marketplaceApi.batches(params),
});

export const getOffers = (params) => ({
  type: GET_MARKETPLACE_OFFERS,
  payload: marketplaceApi.offers(params),
});

export const getDeals = (params) => ({
  type: GET_MARKETPLACE_DEALS,
  payload: marketplaceApi.deals(params),
});

export const setCurrentOffer = (currentOffer) => ({
  type: SET_CURRENT_OFFER,
  payload: currentOffer,
});
