import React, { useEffect, useState } from "react";

import { Switch, Route } from "react-router-dom";

import { Box, FormControl, FormLabel, Grid, Skeleton, Spinner, useDisclosure } from "@chakra-ui/core";
import { FaSearch } from "react-icons/fa";

import Text from "../../common/Text";
import FeaturedContainer from "../../common/FeaturedContainer";
import SalesRepresentative from "../../common/SalesRepresentative";
import Button from "../../common/Button";
import Filters from "../../common/Filters";
import Modal from "../../common/Modal";
import Input from "../../common/Input";

import Batches from "./Batches";
import OffersList from "../myOffers/MyOffersList";
import Deals from "./Deals";
import Network from "./Network";
import ActivityLog from "./ActivityLog";
import { batch, useDispatch, useSelector } from "react-redux";

import InviteDealers from "./InviteDealers";
import { editUser } from "../../actions/authActions";

import { myDashboard } from "../../api"
import { debounce } from "../../common/utils";
import { setSearchKeyword, setSearchResults } from "../../actions/dashboardActions";

const ActionComponent = ({
  primaryText,
  secondaryText,
  seperator,
  showSeperatorOnMobile = true,
}) => {

  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent={["flex-start", "center"]}
      w={["50%", "100%"]}
      p={["1rem", 0]}
      mb="1rem"
      borderRightWidth={seperator && [showSeperatorOnMobile ? "2px" : 0, "2px"]}
      borderRightColor={seperator && "#3843640F"}
    >
      <Text
        fontSize="1.65rem"
        color="purple.500"
        fontWeight="bold"
        overflow="visible"
      >
        {primaryText || <Spinner size="sm" color="text.500" />}
      </Text>
      <Text type="bodyLight" maxW={["auto", "3.5rem"]} ml="0.5rem">
        {secondaryText}
      </Text>
    </Box>
  );
};

const ActionsSeparator = () => (
  <Box w="2px" h="100%" backgroundColor="text.500" opacity="0.54"></Box>
);

const generalFilters = [
  "Batches",
  "Offers",
  "Deals",
  "Network",
  "Activity Log",
];

const filtersMap = {
  batches: "Batches",
  offers: "Offers",
  deals: "Deals",
  network: "Network",
  activityLog: "Activity Log",
};

const MyDashboard = ({ history }) => {
  useEffect(() => {
    if (history.location.pathname === "/dashboard")
      history.push("/dashboard/batches");
    getInfoData();
  }, []);
  const user = useSelector((state) => state.auth.user);
  const searchKeyword = useSelector((state) => state.dashboard.searchKeyword);
  const totalResults = useSelector((state) => state.dashboard.searchResultsCount.total);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isEditNetworkModalOpened, setIsEditNetworkModalOpened] = useState(false)
  const [company, setCompany] = useState("");

  const [batchesRequireAction, setBatchesRequireAction] = useState(null);
  const [offersRequireResponse, setOffersRequireResponse] = useState(null);
  const [dealsInProgress, setDealsInProgress] = useState(null);
  const [dealersRequireOnboarding, setDealersRequireOnboarding] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSearchCount(searchKeyword);
  }, [searchKeyword])

  const getSearchCount = (q) => {
    setLoading(true);
    if (searchKeyword.length === "") {
      dispatch(setSearchResults({ batches: 0, users: 0, total: 0 }))
      return setLoading(false);
    }
    Promise.all([
      myDashboard.headBatches({ q }),
      myDashboard.headOffers({ q }),
      myDashboard.headDeals({ q }),
      myDashboard.headUsers({ q })
    ]).then(headers => {
      const batches = Number(headers[0].get("x-total-count"));
      const offers = Number(headers[1].get("x-total-count"));
      const deals = Number(headers[2].get("x-total-count"));
      const users = Number(headers[3].get("x-total-count"));
      const total = batches + offers + deals + users;
      dispatch(setSearchResults({ batches, offers, deals, users, total }))
      setLoading(false);
    })
  }

  const getInfoData = () => {

    myDashboard.headBatchesRequireAction().then(headers => {
      setBatchesRequireAction(headers.get("x-total-count"));
    })
    myDashboard.headDealsInProgress().then(headers => {
      setDealsInProgress(headers.get("x-total-count"))
    })
    myDashboard.headDealersRequireOnboarding().then(headers => {
      setDealersRequireOnboarding(headers.get("x-total-count"))
    })
    myDashboard.headOffersRequireResponse().then(headers => {
      setOffersRequireResponse(headers.get("x-total-count"))
    })
  }

  const navigateToFilter = (selectedTab) => {
    switch (selectedTab) {
      case "Batches":
        history.push("/dashboard/batches");
        break;
      case "Offers":
        history.push("/dashboard/offers");
        break;
      case "Deals":
        history.push("/dashboard/deals");
        break;
      case "Network":
        history.push("/dashboard/network");
        break;
      case "Activity Log":
        history.push("/dashboard/activityLog");
        break;

      default:
        break;
    }
  };

  const navigateToCreate = () => {
    history.push("create-batch");
  };

  const openEditNetworkNameModal = () => {
    setCompany(user.company || "");
    setIsEditNetworkModalOpened(true);
  }

  const selectedFilter = filtersMap[history.location.pathname.split("/")[2]];

  const editNetworkName = () => {
    dispatch(editUser({ company }))
    setIsEditNetworkModalOpened(false);
  }

  const onSearchChange = debounce((keyword) => {
    dispatch(setSearchKeyword(keyword))
  }, 450)

  return (
    <Box w="100%" h="100%">
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px={["2.5rem", "3rem"]}
        py="1.5rem"
      >
        <Text type="header" color="white">
          My Dashboard
        </Text>
        <Text type="secondaryBodyRegular" color="white">
          Post your batches, respond to offers and complete your deals. Build
          your network and follow their activity.
        </Text>
      </Box>
      <Grid
        templateColumns={["1fr", "7fr 3fr"]}
        columnGap="0.6rem"
        mt={"-3.5rem"}
        mx={["2.5rem"]}
      >
        <FeaturedContainer
          d="flex"
          flexDir="column"
          justifyContent="space-around"
          p="1.5rem"
        >
          <Box
            d={"flex"}
            justifyContent="space-between"
            alignItems="center"
            flexWrap={["wrap", "nowrap"]}
          >
            <ActionComponent
              primaryText={batchesRequireAction}
              secondaryText={"Batches require action"}
              seperator={true}
            />
            <ActionComponent
              primaryText={offersRequireResponse}
              secondaryText={"Offers require response"}
              seperator={true}
              showSeperatorOnMobile={false}
            />
            <ActionComponent
              primaryText={dealsInProgress}
              secondaryText={"Deals in progress"}
              seperator={true}
            />
            <ActionComponent
              primaryText={dealersRequireOnboarding}
              secondaryText={"Dealers require onboarding"}
            />
          </Box>
          <Box d="flex" alignItems="center" flexDir={["column", "row"]}>
            <Box
              mt={["0.5rem", "0"]}
              w={["100%", "45%", "45%", "45%", "50%"]}
              h="1.7rem"
              borderRadius="22px"
              borderWidth="1px"
              borderColor="text.400"
              opacity="0.3"
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              px="0.5rem"
            >
              <Box as={FaSearch} w="18px" h="18px" />
              <Input
                fontSize="18px"
                ml="0.5rem"
                variant="flushed"
                borderWidth="0"
                h="18px"
                placeholder="Search dealers or batches..."
                onChange={e => onSearchChange(e.target.value)}
                p={[0, 0, 0, 0]}
              />
            </Box>
            <Box
              w={["100%", "50%"]}
              d="flex"
              justifyContent={["space-between", "flex-end"]}
              ml="auto"
              mt={["1rem", 0]}
            >
              <Button minWidth={[]} onClick={navigateToCreate}>
                Create Batch
              </Button>
              <Button ml="0.5rem" onClick={onOpen}>
                Invite Dealers
              </Button>
            </Box>
          </Box>
        </FeaturedContainer>
        <SalesRepresentative representativeType="network" representative={user} onEditNetworkClicked={openEditNetworkNameModal} />
      </Grid>
      <Box mx="2.5rem" mt="2rem">
        {
          searchKeyword && <Skeleton mb="2.5rem" isLoaded={!loading} borderRadius={"10px"}>
            <Text type="header">Search Results</Text>
            <Text type="bodyLight">{totalResults} results match your search</Text>
          </Skeleton>
        }
        <Filters
          generalFilters={generalFilters}
          selectedGeneral={selectedFilter || "Batches"}
          onSelectedGeneral={navigateToFilter}
        />
        <Switch>
          <Route path="/dashboard/batches" component={Batches} />
          <Route path="/dashboard/offers" component={OffersList} />
          <Route path="/dashboard/deals" component={Deals} />
          <Route path="/dashboard/network" component={Network} />
          <Route path="/dashboard/activityLog" component={ActivityLog} />
        </Switch>
      </Box>
      {isOpen && <InviteDealers isOpen={isOpen} onClose={onClose} />}
      {isEditNetworkModalOpened && (
        <Modal isOpen={isEditNetworkModalOpened} onClose={() => setIsEditNetworkModalOpened(false)} showFooter={false}>
          <Text my="1rem" type="header">Edit Network Name</Text>
          <FormControl>
            <FormLabel htmlFor="network-name">Network Name</FormLabel>
            <Input variantColor="purple" variant="flushed" type="network-name" id="network-name" aria-describedby="network-name-helper-text" value={company} onChange={e => setCompany(e.target.value)} />
          </FormControl>
          <Box d="flex" justifyContent="space-between" alignItems="center" my="1rem">
            <Button variantColor="gray" backgroundColor="gray.100" color="text.500" onClick={() => setIsEditNetworkModalOpened(false)}>Cancel</Button>
            <Button onClick={editNetworkName}>Save</Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default MyDashboard;
