import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications as getNotificationsActions } from "../../actions/notificationsActions";

import { Box, Image, Skeleton, Spinner } from "@chakra-ui/core";
import Text from "../../common/Text";
import { FaEllipsisH } from "react-icons/fa";

import moment from "moment";

const Notification = ({ image, message, create_date, state }) => {
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      p="0.5rem"
      borderBottom="1px solid #70707017"
      cursor="pointer"
    >
      <Box d="flex" alignItems="center">
        <Box
          height={["2.5rem", "2.5rem", "2.5rem", "1.5rem"]}
          width={["2.5rem", "2.5rem", "2.5rem", "1.5rem"]}
          rounded="full"
          d="flex"
          justifyContent="center"
          alignItems="center"
          className="image-border-gradient"
          m="auto"
        >
          <Image
            objectFit="cover"
            rounded="full"
            size={["2.4rem", "2.4rem", "2.4rem", "1.4rem"]}
            src={
              image ||
              "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/The_Blacklist_-_James_Spader_%28cropped%29.jpg/220px-The_Blacklist_-_James_Spader_%28cropped%29.jpg"
            }
            alt="user"
          />
        </Box>
        <Box ml="1rem">
          <Text
            type="secondaryBodyRegular"
            fontSize={["1rem", "1rem", "1rem", "0.6rem"]}
          >
            {message || ""}
          </Text>
          <Text
            fontSize={["0.5rem", "0.5rem", "0.5rem", "9px"]}
            fontWeight="light"
            color="text.500"
          >
            {moment(new Date(create_date) || new Date()).fromNow()}
          </Text>
        </Box>
      </Box>
      <Box
        backgroundColor={state == "unread" ? "purple.500" : "white"}
        width="8px"
        height="8px"
        borderRadius="100%"
      />
    </Box>
  );
};

const NotificationsList = ({ ref }) => {
  const store = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  useEffect(function () {
    if (store.objects.length == 0) dispatch(getNotificationsActions());
  }, []);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && store.per_page > store.pages && !store.loading) {
      dispatch(getNotificationsActions({ page: store.page + 1 }));
    }
  };

  return (
    <Box bg="white" borderRadius="10px">
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        p="0.5rem"
      >
        <Text
          type="header"
          fontSize={["1.5rem", "1.5rem", "1.5rem", "0.8rem"]}
          ref={ref}
        >
          Notifications
        </Text>
      </Box>
      <Box
        overflowY="auto"
        minHeight="10rem"
        maxHeight="10rem"
        onScroll={handleScroll}
        textAlign="center"
      >
        {!store.loading && store.objects.length == 0 && (
          <Text type="largeBodyLight">You have no notifications available</Text>
        )}
        {store.loading && store.objects.length == 0 ? (
          <>
            <Skeleton h="2rem" m="0.5rem"></Skeleton>
            <Skeleton h="2rem" m="0.5rem"></Skeleton>
            <Skeleton h="2rem" m="0.5rem"></Skeleton>
          </>
        ) : (
            store.objects.map((notification) => (
              <Notification {...notification} key={notification.id} />
            ))
          )}
        {store.loading && store.objects.length > 0 && <Spinner />}
      </Box>
    </Box>
  );
};

export default NotificationsList;
