import React from "react";

import {
  Box,
  Text,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Button,
} from "@chakra-ui/core";

import NotificationsList from "./Notifications";

import { FaBell, FaBars, FaUser, FaPowerOff } from "react-icons/fa";
import Logo from "./Logo";
import { Link } from "react-router-dom";

import firebase from "../../common/firebase";
import { useSelector } from "react-redux";

import Male from "../../assets/images/Male.png";
import MobileLogo from "../../assets/images/mobile-logo.png";

const Header = ({ openSidebar, toNotifications, toMarketplace, toProfile }) => {
  const isCurrentScreen =
    window.location.pathname.split("/")[1] === "dashboard";
  const initialFocusRef = React.useRef();
  const initialFocusRefImage = React.useRef();

  let user = useSelector((state) => state.auth.user);

  return (
    <Box
      w="100vw"
      h={["5rem", "4.2rem"]}
      bg="surface"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 3px 15px #00000029"
      position="relative"
    >
      <Box
        display={["none", "none", "none", "block"]}
        onClick={toMarketplace}
        cursor="pointer"
      >
        <Logo />
      </Box>
      <Box
        onClick={openSidebar}
        as={FaBars}
        size={["1.5rem", "1rem"]}
        color="text.500"
        ml={"1.5rem"}
        display={["block", "block", "block", "none", "none"]}
      />
      <Box
        onClick={toMarketplace}
        display={["block", "block", "block", "none", "none"]}
      >
        <Logo logo={MobileLogo} imgH="3rem" imgMl={0} />
      </Box>

      <Box h="100%" d="flex" alignItems="center">
        <Text
          as={Link}
          to="/dashboard/batches"
          fontSize={["1.2rem", "0.8rem"]}
          fontWeight={isCurrentScreen ? "bold" : "normal"}
          color={isCurrentScreen ? "purple.500" : "text.500"}
          mr={"1.5rem"}
        >
          {user.is_seller && "My Dashboard"}
        </Text>
        <Popover placement="bottom-end" initialFocusRef={initialFocusRef}>
          <PopoverTrigger>
            <Button
              mr="1.5rem"
              backgroundColor="none"
              color="text.500"
              _focus={{ background: "none" }}
              _hover={{ background: "none" }}
              display={["none", "none", "none", "block"]}
            >
              <FaBell size={"1rem"} />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            borderColor="white"
            bg="white"
            width={"15rem"}
            maxHeight="14rem"
            boxShadow="0px 3px 15px #0000000D"
            borderRadius="10px"
          >
            <PopoverArrow />
            <Button ref={initialFocusRef} display="none" />
            <NotificationsList ref={null} />
          </PopoverContent>
        </Popover>
        <Box
          as={FaBell}
          size={["1.5rem", "1rem"]}
          color="text.500"
          mr={"1.5rem"}
          display={["block", "block", "block", "none"]}
          onClick={toNotifications}
        />
        <Popover placement="bottom" initialFocusRef={initialFocusRefImage}>
          <PopoverTrigger>
            <Image
              mr={"2.5rem"}
              objectFit="cover"
              rounded="full"
              size="2.5rem"
              src={user?.image?.url || Male}
            />
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            borderColor="white"
            bg="white"
            maxW={["9rem", "4.5rem"]}
            boxShadow="0px 3px 15px #0000000D"
            borderRadius="10px"
          >
            <PopoverArrow />
            <Button ref={initialFocusRefImage} display="none" />
            <Options toProfile={toProfile} />
          </PopoverContent>
        </Popover>
      </Box>
    </Box>
  );
};

const Options = ({ toProfile }) => {
  const logOut = () => {
    localStorage.clear();
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <Box
      fontSize={["1rem", "0.5rem"]}
      d="flex"
      flexDir="column"
      alignItems="center"
      padding="0.5rem"
    >
      <Box
        d="flex"
        justifyContent="flex-start"
        alignItems="center"
        w="100%"
        onClick={toProfile}
        cursor="pointer"
      >
        <Box as={FaUser} color="gray.100" />
        <Text ml="0.5rem" fontW="bold">
          My Profile
        </Text>
      </Box>
      <Box w="100%" h="1px" backgroundColor="gray.100" m="0.3rem" />
      <Box
        d="flex"
        justifyContent="flex-start"
        alignItems="center"
        w="100%"
        onClick={logOut}
        cursor="pointer"
      >
        <Box as={FaPowerOff} color="gray.100" />
        <Text ml="0.5rem" fontW="bold">
          Log out
        </Text>
      </Box>
    </Box>
  );
};

export default Header;
