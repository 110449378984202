import React from "react";
import { Box, Button, Text } from "@chakra-ui/core";
import { MdKeyboardBackspace } from "react-icons/md";

const Pagination = ({ pages = 1, currentPage = 1, setPage }) => {
  const previousPage = () => {
    setPage(currentPage - 1);
  };

  const nextPage = () => {
    setPage(currentPage + 1);
  };

  const toPage = (page) => setPage(page);

  return (
    <Box d="flex" justifyContent="center" alignItems="center" my="1rem">
      <Button
        boxShadow={["0px 3px 7px #1F1F1F1A", "0px 3px 15px #0000000D"]}
        bg="white"
        borderRadius="100%"
        minWidth={["2rem", "1.5rem"]}
        minHeight={["2rem", "1.5rem"]}
        height={["2rem", "1.5rem"]}
        width={["2rem", "1.5rem"]}
        padding={0}
        isDisabled={currentPage == 1}
        onClick={previousPage}
      >
        <MdKeyboardBackspace />
      </Button>
      {Array.from(Array(pages)).map((page, index) => (
        <Text
          fontSize={["1.2rem", "0.7rem"]}
          paddingX="1rem"
          key={index}
          color={index + 1 == currentPage ? "purple.500" : "text.500"}
          cursor="pointer"
          onClick={() => toPage(index + 1)}
        >
          {index + 1}
        </Text>
      ))}
      <Button
        boxShadow={["0px 3px 7px #1F1F1F1A", "0px 3px 15px #0000000D"]}
        bg="white"
        borderRadius="100%"
        minWidth={["2rem", "1.5rem"]}
        minHeight={["2rem", "1.5rem"]}
        height={["2rem", "1.5rem"]}
        width={["2rem", "1.5rem"]}
        padding={0}
        isDisabled={currentPage == pages}
        onClick={nextPage}
      >
        <MdKeyboardBackspace transform="rotate(180)" />
      </Button>
    </Box>
  );
};

export default Pagination;
