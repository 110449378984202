import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/core";

import Text from "../../common/Text";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Select from "../../common/Select";
import DatePicker from "react-datepicker";

import { FaChevronDown } from "react-icons/fa";
import { myDashboard } from "../../api";

import Checkbox from "../../common/CheckBox";
import validate from "validate.js";

import staticData from "../../common/meta/static.json";
import batchTypes from "../../common/meta/batchTypes";

import { useSelector, useDispatch } from "react-redux";
import {
  getBatch,
  setBatch as setBatchReducer,
} from "../../actions/dashboardActions";

const validationConstraints = {
  title: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  description: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  type: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  expiry_date: {
    presence: true,
  },
};

const CustomDateInput = ({ value, onClick }) => {
  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      minH={["1.7rem", "1.5rem"]}
      borderRadius="22px"
      paddingBottom={0}
      w={["100%", "7.5rem"]}
      fontSize={["0.8rem", "0.5rem"]}
      color="#38436480"
      mb="1.5rem"
      borderColor="gray"
      borderWidth="1px"
      d="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box ml="0.5rem">{value || "Please select date"}</Box>
      <Box as={FaChevronDown} mr="1rem" />
    </Box>
  );
};

const TypeSelect = ({ batch, onChangeBatch }) => {
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      mb="1.5rem"
      overflowX={["auto", "hidden"]}
      h="2.5rem"
    >
      {Object.keys(batchTypes).map((type) => {
        const isSelected = batch.type === type;
        return (
          <Button
            onClick={() => onChangeBatch("type", type)}
            key={type}
            variantColor={isSelected ? "purple" : "gray"}
            color={"white"}
            minWidth={["auto", "3rem"]}
            p={["1rem"]}
            h={["2.1rem", "1.92rem"]}
            boxShadow="none"
            mr={["1rem", 0]}
          >
            {batchTypes[type]}
          </Button>
        );
      })}
    </Box>
  );
};

const BatchForm = ({ history, match }) => {
  const currentBatch = useSelector(
    (state) => state.dashboard.currentBatch.object
  );
  const dispatch = useDispatch();
  const [batch, setBatch] = useState({
    title: "",
    description: "",
    type: "new",
    show_prices: true,
    location: "",
    expiry_date: null,
    global_access: true,
  });
  useEffect(() => {
    let { batchId } = match.params;
    if (batchId && currentBatch && currentBatch.id == batchId) {
      setBatch(currentBatch);
    } else if (batchId) {
      dispatch(getBatch(batchId));
    }
  }, [currentBatch]);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const isEditing = batch.id;

  const onChangeBatch = (key, value) => {
    setBatch({
      ...batch,
      [key]: value,
    });
  };

  const onSubmit = () => {
    let validationErrors = validate(batch, validationConstraints);
    if (!batch.expiry_date) {
      if (!validationErrors) validationErrors = {};
      validationErrors.expiry_date = "Expiration date is required"
    }
    else if (new Date(batch.expiry_date) < Date.now()) {
      if (!validationErrors) validationErrors = {};
      validationErrors.expiry_date = "Date must be in future"
    }

    console.log(validationErrors)

    setErrors(validationErrors);

    if (!validationErrors) {
      setIsLoading(true);
      if (isEditing) {
        let editBatch = {
          title: batch.title,
          description: batch.description,
          type: batch.type,
          show_prices: batch.show_prices,
          location: batch.location,
          expiry_date: batch.expiry_date,
          global_access: batch.global_access,
        };
        myDashboard.editBatch(batch.id, editBatch).then((res) => {
          if (res && res.success) {
            dispatch(setBatchReducer(res));
            history.push("/dashboard/batches/" + res.object.id);
          }
          setIsLoading(false);
        });
      } else {
        myDashboard.createBatch(batch).then((res) => {
          if (res && res.success) {
            history.push("/dashboard/batches/" + res.object.id);
          }
          setIsLoading(false);
        });
      }
    }
  };

  const goBack = () => {
    let { batchId } = match.params;
    isEditing
      ? history.push(`/ dashboard / batches / ${batchId} `)
      : history.push("/dashboard/batches");
  };

  return (
    <Box>
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text type="header" color="white">
          {isEditing ? "Edit" : "Create"} Batch
        </Text>
        <Text
          as={Link}
          to={
            isEditing ? `/dashboard/batches/${batch.id}` : "/dashboard/batches"
          }
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
        >
          ‹ Back to{" "}
          {isEditing ? "Batch Details" : "Solution404 Used Cars & Vehicles"}
        </Text>
      </Box>
      <Grid
        mx="2.5rem"
        mt="-2rem"
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
        p="1rem"
        px="1.5rem"
        bg="white"
        templateColumns={["1fr", "3fr 2fr"]}
        columnGap="2rem"
        d={["block", "grid"]}
      >
        <Box>
          <Text type="header" mb="1.5rem">
            {isEditing ? "Edit" : "Create"} Batch
          </Text>
          <Text type="secondaryBodyBold" mb="0.5rem">
            Type of vehicles
          </Text>
          <Text type="bodyLight" mb="1rem">
            Indicate the type of vehicles this batch contains.
          </Text>

          <TypeSelect batch={batch} onChangeBatch={onChangeBatch} />

          <FormControl mb="1.5rem" isInvalid={errors && errors.title}>
            <FormLabel
              color="text.500"
              fontWeight="bold"
              minHeight="1.5rem"
              fontSize={["0.8rem", "0.6rem"]}
            >
              Title
            </FormLabel>
            <Input
              onChange={(e) => onChangeBatch("title", e.target.value)}
              value={batch.title}
              variant="flushed"
              placeholder="For example “Large selection of ex-rental 2017 Nissan models now available"
              fontSize={["1rem", "0.5rem"]}
            />
            {errors && errors.title && (
              <FormErrorMessage>Title is requred</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mb="1.5rem" isInvalid={errors && errors.description}>
            <FormLabel
              color="text.500"
              fontWeight="bold"
              minHeight="1.5rem"
              fontSize={["0.8rem", "0.6rem"]}
            >
              Description
            </FormLabel>
            <Textarea
              focusBorderColor="purple.500"
              variantColor="purple"
              onChange={(e) => onChangeBatch("description", e.target.value)}
              value={batch.description}
              fontSize={["1rem", "0.5rem"]}
              minH={["6rem", "4rem"]}
              placeholder="Provide additional general information about the vehicles in this batch, such as their age, source, condition, etc"
              _placeholder={{ color: "text.500", opacity: 0.5 }}
              px="0.5rem"
            />
            {errors && errors.description && (
              <FormErrorMessage>Description is requred</FormErrorMessage>
            )}
          </FormControl>
          <Text type="secondaryBodyBold" mb="0.5rem">
            Prices
          </Text>
          <Text type="bodyLight" mb="1rem">
            Indicate if you would like to show buyers the vehicle prices (if
            provided).
          </Text>
          <Checkbox
            isChecked={batch.show_prices}
            onChange={(e) => onChangeBatch("show_prices", e.target.checked)}
          >
            <Text fontSize={["0.8rem", "0.6rem"]}>
              Show vehicle prices on listings
            </Text>
          </Checkbox>
        </Box>
        <Box pt={["1rem", "3rem"]} minW={0}>
          <Box
            d="flex"
            flexDirection={["row", "column"]}
            alignItems={["center", "flex-start"]}
          >
            <Text type="secondaryBodyBold" mb="0.5rem" w={["40%", "auto"]}>
              Expiration Date
            </Text>
            <Text type="bodyLight" mb="0.5rem" d={["none", "block"]}>
              Select the expiration date for this batch. <br />
              After it expires you can post it again.
            </Text>
            <FormControl
              isInvalid={errors && errors.expiry_date}
              height="min-content"
              w="60%"
              ml={["1rem", 0]}
            >
              <DatePicker
                selected={
                  typeof batch.expiry_date === "string"
                    ? new Date(batch.expiry_date)
                    : batch.expiry_date
                }
                filterDate={(date) => date > Date.now()}
                onChange={(date) => onChangeBatch("expiry_date", date)}
                customInput={<CustomDateInput />}
              />
              {errors && errors.expiry_date && (
                <FormErrorMessage mt={"-1rem"}>
                  {errors.expiry_date}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box
            d="flex"
            flexDirection={["row", "column"]}
            alignItems={["center", "flex-start"]}
          >
            <Box d="flex" alignItems="center" mb="0.5rem" w="40%">
              <Text type="secondaryBodyBold">Location</Text>
              <Text type="bodyLight" ml="0.5rem">
                (Optional)
              </Text>
            </Box>
            <Select
              placeholder="Please select location"
              variant="outline"
              minH="1.5rem"
              borderRadius="22px"
              paddingBottom={0}
              w={["60%", "7.5rem"]}
              fontSize={["0.8rem", "0.5rem"]}
              color="#38436480"
              mb="1.5rem"
              ml={["1rem", 0]}
              value={batch.location}
              onChange={(e) => onChangeBatch("location", e.target.value)}
            >
              {staticData.countries.map((country) => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </Select>
          </Box>
          <Text type="secondaryBodyBold" mb="0.5rem">
            Distribution
          </Text>
          <Text type="bodyLight" mb="0.5rem">
            Choose to post this batch to the SeezOne global market (public), in
            addition to your network (private).
          </Text>

          <Checkbox
            color="#38436480"
            isChecked={batch.global_access}
            onChange={(e) => onChangeBatch("global_access", e.target.checked)}
          >
            <Text fontSize={["0.8rem", "0.6rem"]}>
              SeezOne global marketplace
            </Text>
          </Checkbox>
          <br />
          <Checkbox isChecked={true} isDisabled>
            <Text fontSize={["0.8rem", "0.6rem"]}>
              Solution404 Used Cars & Vehicles
            </Text>
          </Checkbox>

          <Box d="flex" mt="4rem">
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              w="100%"
              minW={0}
              onClick={onSubmit}
            >
              {isEditing ? "Save Changes" : "Create Batch"}
            </Button>
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              w="100%"
              minW={0}
              variantColor="gray"
              color="white"
              ml="1rem"
              onClick={goBack}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default BatchForm;
