export const headers = (isFormData = false) => ({
  Accept: "application/json",
  "Content-Type": isFormData ? "multipart/form-data" : "application/json",
});

export const authHeaders = (token, isFormData) => ({
  "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});
