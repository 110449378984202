import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/core";
import { MdChevronRight } from "react-icons/md";

import moment from "moment";
import Text from "../../common/Text";
import Button from "../../common/Button";
import { currencyFormat } from "../../common/utils";

const OfferStates = (props) => {
  const findType = () => {
    if (!props.offer.is_counter) {
      switch (props.type) {
        case "pending":
          return <Pending {...props} />;
        case "rejected":
          return <Rejected {...props} />;
        case "withdrawn":
          return <Withdrawn {...props} />;
        case "accepted":
          return <Accepted {...props} />;
        default:
          return null;
      }
    } else {
      return <Countered {...props} />;
    }
  };

  return props.modifying ? <Modifying {...props} /> : findType();
};

const Modifying = ({ loading, update, vehicles, isChanged }) => {
  const calculateCurrentOffer = () => {
    let value = 0;
    let vehiclesCount = 0;
    vehicles.forEach((vehicle) => {
      if (vehicle.offerMade) {
        value += Number(vehicle.offerMade);
        vehiclesCount++;
      }
    });
    return { value, vehicles: vehiclesCount };
  };
  const currentOffer = calculateCurrentOffer();

  return (
    <Box p="1rem" pl="2rem" w="100%">
      <Text type="header" mb="0.5rem">
        Modify Counter-Offer
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        Modify this offer to make a counter-offer to the buyer
      </Text>
      <Box float="right" d="flex" alignItems="center">
        <Text mr="1rem" type="largeBodyRegular">
          {currentOffer.vehicles} vehicles
        </Text>
        <Button variantColor={"gray"} mr="1rem">
          {currentOffer.value > 0 && isChanged
            ? currencyFormat(currentOffer.value)
            : "-/-"}
        </Button>
        <Button isLoading={loading} onClick={update} isDisabled={!isChanged}>
          Counter Offer
        </Button>
      </Box>
    </Box>
  );
};

const Pending = ({ loading, modify, decline, accept }) => {
  return (
    <Box p="1rem" w="100%">
      <Text type="header" mb="0.5rem">
        Offer Received
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        You have received a new offer. Please review and respond.
      </Text>
      <Box
        d="flex"
        flexDir={["column", "row"]}
        justifyContent={["space-between", "flex-end"]}
        alignItems={["flex-end", "center"]}
        w="100%"
      >
        <Box>
          <Button
            mt={["1rem", 0]}
            bg="success"
            onClick={modify}
            isLoading={loading}
            onClick={accept}
            float={["right", "none"]}
          >
            Accept Offer
          </Button>
          <Button
            mt={["1rem", 0]}
            bg="error"
            ml={[0, "1rem"]}
            onClick={decline}
            isLoading={loading}
            float={["right", "none"]}
          >
            Decline Offer
          </Button>
        </Box>
        <Button
          mt={["1rem", 0]}
          ml={["0", "1rem"]}
          onClick={modify}
          isLoading={loading}
        >
          Counter Offer{" "}
        </Button>
      </Box>
    </Box>
  );
};

const Accepted = ({ offer }) => {
  return (
    <Box w="100%" h="100%">
      <Box p="1rem" h="70%">
        <Text type="header" mb="0.5rem" color="success">
          Offer Accepted
        </Text>
        <Text type="secondaryBodyLight" mb="1rem">
          This offer was accepted by both parties. You can view it in Deals.
        </Text>
      </Box>
      <Box bg="gray.400" w="100%" h="30%" d="flex" alignItems="center">
        <Text
          as={Link}
          to={
            offer.deal?.id
              ? `/dashboard/deals/details/${offer.deal.id}`
              : "/dashboard/deals"
          }
          color="purple.500"
          h={["3rem", "30%"]}
          minHeight="2rem"
          d="flex"
          alignItems="center"
          type="secondaryBodyBold"
          ml="1rem"
        >
          Continue to Deal Details{" "}
          <Box as={MdChevronRight} fontWeight="light" />
        </Text>
      </Box>
    </Box>
  );
};

const Rejected = () => {
  return (
    <Box p="1rem">
      <Text type="header" mb="0.5rem" color="error">
        Offer Declined
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        You declined this offer.
      </Text>
    </Box>
  );
};

const Countered = ({ offer }) => {
  return (
    <Box
      p="1rem"
      pb="2rem"
      d="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Text type="header"> Counter-Offer Made </Text>
      <Text type="secondaryBodyLight">
        Offer #{offer.id} {moment(offer.update_date).fromNow()}
      </Text>
      <Text type="header" mt="0.5rem">
        {offer.vehicle_offers.length} Vehicles / {currencyFormat(offer.price)}
      </Text>
    </Box>
  );
};

const Withdrawn = () => {
  return (
    <Box p="1rem">
      <Text type="header" mb="0.5rem" color="error">
        Offer Withdrawn
      </Text>
      <Text type="secondaryBodyLight" mb="1rem">
        The buyer withdrew this offer.
      </Text>
    </Box>
  );
};

export default OfferStates;
