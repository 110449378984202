import typeToReducer from "type-to-reducer";
import {
  SET_USER,
  REGISTER_USER,
  SET_TOKEN,
  GET_SET_TOKEN,
  EDIT_CURRENT_USER
} from "../constants";

const initalState = {
  user: {},
  error: {},
  idToken: null,
  loading: false,
  isRejected: false,
};

const reducer = typeToReducer(
  {
    [SET_USER]: (state, action) => ({
      ...state,
      user: { ...state.user, ...action.payload },
    }),
    [SET_TOKEN]: (state, action) => ({
      ...state,
      idToken: action.payload,
    }),
    [REGISTER_USER]: {
      PENDING: (state) => ({
        ...state,
        loading: true,
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        user: { ...state.user, ...action.payload },
      }),
    },
    [GET_SET_TOKEN]: {
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
        idToken: null,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        error: {},
        idToken: action.payload,
      }),
    },
    [EDIT_CURRENT_USER]: {
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        loading: false,
        error: action.payload,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        loading: false,
        isRejected: false,
        error: {},
        user: action.payload.object,
      }),
    },
  },
  initalState
);

export default reducer;
