import React from "react";

import Sections from "./Sections";

import sections from "./termsSections";

const TermsConditions = () => {
  return <Sections sections={sections} title="Terms and Conditions" />;
};

export default TermsConditions;
