import React from "react";
import ErrorPage from "./ErrorPage";

const NotFound = ({ history }) => {
  return (
    <ErrorPage
      title="Access Denied/Forbidden"
      description="You do not have permission to access this page"
    />
  );
};

export default NotFound;
