import React from "react";

import { Box } from "@chakra-ui/core";

const TagOutline = ({ text, ...other }) => {
  return (
    <Box
      width={["6.7rem", "3.7rem"]}
      height={["1.8rem", "1.3rem"]}
      borderWidth={1}
      borderColor="purple.500"
      color={"purple.500"}
      fontWeight="medium"
      d="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="25px"
      fontSize={["1.1rem", "0.6rem"]}
      textTransform="capitalize"
      {...other}
    >
      {text}
    </Box>
  );
};

export default TagOutline;
