import React, { useState } from "react";
import { Box, Button, FormControl, FormErrorMessage } from "@chakra-ui/core";
import { Link } from "react-router-dom";

import { FaLock, FaCheck } from "react-icons/fa";

import Header from "../login/Header";
import firebase from "../../common/firebase";
import CustomButton from "../../common/Button";
import Input from "../../common/Input";
import Text from "../../common/Text";

import constraints from "../../common/generalConstraints";
import validate from "validate.js";

const PasswordSent = ({ backToLogin }) => {
  return (
    <Box
      className="gradient-background"
      h="100vh"
      w="100vw"
      px={["2rem", "5.4rem"]}
    >
      <Header />
      <Box
        ml={"auto"}
        mr={"auto"}
        mt={["2rem", 0]}
        borderRadius={10}
        bg="white"
        w={["calc(100% - 1rem)", "19rem"]}
        borderRadius={10}
        bg="white"
        d="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <Box
          mt={["2rem", 143]}
          w={"2.9rem"}
          h={"2.9rem"}
          borderRadius={"2.9rem"}
          bg="purple.500"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box as={FaCheck} size="1.25rem" color="white" bg="purple.500"></Box>
        </Box>
        <Text mt={25} type="title" color="text.500">
          Forgot Password?
        </Text>

        <Text
          mt={11}
          type="secondaryBodyLight"
          color="purple.400"
          textAlign="center"
        >
          A reset link has been sent to your email
        </Text>

        <Button
          as={Link}
          to="/login"
          mt={"5.26rem"}
          w={["100%", "19rem"]}
          h={["4rem", "2.4rem"]}
          bg="surface"
          borderBottomLeftRadius={10}
          borderBottomRightRadius={10}
          onClick={backToLogin}
        >
          <Text type="secondaryBodyBold" opacity={0.5}>
            Back to Login
          </Text>
        </Button>
      </Box>
    </Box>
  );
};

const ForgotPassword = ({ }) => {
  const [isDone, setIsDone] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e && e.preventDefault();
    const validationErrors = validate({ email }, constraints);

    if (validationErrors) {
      setErrors({ ...validationErrors });
    } else {
      setIsLoading(true);
      sendResetPassword();
    }
  };

  const sendResetPassword = () => {
    var auth = firebase.auth();

    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        setIsLoading(false);
        setIsDone(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        setErrors({
          email: ["There is no user record corresponding to this identifier."],
        });
      });
  };

  if (isDone) return <PasswordSent />;

  return (
    <Box
      className="gradient-background"
      h="100vh"
      w="100vw"
      px={["2rem", "5.4rem"]}
    >
      <Header />
      <Box
        ml={"auto"}
        mr={"auto"}
        mt={["2rem", 0]}
        borderRadius={10}
        bg="white"
        w={["calc(100% - 1rem)", "19rem"]}
        className="forgot-password-screen"
      >
        <Box
          px={["2rem", "3rem"]}
          d="flex"
          as="form"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          onSubmit={handleSubmit}
        >
          <Box
            mt={"1.35rem"}
            w={["4rem", "2.9rem"]}
            h={["4rem", "2.9rem"]}
            borderRadius={["4rem", "2.9rem"]}
            bg="purple.500"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box as={FaLock} size="1.25rem" color="white" bg="purple.500"></Box>
          </Box>
          <Text
            mt={25}
            type="title"
            fontWeight="bold"
            color="text.500"
            isLoading={isLoading}
          >
            Forgot Password?
          </Text>

          <Text mt={11} type="secondaryBodyLight" textAlign="center">
            Enter your registered email address and we will send you a new
            password.
          </Text>
          <FormControl
            isDisabled={isLoading}
            isInvalid={errors && errors.email}
            w={"90%"}
          >
            <Input
              autoFocus
              variant="flushed"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter email address..."
              type="email"
              className="forgot-password-email-input"
              my={"1.35rem"}
            />
            {errors &&
              errors.email &&
              errors.email.map((message) => (
                <FormErrorMessage
                  fontSize={15}
                  className="forgot-password-error-message"
                  key={message}
                >
                  {message}
                </FormErrorMessage>
              ))}
          </FormControl>
          <CustomButton
            variantColor="purple"
            type="submit"
            isLoading={isLoading}
            className="reset-password-button"
          >
            Reset Password
          </CustomButton>
        </Box>
        <Button
          as={Link}
          to="/login"
          mt={"1.8rem"}
          w={["100%", "19rem"]}
          h={["4rem", "2.4rem"]}
          bg="surface"
          borderBottomLeftRadius={10}
          borderBottomRightRadius={10}
        >
          <Text type="secondaryBodyBold" opacity={0.5}>
            Back to Login
          </Text>
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
