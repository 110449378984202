import React from "react";
import { Box, Image, Text } from "@chakra-ui/core";

import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import logo from "../../assets/images/logo.png";

const LandingHeader = (props) => {
  return (
    <Box h={"7rem"} w="100%" {...props}>
      <Box
        pt={"2rem"}
        h="100%"
        d="flex"
        alignItems="center"
        justifyContent={["space-between", "flex-start"]}
      >
        <Image src={logo} w="8rem" objectFit="contain"></Image>
        <Box
          as={FaBars}
          size="2.5rem"
          color="white"
          display={["block", "none"]}
        />
        <Box
          ml="5.4rem"
          d={["none", "flex"]}
          w="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          pb="1rem"
        >
          <Box d="flex">
            <Text
              as={Link}
              to="/"
              fontWeight="bold"
              fontSize="0.7rem"
              color="white"
            >
              How It Works
            </Text>
            <Text
              as={Link}
              to="/faq"
              fontWeight="bold"
              fontSize="0.7rem"
              color="white"
              ml="2.4rem"
            >
              FAQs
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight="bold"
              fontSize="1rem"
              color="white"
              as={Link}
              to={"/login"}
            >
              Login
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingHeader;
