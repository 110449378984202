import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import { offers as offersApi, batches as batchesApi } from "../../api";
import useFetchHook from "../../common/hooks/useFetchHook";
import { currencyFormat } from "../../common/utils";
import moment from "moment";

import { Box, Grid, Skeleton, useToast } from "@chakra-ui/core";

import BatchInfo from "../../common/BatchInfo";
import FeaturedContainer from "../../common/FeaturedContainer";
import OfferSummaryModal from "../../common/OfferSummaryModal";
import OfferStates from "./OfferStates";
import CounterOfferStates from "./CounterOfferStates";
import Filters from "../../common/Filters";
import CarRow from "../../common/CarRow";
import Text from "../../common/Text";

import makes from "../../common/meta/makes.json";
import years from "../../common/meta/years.js";

import {
  offersVariantsMapping as variantsMapping,
  offersLayoutMapping as layoutMapping,
  offersCounterLayoutMapping as counterLayoutMapping,
} from "../../common/mappings";

const OfferDetails = ({ history, match }) => {
  const { offerId } = match.params;
  const [offer, dispatchOffer] = useFetchHook();
  const [vehicles, dispatchVehicles] = useFetchHook();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modifying, setModifying] = useState(false);
  const [detailedFilters, setDetailedFilters] = useState([
    { name: "Make", options: Object.keys(makes), selected: "" },
    { name: "Model", options: [], selected: "" },
    { name: "Year", options: years, selected: "" },
  ]);
  const [isRespondingToCounter, setIsRespondingToCounter] = useState(false);
  const toast = useToast();

  useEffect(function init() {
    dispatchOffer({ type: "pending" });
    offersApi.getSingle(offerId).then((response) => {
      if (response.object.is_counter) {
        offersApi.getVehicles(response.object.id).then((vehicle_response) => {
          response.object.counter_vehicle_offers = vehicle_response.objects;
          dispatchOffer({ type: "fulfilled", payload: response.object });
        });
      } else {
        dispatchOffer({ type: "fulfilled", payload: response.object });
      }
    });
  }, []);

  const selectDetailedFilter = (filterIndex, value) => {
    const newDetailedFilter = [...detailedFilters];
    newDetailedFilter[filterIndex].selected = value;
    if (filterIndex === 0 && value.length > 0) {
      newDetailedFilter[1].options = makes[value].models;
    }
    setDetailedFilters(newDetailedFilter);
    getBatchVehicles();
  };

  const modifyOffer = () => getBatchVehicles(true);

  const addToOffer = (index, offerMade) => {
    const _vehicles = [...vehicles.list];
    _vehicles[index].offerMade = offerMade;
    dispatchVehicles({ type: "set", payload: _vehicles });
  };

  const removeFromOffer = (index) => {
    const _vehicles = [...vehicles.list];
    delete _vehicles[index].offerMade;
    dispatchVehicles({ type: "set", payload: _vehicles });
  };

  const updateOffer = () => {
    const vehicle_offers = [];
    vehicles.list.forEach((vehicle) => {
      if (vehicle.offerMade > 0) {
        vehicle_offers.push({
          vehicle_ids: [vehicle.id],
          price: vehicle.offerMade,
        });
      }
    });
    dispatchVehicles({ type: "pending" });
    offersApi.modify(offerId, vehicle_offers).then((response) => {
      setModifying(false);
      toggleModal();
      dispatchVehicles({ type: "set", payload: [] });
      dispatchOffer({ type: "fulfilled", payload: response.object });
    });
  };

  const getBatchVehicles = () => {
    const params = {
      make: detailedFilters[0].selected,
      model: detailedFilters[1].selected,
      year: detailedFilters[2].selected,
    };
    dispatchVehicles({ type: "pending" });
    batchesApi.getVehicles(offer.data.batch.id, params).then((response) => {
      const offerVehicles = offer.data.vehicle_offers;
      response.objects.forEach((batchVehicle, batchVehicleIndex) => {
        const index = offerVehicles.findIndex(
          (offerVehicle) => offerVehicle.vehicles[0].id === batchVehicle.id
        );
        if (index >= 0) {
          response.objects[batchVehicleIndex].offerMade =
            offerVehicles[index].price;
        }
      });
      dispatchVehicles({ type: "fulfilled", payload: response });
      setModifying(true);
    });
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const withdrawOffer = () => {
    dispatchOffer({ type: "pending" });
    offersApi
      .withdraw(offerId)
      .then((response) => {
        dispatchOffer({ type: "fulfilled", payload: response.object });
      })
      .catch((err) => {
        dispatchOffer({ type: "rejected" });
        toast({
          position: "top-right",
          title: "An error occured",
          description: err.detail,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const acceptCounterOffer = () => {
    setIsRespondingToCounter(true);
    offersApi.accept(offer.data.id).then((response) => {
      setIsRespondingToCounter(false);
      dispatchOffer({ type: "fulfilled", payload: response.object });
    });
  };

  const rejectCounterOffer = () => {
    setIsRespondingToCounter(true);
    offersApi.reject(offer.data.id).then((response) => {
      setIsRespondingToCounter(false);
      dispatchOffer({ type: "fulfilled", payload: response.object });
    });
  };

  const calculateOffer = () => {
    let value = 0;
    if (offer.data.vehicle_offers) {
      offer.data.vehicle_offers.forEach((vehicle) => {
        value += Number(vehicle.price);
      });
    }
    return value;
  };
  const currentOffer = calculateOffer();
  const type = offer.data.is_counter ? "pending" : offer.data.state || "";

  return (
    <Box>
      <Box
        w="100%"
        h={["16vh", "16vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px="2.5rem"
        py="1.5rem"
      >
        <Text type="header" color="white">
          Offer Details
        </Text>
        <Text
          as={Link}
          to="/offers"
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
        >
          ‹ Back to My Offers
        </Text>
      </Box>
      <Box mx="2.5rem" mt="2rem">
        <Skeleton isLoaded={!offer.loading}>
          <BatchInfo
            innerProps={{ px: "1rem" }}
            mt="-5rem"
            batch={offer.data.batch || {}}
          />
        </Skeleton>
        <Grid
          templateColumns={
            modifying
              ? ["1fr", ["3fr 7fr"]]
              : offer.data.is_counter
                ? counterLayoutMapping
                : layoutMapping[type || "pending"]
          }
          columnGap="0.6rem"
        >
          <Skeleton isLoaded={!offer.loading} mt={offer.loading ? "1rem" : ""}>
            <FeaturedContainer
              mt={["1rem", "0.6rem"]}
              variant="purple.500"
              opacity={offer.data.is_counter ? "0.5" : "1"}
              h="calc(100% - 0.6rem)"
            >
              <Box
                p="1rem"
                pb="2rem"
                d="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Text type="header"> Offer Made </Text>
                <Text type="secondaryBodyLight">
                  {offer.data.title} {moment(offer.data.create_date).fromNow()}
                </Text>
                <Text type="header" mt="0.5rem">
                  {offer.data.is_counter
                    ? offer.data.original_offer.vehicle_offers?.length
                    : offer.data.vehicle_offers?.length}{" "}
                  Vehicles /{" "}
                  {currencyFormat(
                    offer.data.is_counter
                      ? offer.data.original_offer.price
                      : offer.data.price
                  )}
                </Text>
              </Box>
            </FeaturedContainer>
          </Skeleton>
          <Skeleton isLoaded={!offer.loading} mt={offer.loading ? "1rem" : ""}>
            <FeaturedContainer
              variant={
                type === "pending" && !offer.data.is_counter
                  ? ""
                  : variantsMapping[type]
              }
              minH="7rem"
              mt={["1rem", "0.6rem"]}
              opacity={
                offer.data.is_counter && offer.data.state === "rejected"
                  ? 0.5
                  : 1
              }
            >
              <OfferStates
                type={type}
                offer={offer.data}
                modify={modifyOffer}
                withdraw={withdrawOffer}
                loading={vehicles.loading}
                modifying={modifying}
                vehicles={vehicles.list}
                update={updateOffer}
              />
            </FeaturedContainer>
          </Skeleton>
          <Skeleton isLoaded={!offer.loading} mt={offer.loading ? "1rem" : ""}>
            {offer.data.is_counter && (
              <CounterOfferStates
                type={offer.data.state}
                accept={acceptCounterOffer}
                reject={rejectCounterOffer}
                loading={isRespondingToCounter}
                deal={offer.data.deal}
              />
            )}
          </Skeleton>
        </Grid>
      </Box>
      <Box
        background="white"
        mx="2.5rem"
        mt={["2rem", "0.6rem"]}
        boxShadow="0px 3px 15px #0000000D"
        borderRadius="10px"
      >
        <Box
          d="flex"
          alignItems={["flex-start", "center"]}
          justifyContent="space-between"
          py="1.5rem"
          px={["1.5rem", 0]}
          flexDirection={["column", "row"]}
        >
          <Box w={["100%", "8rem"]} mx={[0, "1.5rem"]}>
            <Text
              fontWeight="bold"
              fontSize={["1.7rem", "0.95rem"]}
              color="text.500"
              lineHeight={["2rem", "1rem"]}
            >
              Offer Summary
            </Text>
            <Text
              fontWeight="light"
              fontSize={["0.9rem", "0.5rem"]}
              color="text.400"
              maxW="60%"
            >
              Showing{" "}
              {(modifying ? vehicles.list : offer.data?.vehicle_offers)?.length}{" "}
              vehicles
            </Text>
          </Box>
          <Filters
            hideGeneral={true}
            detailedFilters={detailedFilters}
            onSelectedDetailed={modifying ? selectDetailedFilter : () => { }}
          />
        </Box>
        <Grid mx="1.5rem" pb="1.5rem" rowGap="0.25rem" overflowY="scroll">
          {(modifying ? vehicles.list : offer.data?.vehicle_offers)?.map(
            (car, index) => {
              return (
                <CarRow
                  key={!modifying ? car.vehicles[0].id : car.id}
                  index={index}
                  type={!modifying && "offer"}
                  linkTo={`${offerId}/batch/${offer.data.batch.id}/vehicle/${!modifying ? car.vehicles[0].id : car.id
                    }`}
                  car={
                    modifying ? car : { ...car.vehicles[0], offerMade: car.price }
                  }
                  offerMade={modifying && car.offerMade}
                  showPrice
                  addToOffer={addToOffer}
                  removeFromOffer={removeFromOffer}
                />
              );
            }
          )}
        </Grid>
      </Box>
      <OfferSummaryModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        currentOffer={currentOffer}
        footerText="Continue to Offer Details"
        headerText="Your modified offer has been sent to the seller"
        offer={offer}
      />
    </Box>
  );
};

export default withRouter(OfferDetails);
