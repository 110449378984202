import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@chakra-ui/core";
import Text from "../Text";
import Button from "../Button";

const ErrorPage = ({ title, description }) => {
  const toMarketplace = () => history.push("/marketplace");
  const history = useHistory();
  return (
    <Box px="4rem" py="5rem">
      <Text color="purple.500" fontWeight="bold" fontSize="2rem">
        {title}
      </Text>
      <Text py="1rem">{description}</Text>
      <Button onClick={toMarketplace}>Back to Marketplace</Button>
    </Box>
  );
};

export default ErrorPage;
