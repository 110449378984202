import React, { useState, useEffect } from "react";

import { Box, Grid, Image, Skeleton } from "@chakra-ui/core";
import { Link } from "react-router-dom";

import Button from "../../common/Button";
import Text from "../../common/Text";
import AddToOfferModal from "../../common/AddToOfferModal";

import { FaPaperclip, FaTimesCircle } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useSelector, useDispatch } from "react-redux";
import { getVehicle, setVehicle } from "../../actions/dashboardActions";
import { setCurrentOffer } from "../../actions/marketplaceActions";
import { currencyFormat, formatBytes } from "../../common/utils";

import Switch from "@material/react-switch";
import "@material/react-switch/dist/switch.css";

const Attachment = ({ attachment }) => {
  const downloadAttachment = () => {
    const link = document.createElement("a");
    link.href = attachment.url;
    link.target = "_blank";
    link.download = true;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const suffixTester = /(?:\.([^.]+))?$/;

  return (
    <Box d="flex" mb="0.5rem" justifyContent="flex-start">
      <Box as={FaPaperclip} size={["1rem", "0.5rem"]} color="text.500" />
      <Box maxW="80%" d="flex" flexDirection="column">
        <Text
          ml="0.4rem"
          cursor="pointer"
          onClick={downloadAttachment}
          type="secondaryBodyRegular"
          fontSize={["1.1rem", "0.6rem"]}
          lineHeight={["1.2rem", "0.8rem"]}
        >
          {attachment.name}
        </Text>
        <Text ml="0.4rem" type="bodyLight" fontSize={["1rem", "0.5rem"]}>
          {suffixTester.exec(attachment.name)[1].toUpperCase()}{" "}
          {formatBytes(attachment.file_size)}
        </Text>
      </Box>
    </Box>
  );
};

const VehicleDetails = ({ history, match }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [offer, setOffer] = useState("");

  const dispatch = useDispatch();

  const { vehicleId, batchId, offerId } = match.params;
  const currentVehicle = useSelector((state) => state.dashboard.currentVehicle);
  const vehicles = useSelector(
    (state) => state.dashboard.currentBatch.vehicles
  );
  const currentOffer = useSelector((state) => state.marketplace.currentOffer);
  const loading = useSelector((state) => state.dashboard.loading);

  const currencies = useSelector(state => state.statics.currencies);
  const symbol = currencies.find(c => c.name === currentVehicle?.currency)?.symbol || "$";

  useEffect(() => {
    if (!currentVehicle?.id || currentVehicle.id != vehicleId) {
      let vehicleInList = vehicles.find((vehicle) => vehicle.id == vehicleId);
      if (vehicleInList) dispatch(setVehicle(vehicleInList));
      else dispatch(getVehicle(batchId, vehicleId));
    } else {
      isVehicleInOffer();
    }
  }, [currentVehicle, vehicleId]);

  const goBack = () => {
    history.push(match.url.split("/").slice(0, -2).join("/"));
  };

  const navigateToEdit = () => {
    history.push(`${vehicleId}/edit`);
  };

  const getBackText = () => {
    if (offerId) {
      return "Back to Offer Details";
    } else return "Back to Batch Details";
  };

  const isVehicleInOffer = () => {
    if (currentOffer.batchId === batchId && !offerId) {
      const index = currentOffer.offer.findIndex(
        (vehicle) => currentVehicle.id === vehicle.id
      );
      if (index >= 0) setOffer(currentOffer.offer[index].offerMade);
    }
  };

  const toggleModal = () => setIsOfferModalOpen(!isOfferModalOpen);

  const handleOfferChange = (e) => setOffer(e.target.value);

  const onAddToOffer = () => {
    const _currentOffer = {
      batchId,
      offer: currentOffer.offer ? [...currentOffer.offer] : [],
    };
    _currentOffer.offer.push({ ...currentVehicle, offerMade: offer });
    dispatch(setCurrentOffer(_currentOffer));
    toggleModal();
  };

  const onRemoveFromOffer = () => {
    setOffer("");
    const index = currentOffer.offer.findIndex(
      (vehicle) => currentVehicle.id === vehicle.id
    );
    const _currentOffer = { ...currentOffer };
    _currentOffer.offer.splice(index, 1);
    dispatch(setCurrentOffer(_currentOffer));
  };

  const isOwnerView = match.url.split("/")[1] === "dashboard";

  const images = currentVehicle?.images.map((image) => image.url) || [];

  const onImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Box w="100%" h="100%">
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <Box
        w="100%"
        h={["19vh", "19vh", "9vh", "8vh", "19vh"]}
        className="gradient-header"
        px={"2.5rem"}
        py="1.5rem"
      >
        <Text fontSize={["1.6rem", "0.95rem"]} type="header" color="white">
          Vehicle Details
        </Text>
        <Text
          onClick={goBack}
          fontSize={["1.1rem", "0.6rem"]}
          color="white"
          cursor="pointer"
        >
          ‹ {getBackText()}
        </Text>
      </Box>
      <Skeleton
        isLoaded={currentVehicle && !loading}
        marginLeft={["2.5rem", "auto"]}
        marginRight={["2.5rem", "auto"]}
        mt={["-2rem", "-2rem", "-2rem", "-2rem", "-3rem"]}
        borderRadius="10px"
        boxShadow="0px 3px 15px #0000000D"
        w={["auto", "80%", "70%", "60%", "50%"]}
        background="white"
        py={["1.5rem", "0.9rem"]}
        px={["2rem", "1.8rem"]}
        color="text.500"
      >
        {isOwnerView && (
          <Box d="flex" justifyContent="space-between" alignItems="center">
            <Button
              variant="link"
              minWidth={"0"}
              onClick={navigateToEdit}
              boxShadow="none"
              w={["auto"]}
            >
              Edit Vehicle
            </Button>
            {
              currentVehicle?.state === "sold" && <Text type="header" color="error">SOLD</Text>
            }
          </Box>
        )}
        <Box
          d="flex"
          flexDirection={["column-reverse", "column"]}
          mb={["0.5rem", 0]}
        >
          <Text type="header" lineHeight={"1.5rem"}>
            {(currentVehicle && currentVehicle.title) || "Title"}
          </Text>
          <Text
            type="secondaryBodyLight"
            color="text.400"
            fontSize={["1rem", "0.6rem"]}
          >
            Vehicle #{currentVehicle && currentVehicle.id}
          </Text>
        </Box>
        <Grid
          templateColumns="repeat(6, 1fr)"
          templateRows="3rem 3rem"
          rowGap="0.4rem"
          columnGap="5px"
          mt="5px"
          display={["none", "grid"]}
        >
          {images.map((image, index) => (
            <Image
              cursor="pointer"
              key={index}
              onClick={() => onImageClick(index)}
              pr="1rem"
              h="full"
              w={index !== 0 && "full"}
              w="full"
              gridRow={index === 0 && "1/3"}
              gridColumn={index === 0 && "1/3"}
              src={image}
              borderRadius="10px"
            ></Image>
          ))}
        </Grid>
        <Box display={["block", "none"]}>
          {images && images[0] && (
            <Image
              borderRadius="10px"
              onClick={() => onImageClick(0)}
              h="11rem"
              w="100%"
              src={images[0]}
            />
          )}
          {images && images.length > 1 && (
            <Box
              d="flex"
              justifyContent="flex-start"
              alignItems="center"
              mt="1rem"
            >
              {images.slice(1).map((image, index) => (
                <Image
                  ml={1}
                  key={index}
                  borderRadius="10px"
                  onClick={() => onImageClick(index + 1)}
                  h="2.5rem"
                  w="2.5rem"
                  src={image}
                ></Image>
              ))}
            </Box>
          )}
        </Box>
        <Text
          type="secondaryBodyBold"
          mt="0.6rem"
          fontSize={["0.9rem", "0.6rem"]}
        >
          Description
        </Text>
        <Text type="secondaryBodyLight">
          {currentVehicle && currentVehicle.description}
        </Text>
        <Box
          bg="surface"
          d="flex"
          py="0.5rem"
          px="0.9rem"
          flexDir="column"
          my="0.7rem"
        >
          <Text
            type="secondaryBodyBold"
            mb="0.5rem"
            fontSize={["1rem", "0.6rem"]}
          >
            Vehicle details
          </Text>
          <Grid templateColumns={["1fr", "1fr 1fr 1fr"]}>
            <Box fontWeight="regular" fontSize={["0.9rem", "0.6rem"]}>
              <Text
                opacity={currentVehicle && currentVehicle.mileage ? "1" : "0.3"}
              >
                Mileage:{" "}
                {currentVehicle &&
                  Number(currentVehicle.mileage).toLocaleString()}
                km{" "}
              </Text>
              <Text
                opacity={currentVehicle && currentVehicle.color ? "1" : "0.3"}
              >
                Color: {currentVehicle && currentVehicle.color}{" "}
              </Text>
              <Text
                opacity={
                  currentVehicle && currentVehicle.transmission ? "1" : "0.3"
                }
              >
                Transmission: {currentVehicle && currentVehicle.transmission}
              </Text>
            </Box>
            <Box
              fontWeight="regular"
              fontSize="0.6rem"
              display={["none", "block"]}
            >
              <Text
                opacity={
                  currentVehicle && currentVehicle.regional_specs ? "1" : "0.3"
                }
              >
                Specs: {currentVehicle && currentVehicle.regional_specs}
              </Text>
              <Text
                opacity={
                  currentVehicle && currentVehicle.engine_displacement
                    ? "1"
                    : "0.3"
                }
              >
                Engine Size:{" "}
                {currentVehicle && currentVehicle.engine_displacement}
              </Text>
              <Text
                opacity={
                  currentVehicle && currentVehicle.num_cylinders ? "1" : "0.3"
                }
              >
                Number of cylinders:{" "}
                {currentVehicle && currentVehicle.num_cylinders}{" "}
              </Text>
              <Text
                opacity={
                  currentVehicle && currentVehicle.fuel_type ? "1" : "0.3"
                }
              >
                Fuel type: {currentVehicle && currentVehicle.fuel_type}
              </Text>
            </Box>
            <Box
              fontWeight="regular"
              fontSize="0.6rem"
              display={["none", "block"]}
            >
              <Text
                opacity={
                  currentVehicle && currentVehicle.num_doors ? "1" : "0.3"
                }
              >
                Number of doors: {currentVehicle && currentVehicle.num_doors}{" "}
              </Text>
              <Text
                opacity={
                  currentVehicle && typeof currentVehicle.warranty !== "boolean"
                    ? "0.3"
                    : "1"
                }
              >
                Warranty:{" "}
                {currentVehicle && currentVehicle.warranty ? "Yes" : "No"}{" "}
              </Text>
              <Text
                opacity={
                  currentVehicle &&
                    typeof currentVehicle.service_history !== "boolean"
                    ? "0.3"
                    : "1"
                }
              >
                Service history:{" "}
                {currentVehicle && currentVehicle.service_history
                  ? "Yes"
                  : "No"}
              </Text>
            </Box>
          </Grid>
        </Box>
        <Box d="flex" flexDir="column" my="0.7rem">
          <Text
            fontWeight="bold"
            fontSize={["1.2rem", "0.6rem"]}
            my="0.5rem"
            ml={[0]}
          >
            Attachments
          </Text>
          <Box
            d="flex"
            flexDirection={["column", "row"]}
            justifyContent="space-between"
          >
            <Box>
              {currentVehicle?.attachments.map((attachment) => (
                <Attachment attachment={attachment} key={attachment.id} />
              ))}
              {
                currentVehicle?.attachments?.length === 0 && <Text type="secondaryBodyLight">No attachments</Text>
              }
            </Box>
            <Box
              mb="0.5rem"
              d="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {
                currentVehicle?.price && <Box
                  d="flex"
                  alignItems="center"
                  w="full"
                  justifyContent={["flex-end", "center"]}
                  mb="0.8rem"
                >
                  <Text
                    fontWeight="bold"
                    fontSize={["1.1rem", "0.6rem"]}
                    mr="0.5rem"
                  >
                    Price
                </Text>
                  <Text type="largeBodyRegular" fontSize={["1.2rem", "0.9rem"]}>
                    {symbol || "$"}
                    {currentVehicle &&
                      Number(currentVehicle.price).toLocaleString()}
                  </Text>
                </Box>
              }
              {!isOwnerView &&
                !offerId &&
                (offer && offer > 0 ? (
                  <Box w="7.2rem" d="flex" justifyContent="center">
                    <Box
                      borderRadius={"1.92rem"}
                      min-width={"5rem"}
                      height={"1.5rem"}
                      borderWidth="1px"
                      borderColor="purple.500"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      px="0.6rem"
                    >
                      <Text
                        type="largeBodyBold"
                        color="purple.500"
                        textAlign="center"
                      >
                        {currencyFormat(offer)}
                      </Text>
                      <Box
                        as={FaTimesCircle}
                        color="purple.500"
                        ml="0.4rem"
                        fontSize="0.7rem"
                        cursor="pointer"
                        onClick={onRemoveFromOffer}
                      />
                    </Box>
                  </Box>
                ) : (
                    <Button fontSize={["1.2rem", "0.7rem"]} onClick={toggleModal}>
                      Add to Offer
                    </Button>
                  ))}
              {!isOwnerView && !offerId && currentVehicle && (
                <AddToOfferModal
                  isModalOpen={isOfferModalOpen}
                  toggleModal={toggleModal}
                  car={currentVehicle}
                  showPrice={currentVehicle?.price}
                  currencySymbol={symbol}
                  handleOfferChange={handleOfferChange}
                  offer={offer}
                  onAddToOffer={onAddToOffer}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Skeleton>
    </Box>
  );
};

export default VehicleDetails;
