import React from "react";
import { Link } from "react-router-dom";

import { Box } from "@chakra-ui/core";
import { FaCar } from "react-icons/fa";

import FeaturedContainer from "../../common/FeaturedContainer";
import TagOutline from "../../common/TagOutline";
import Text from "../../common/Text";

const FeaturedBox = ({ variant, batch }) => {
  return (
    <FeaturedContainer
      variant={variant}
      as={Link}
      to={`/marketplace/batches/${batch.id}`}
    >
      <Box
        w="100%"
        h="100%"
        px="0.5rem"
        pt="1rem"
        pb="1em"
        pr="0.5rem"
        d="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Box
            fontWeight="light"
            fontSize="1.1rem"
            color="text.400"
            display={["flex", "none"]}
          >
            Batch #{batch.id}
            {variant && (
              <Text color={variant} type="bodyBold" ml="1rem" fontSize="1rem">
                {variant === "urgent" ? "Urgent" : "Hot"}
              </Text>
            )}
          </Box>
          <Text
            fontWeight="bold"
            fontSize={["1.4rem", "0.8rem"]}
            color="text.500"
            maxW={"15rem"}
            lineHeight={["1.5rem", "1rem"]}
          >
            {batch.description}
          </Text>
          <Text
            type="secondaryBodyLight"
            color="text.400"
            display={["none", "block"]}
          >
            Batch #{batch.id}
          </Text>
        </Box>
        <Box
          d={["none", "flex"]}
          alignItems="center"
          mt={"5px"}
          fontSize={"0.65rem"}
        >
          {variant && (
            <Text color={variant} type="bodyBold" ml="0.5rem">
              {variant === "urgent" ? "Urgent" : "Hot"}
            </Text>
          )}
        </Box>
        <Box mt="0.8rem" color="text.500" d="flex" alignItems="center">
          <TagOutline text={batch.type} />
          <Box ml="13px" as={FaCar} size={["1.2rem", "0.8rem"]}></Box>
          <Text fontSize={["1.1rem", "0.6rem"]} ml="6px">
            {batch.num_vehicles}
          </Text>
        </Box>
      </Box>
    </FeaturedContainer>
  );
};

export default FeaturedBox;
